import Badge from '@/components/badge';
import Link from '@/components/link/Link.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useParentAccountByCustodianQuery } from '@/hooks/ops/useParentAccountByCustodianQuery.hook';
import { workflowStatusColorMap } from '@/models/ops/workflows/WorkflowStatusColorMap.model';
import { WorkflowStatusEnumsObject } from '@/models/ops/workflows/WorkflowStatusEnumObject.model';
import AlertService from '@/services/ops/alerts/Alert.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  alpha,
  Button,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { AxiosError } from 'axios';
import { FC, useContext } from 'react';

import { AlertContext } from '../../AlertContext';
import { isWorkflowComplete, isWorkflowFailed } from '../../helpers';
import { useGetWorkflowByTracerId } from '../../useGetWorkflowByTracerId.hook';
import { useUpdateAlertMutation } from '../../useUpdateAlertMutation.hook';

type TransferFundsProps = {
  showCloseTicketPrompt?: boolean;
  prompt?: string;
  onChangePlan?: () => void;
};

export const TransferFunds: FC<TransferFundsProps> = props => {
  const alert = useContext(AlertContext);
  const cashTransferWorkflowTracerId =
    alert?.details.cashTransferWorkflowTracerId;
  const isAlertClosed = alert?.alertStatus === AlertStatus.Closed;
  const parentAccountQuery = useParentAccountByCustodianQuery({
    custodianAccountNumber: alert?.custodianAccountNumber,
    custodianId: alert?.details?.event?.body[0]?.custodianId
  });
  const isHouseAccount =
    parentAccountQuery.data?.accountType === ParentAccountType.House;

  const cashTransferWorkflowQuery = useGetWorkflowByTracerId(
    cashTransferWorkflowTracerId
  );

  const transferDepositCashMutation = useMutation(
    ['AlertService.transferDepositCash'],
    (alertId: number) => AlertService.transferDepositCash(alertId),
    {
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;

        showSnackbar({
          message: `Failed to start transferring funds: ${message}`,
          severity: 'error'
        });
      },
      onSuccess: updatedAlert => {
        showSnackbar({ message: 'Funds transfer started' });
        queryClient.setQueryData(
          ['AlertService.getById', updatedAlert.id],
          updatedAlert
        );
      }
    }
  );
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const updateAlertMutation = useUpdateAlertMutation();

  if (!isHouseAccount) return null;

  return (
    <>
      {!cashTransferWorkflowTracerId && !isAlertClosed && (
        <Alert
          data-testid='cash-transfer-workflow-alert'
          icon={false}
          severity='warning'
          variant='outlined'>
          <AlertTitle>Transfer Funds</AlertTitle>
          <Typography sx={{ fontSize: 14, mb: 2 }}>{props.prompt}</Typography>
          <Stack direction='row' mb={2} spacing={1}>
            <LoadingButton
              data-testid='deposit-plan-transfer-funds-button'
              loading={transferDepositCashMutation.isLoading}
              onClick={() => transferDepositCashMutation.mutate(alert.id)}
              size='small'
              variant='contained'>
              Transfer Funds
            </LoadingButton>
            <Button
              data-testid='deposit-plan-change-plan-button'
              onClick={() => {
                updateAlertMutation.mutate({
                  alertId: alert.id,
                  updateRequest: {
                    details: {
                      depositPlanId: null
                    }
                  }
                });

                if (props.onChangePlan) props.onChangePlan();
              }}
              size='small'>
              Change Plan
            </Button>
          </Stack>
          <Alert
            severity='warning'
            sx={{
              borderColor: theme => alpha(theme.palette.warning.main, 0.25),
              borderStyle: 'solid',
              borderWidth: '1px'
            }}>
            Transaction type cannot be changed following a fund transfer. Please
            confirm that it is correct before proceeding.
          </Alert>
        </Alert>
      )}
      {isWorkflowFailed(cashTransferWorkflowQuery.data?.workflowStatus) && (
        <Alert
          data-testid='cash-transfer-workflow-failed-alert'
          icon={false}
          severity='error'
          variant='outlined'>
          <Typography sx={{ fontSize: 14, mb: 2 }}>
            Sorry, something went wrong transferring deposited funds to the
            plan’s account.
          </Typography>
          <Stack direction='row' spacing={1} useFlexGap>
            {!isAlertClosed && (
              <LoadingButton
                data-testid='deposit-plan-retry-transfer-funds-button'
                loading={transferDepositCashMutation.isLoading}
                onClick={() => transferDepositCashMutation.mutate(alert.id)}
                size='small'
                variant='contained'>
                Try Again
              </LoadingButton>
            )}
            <Button
              data-testid='deposit-plan-change-plan-button'
              onClick={() => {
                updateAlertMutation.mutate({
                  alertId: alert.id,
                  updateRequest: {
                    details: {
                      depositPlanId: null
                    }
                  }
                });
              }}
              size='small'>
              Change Plan
            </Button>
          </Stack>
        </Alert>
      )}
      {cashTransferWorkflowQuery.data &&
        !isWorkflowFailed(cashTransferWorkflowQuery.data?.workflowStatus) && (
          <Alert
            action={
              props.showCloseTicketPrompt &&
              isWorkflowComplete(
                cashTransferWorkflowQuery.data?.workflowStatus
              ) &&
              alert.alertStatus !== AlertStatus.Closed && (
                <Button
                  data-testid='deposit-plan-close-ticket-button'
                  onClick={() => {
                    updateAlertMutation.mutate({
                      alertId: alert.id,
                      updateRequest: {
                        alertStatus: AlertStatus.Closed
                      }
                    });
                  }}
                  size='small'
                  sx={{
                    minWidth: 'max-content'
                  }}>
                  Close Ticket
                </Button>
              )
            }
            data-testid='cash-transfer-workflow-submitted-alert'
            icon={false}
            severity={
              isWorkflowComplete(cashTransferWorkflowQuery.data?.workflowStatus)
                ? 'success'
                : 'info'
            }
            variant='outlined'>
            <AlertTitle>Transfer Funds</AlertTitle>
            <Stack minWidth={0} overflow='hidden' spacing={1.25} useFlexGap>
              <Typography fontSize={14}>
                {isWorkflowComplete(
                  cashTransferWorkflowQuery.data?.workflowStatus
                )
                  ? 'Funds have been transferred to the plan’s account'
                  : 'Transfer has been submitted.'}
              </Typography>
              <Stack alignItems='center' direction='row' spacing={1}>
                <Link
                  data-testid='cash-transfer-workflow-link'
                  sx={{
                    fontSize: 14,
                    maxWidth: 256,
                    minWidth: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                  target='_blank'
                  to={`/ops/workflows/${cashTransferWorkflowQuery.data?.id}`}>
                  Tracer ID: {cashTransferWorkflowQuery.data?.tracerId}
                </Link>
                <Badge
                  color={
                    cashTransferWorkflowQuery.data?.workflowStatus
                      ? workflowStatusColorMap[
                          cashTransferWorkflowQuery.data?.workflowStatus
                        ]
                      : 'neutral'
                  }
                  size='small'
                  sx={{
                    minWidth: 'max-content'
                  }}>
                  {formatters.displayCase(
                    formatters.getValueKey(
                      WorkflowStatusEnumsObject,
                      cashTransferWorkflowQuery.data?.workflowStatus
                    )
                  )}
                </Badge>
              </Stack>
            </Stack>
          </Alert>
        )}
      {!isHouseAccount && alert.alertStatus !== AlertStatus.Closed && (
        <Button
          data-testid='deposit-plan-change-plan-button'
          onClick={() => {
            updateAlertMutation.mutate({
              alertId: alert.id,
              updateRequest: {
                details: {
                  depositPlanId: null
                }
              }
            });
          }}
          size='small'>
          Change Plan
        </Button>
      )}
    </>
  );
};

TransferFunds.defaultProps = {
  prompt: 'Transfer deposited funds to the plan’s account?',
  showCloseTicketPrompt: false
};

TransferFunds.displayName = 'TransferFunds';
