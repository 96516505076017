import SimpleTabs, { TabData } from '@/components/simple-tabs';
import { Typography } from '@mui/material';

import { FC } from 'react';

import { ModelUpdate } from './ModelUpdate.component';

export const ModelUpdateRoute: FC = () => {
  const tabs: TabData[] = [
    {
      component: <ModelUpdate />,
      label: 'Update Model'
    }
  ];

  return (
    <>
      <Typography mb={6} mt={1} variant='h4'>
        Investment Bulk Tools
      </Typography>
      <SimpleTabs tabs={tabs} tabsAriaLabel='model-update-tabs' />
    </>
  );
};
