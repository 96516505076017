import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { SnackbarOptions } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import { QueryClient, useMutation } from '@tanstack/react-query';

import { useMemo } from 'react';

import { ActionCenterDetailsCashAllocation } from './ActionCenterDetailsBulkCashAllocation.component';
import { BulkActionsConfig } from './useBulkActionCenterConfig.hook';

export const useBulkUploadCashAllocationConfig = (
  showSnackbar: (options: SnackbarOptions) => void,
  queryClient: QueryClient
) => {
  const permissions = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_BULK_UPLOAD_ACTIONS]
  });

  const mutation = useMutation(
    ['ActionCenterService.bulkUploadCashAllocation'],
    (data: FormData) => ActionCenterService.bulkUploadCashAllocation(data),
    {
      onError: () =>
        showSnackbar({
          message:
            'There was an error while validating the file, please try again',
          severity: 'error'
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['ActionCenterService.bulkCashAllocationList']
        });
        showSnackbar({
          message: 'File uploaded succesfully',
          severity: 'success'
        });
      }
    }
  );

  const bulkUploadFilesConfig = useMemo<BulkActionsConfig>(
    () => ({
      step2: <ActionCenterDetailsCashAllocation handleSubmit={mutation} />,
      step3: null,
      visible: permissions.isAllowed
    }),
    [mutation, permissions]
  );

  return bulkUploadFilesConfig;
};
