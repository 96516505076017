import AllowHardshipElective from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/AllowHardshipElective';
import AllowHardshipFromPartialVestedAccount from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/AllowHardshipFromPartialVestedAccount';
import AllowHardshipMatch from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/AllowHardshipMatch';
import AllowHardshipProfitSharing from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/AllowHardshipProfitSharing';
import AllowHardshipRollover from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/AllowHardshipRollover';
import AllowHardshipSafeHarbor from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/AllowHardshipSafeHarbor';
import AllowHardshipTransfer from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/AllowHardshipTransfer';
import EarlyRetirementAge from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/EarlyRetirementAge';
import EarlyRetirementAgeType from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/EarlyRetirementAgeType';
import HardshipCriteria from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/HardshipCriteria';
import HardshipWithdrawalsFromRothAccounts from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/HardshipWithdrawalsFromRothAccounts';
import InServiceAtEarlyAge from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/InServiceAtEarlyAge';
import InServiceAtEarlyAgeFromAllAccounts from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/InServiceAtEarlyAgeFromAllAccounts';
import InServiceAtNormalAge from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/InServiceAtNormalAge';
import InServiceAtNormalAgeFromAllAccounts from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/InServiceAtNormalAgeFromAllAccounts';
import InServiceAtSpecifiedAge from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/InServiceAtSpecifiedAge';
import InServiceAtSpecifiedAgeFromRothAccount from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/InServiceAtSpecifiedAgeFromRothAccount';
import InServiceFromPartialVestedAccount from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/InServiceFromPartialVestedAccount';
import InServiceFromRolloverAccount from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/InServiceFromRolloverAccount';
import InServiceUponDisability from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/InServiceUponDisability';
import NormalRetirementAge from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/NormalRetirementAge';
import NormalRetirementAgeType from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/NormalRetirementAgeType';
import PartialDistributionAfterTermination from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/PartialDistributionAfterTermination';
import SpecifiedAge from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/SpecifiedAge';
import IncomingBlackoutEndDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/effective-date/IncomingBlackoutEndDate';
import IncomingBlackoutStartDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/effective-date/IncomingBlackoutStartDate';
import LiquidationDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/effective-date/LiquidationDate';
import OriginalEffectivePlanDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/effective-date/OriginalEffectivePlanDate';
import PlanYearEnd from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/effective-date/PlanYearEnd';
import ProfitSharingStartDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/effective-date/ProfitSharingStartDate';
import SafeHarborEffectiveDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/effective-date/SafeHarborEffectiveDate';
import SalaryRothDeferralEffectiveDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/effective-date/SalaryRothDeferralEffectiveDate';
import VestwellStartDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/effective-date/VestwellStartDate';
import ExcludeLeasedEmployees from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/eligibility-features/ExcludeLeasedEmployees';
import HoursRequired from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/eligibility-features/HoursRequired';
import LengthOfEmploymentRequired from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/eligibility-features/LengthOfEmploymentRequired';
import MinimumAge from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/eligibility-features/MinimumAge';
import SpecialEntryDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/eligibility-features/SpecialEntryDate';
import SpecialParticipationDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/eligibility-features/SpecialParticipationDate';
import AutoEnrollAmount from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employee-contribution/AutoEnrollAmount';
import AutoEnrollEffectiveDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employee-contribution/AutoEnrollEffectiveDate';
import AutoEscalateAmount from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employee-contribution/AutoEscalateAmount';
import AutoEscalateMaximum from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employee-contribution/AutoEscalateMaximum';
import DeferInDollars from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employee-contribution/DeferInDollars';
import EACAPermissibleWithdrawal from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employee-contribution/EACAPermissibleWithdrawal';
import EmployeeAfterTaxContribution from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employee-contribution/EmployeeAfterTaxContribution';
import EmployeePretaxContribution from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employee-contribution/EmployeePretaxContribution';
import PermissibleTimeframe from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employee-contribution/PermissibleTimeframe';
import SalaryRothDeferral from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employee-contribution/SalaryRothDeferral';
import ContributionMatchFormula from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/ContributionMatchFormula';
import DiscretionaryMatchFundingIntent from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/DiscretionaryMatchFundingIntent';
import DocumentMatchFrequency from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/DocumentMatchFrequency';
import EmployerProfitSharing from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/EmployerProfitSharing';
import MatchFrequency from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/MatchFrequency';
import NonSafeHarborMatch from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/NonSafeHarborMatch';
import NonSafeHarborMatchType from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/NonSafeHarborMatchType';
import Notes from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/Notes';
import ProfitSharingType from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/ProfitSharingType';
import Rollover from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/Rollover';
import SafeHarborMatch from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/SafeHarborMatch';
import SafeHarborMatchType from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/SafeHarborMatchType';
import SafeHarborNonElective from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/employer-contribution/SafeHarborNonElective';
import ChildFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/ChildFee';
import CorrectiveFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/CorrectiveFee';
import DeathFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/DeathFee';
import DisabilityFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/DisabilityFee';
import HardshipFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/HardshipFee';
import InServiceFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/InServiceFee';
import MaintenanceFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/MaintenanceFee';
import OriginationFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/OriginationFee';
import ParticipantTerminationFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/ParticipantTerminationFee';
import PermissiveFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/PermissiveFee';
import PlanTerminationFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/PlanTerminationFee';
import QdroFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/QdroFee';
import RmdFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/RmdFee';
import RolloverFee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/fee/RolloverFee';
import ExcludeRollover from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/force-out-preferencies/ExcludeRollover';
import ForceOutAllowed from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/force-out-preferencies/ForceOutAllowed';
import ForceOutAutomated from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/force-out-preferencies/ForceOutAutomated';
import MaxAmount from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/force-out-preferencies/MaxAmount';
import RolloverMin from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/force-out-preferencies/RolloverMin';
import LoanEmail from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/loan-features/LoanEmail';
import Loans from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/loan-features/Loans';
import MaxOutstandingLoans from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/loan-features/MaxOutstandingLoans';
import MaxYearsResidencePurchase from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/loan-features/MaxYearsResidencePurchase';
import PriorLoans from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/loan-features/PriorLoans';
import ResidencePurchaseExtension from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/loan-features/ResidencePurchaseExtension';
import ConversionType from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/overview/ConversionType';
import PlanDesignProvider from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/overview/PlanDesignProvider';
import PlanDesignTypeTemplate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/overview/PlanDesignTypeTemplate';
import Trustee from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/overview/Trustee';
import AdoptionAgreementDocumentProvider from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/recordkeeper-and-custodian/AdoptionAgreementDocumentProvider';
import CustodianAccountNumber from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/recordkeeper-and-custodian/CustodianAccountNumber';
import ExternalHardDollarBillingAccountNumber from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/recordkeeper-and-custodian/ExternalHardDollarBillingAccountNumber';
import ExternalPlanId from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/recordkeeper-and-custodian/ExternalPlanId';
import OpportunityId from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/recordkeeper-and-custodian/OpportunityId';
import PartnerSystemName from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/recordkeeper-and-custodian/PartnerSystemName';
import PriorProvider from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/recordkeeper-and-custodian/PriorProvider';
import ClientSuccessManager from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/service/ClientSuccessManager';
import DeconvertedToMep from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/status/DeconvertedToMep';
import LastPayrollDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/status/LastPayrollDate';
import OutgoingBlackoutEndDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/status/OutgoingBlackoutEndDate';
import OutgoingBlackoutStartDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/status/OutgoingBlackoutStartDate';
import PlanStatus from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/status/PlanStatus';
import TerminationDate from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/status/TerminationDate';
import EmployerMatch from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/vesting-preferences/EmployerMatch';
import HoursOfServiceRequired from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/vesting-preferences/HoursOfServiceRequired';
import ProfitSharing from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/vesting-preferences/ProfitSharing';
import QACASafeHarborMatch from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/vesting-preferences/QACASafeHarborMatch';
import QACASafeHarborNonElective from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/vesting-preferences/QACASafeHarborNonElective';
import ServicePriorTo18YearsOld from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/vesting-preferences/ServicePriorTo18YearsOld';
import ServicePriorToPlanExistence from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/vesting-preferences/ServicePriorToPlanExistence';
import VestingMethod from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/vesting-preferences/VestingMethod';

import { omit } from 'lodash';

import DualEligibilityEntryDateFrequency from './properties/eligibility-features/DualEligibilityEntryDateFrequency';
import DualEligibilityHoursRequired from './properties/eligibility-features/DualEligibilityHoursRequired';
import DualEligibilityLengthOfEmploymentRequired from './properties/eligibility-features/DualEligibilityLengthOfEmploymentRequired';
import DualEligibilityMinimumAge from './properties/eligibility-features/DualEligibilityMinimumAge';
import HasDualEligibility from './properties/eligibility-features/HasDualEligibility';
import ResidualFee from './properties/fee/ResidualFee';

abstract class BasePlan {
  adminStatus: PlanStatus;

  adoptionAgreementDocumentProvider: AdoptionAgreementDocumentProvider;

  allowAfterTaxContribution: EmployeeAfterTaxContribution;

  allowEmployerProfitSharing: EmployerProfitSharing;

  allowHardshipWithdrawalsFromRothAccount: HardshipWithdrawalsFromRothAccounts;

  allowInServiceAtEarlyAge: InServiceAtEarlyAge;

  allowInServiceAtEarlyAgeFromAllAccounts: InServiceAtEarlyAgeFromAllAccounts;

  allowInServiceAtNormalAge: InServiceAtNormalAge;

  allowInServiceAtNormalAgeFromAllAccounts: InServiceAtNormalAgeFromAllAccounts;

  allowInServiceAtSpecifiedAge: InServiceAtSpecifiedAge;

  allowInServiceFromPartialVestedAccount: InServiceFromPartialVestedAccount;

  allowInServiceFromRolloverAccount: InServiceFromRolloverAccount;

  allowInServiceUponDisability: InServiceUponDisability;

  allowLoans: Loans;

  allowPartialDistributionsAfterTermination: PartialDistributionAfterTermination;

  allowPermissibleWithdrawal: EACAPermissibleWithdrawal;

  allowPretaxContribution: EmployeePretaxContribution;

  allowRollover: Rollover;

  allowNonSafeHarborMatch: NonSafeHarborMatch;

  allowResidencePurchaseExtension: ResidencePurchaseExtension;

  isSafeHarbor: SafeHarborMatch;

  allowSafeHarborNonElective: SafeHarborNonElective;

  allowSpecifiedAgeFromRothAccount: InServiceAtSpecifiedAgeFromRothAccount;

  autoEnrollAmount: AutoEnrollAmount;

  autoEnrollEffectiveDate: AutoEnrollEffectiveDate;

  autoEscalateAmount: AutoEscalateAmount;

  autoEscalateMaximum: AutoEscalateMaximum;

  clientSuccessManagerName: ClientSuccessManager;

  contributionMatchFormula: ContributionMatchFormula;

  conversionType: ConversionType;

  custodianAccountNumber: CustodianAccountNumber;

  deconvertedToMep: DeconvertedToMep;

  deferInDollars: DeferInDollars;

  discretionaryMatchFundingIntent: DiscretionaryMatchFundingIntent;

  documentMatchFrequency: DocumentMatchFrequency;

  hasDualEligibility: HasDualEligibility;

  employerMatchLengthOfEmploymentRequired: DualEligibilityLengthOfEmploymentRequired;

  employerMatchEntryDateFrequency: DualEligibilityEntryDateFrequency;

  employerMatchMinimumAge: DualEligibilityMinimumAge;

  employerMatchHoursRequired: DualEligibilityHoursRequired;

  profitSharingLengthOfEmploymentRequired: DualEligibilityLengthOfEmploymentRequired;

  profitSharingEntryDateFrequency: DualEligibilityEntryDateFrequency;

  profitSharingMinimumAge: DualEligibilityMinimumAge;

  profitSharingHoursRequired: DualEligibilityHoursRequired;

  earlyRetirementAge: EarlyRetirementAge;

  earlyRetirementAgeType: EarlyRetirementAgeType;

  employerMatch: EmployerMatch;

  entryDateFrequency: DualEligibilityEntryDateFrequency;

  excludeLeasedEmployees: ExcludeLeasedEmployees;

  excludeRollover: ExcludeRollover;

  excludeServicePriorTo18YearsOld: ServicePriorTo18YearsOld;

  excludeServicePriorToPlanExistence: ServicePriorToPlanExistence;

  externalHardDollarBillingAccountNumber: ExternalHardDollarBillingAccountNumber;

  externalPlanId: ExternalPlanId;

  hardshipCriteria: HardshipCriteria;

  hardshipFee: HardshipFee;

  participantTerminationFee: ParticipantTerminationFee;

  inServiceFee: InServiceFee;

  rolloverFee: RolloverFee;

  disabilityFee: DisabilityFee;

  childFee: ChildFee;

  permissibleTimeframe: PermissibleTimeframe;

  planTerminationFee: PlanTerminationFee;

  qdroFee: QdroFee;

  rmdFee: RmdFee;

  deathFee: DeathFee;

  correctiveFee: CorrectiveFee;

  permissiveFee: PermissiveFee;

  residualFee: ResidualFee;

  hasPriorLoans: PriorLoans;

  hoursRequired: HoursRequired;

  hoursOfServiceRequired: HoursOfServiceRequired;

  blackoutEndDate: IncomingBlackoutEndDate;

  blackoutStartDate: IncomingBlackoutStartDate;

  isAllowed: ForceOutAllowed;

  isAutomated: ForceOutAutomated;

  isSalaryRothDeferral: SalaryRothDeferral;

  lastPayrollDate: LastPayrollDate;

  lengthOfEmploymentRequired: LengthOfEmploymentRequired;

  liquidationDate: LiquidationDate;

  maintenanceFee: MaintenanceFee;

  matchFrequency: MatchFrequency;

  maxAmount: MaxAmount;

  maxOutstandingLoans: MaxOutstandingLoans;

  maxYearsResidencePurchase: MaxYearsResidencePurchase;

  minimumAge: MinimumAge;

  normalRetirementAge: NormalRetirementAge;

  normalRetirementAgeType: NormalRetirementAgeType;

  nonSafeHarborMatchType: NonSafeHarborMatchType;

  notes: Notes;

  opportunityId: OpportunityId;

  originalEffectivePlanDate: OriginalEffectivePlanDate;

  originationFee: OriginationFee;

  offboardingBlackoutEndDate: OutgoingBlackoutEndDate;

  offboardingBlackoutStartDate: OutgoingBlackoutStartDate;

  partnerSystemName: PartnerSystemName;

  planDesignProvider: PlanDesignProvider;

  planDesignTypeTemplate: PlanDesignTypeTemplate;

  planYearEnd: PlanYearEnd;

  priorProvider: PriorProvider;

  profitSharing: ProfitSharing;

  profitSharingStartDate: ProfitSharingStartDate;

  profitSharingStrategy: ProfitSharingType;

  QACASafeHarborMatch: QACASafeHarborMatch;

  QACASafeHarborNonElective: QACASafeHarborNonElective;

  rolloverMin: RolloverMin;

  safeHarborEffectiveDate: SafeHarborEffectiveDate;

  safeHarborMatchType: SafeHarborMatchType;

  salaryRothDeferralEffectiveDate: SalaryRothDeferralEffectiveDate;

  specialEntryDate: SpecialEntryDate;

  specialParticipationDate: SpecialParticipationDate;

  inServiceSpecifiedAge: SpecifiedAge;

  terminationDate: TerminationDate;

  trustee: Trustee;

  vestingMethod: VestingMethod;

  vestwellStartDate: VestwellStartDate;

  allowHardshipElective: AllowHardshipElective;

  allowHardshipFromPartialVestedAccount: AllowHardshipFromPartialVestedAccount;

  allowHardshipMatch: AllowHardshipMatch;

  allowHardshipProfitSharing: AllowHardshipProfitSharing;

  allowHardshipRollover: AllowHardshipRollover;

  allowHardshipTransfer: AllowHardshipTransfer;

  allowHardshipSafeHarbor: AllowHardshipSafeHarbor;

  loanEmail: LoanEmail;

  constructor(props: Record<string, any>) {
    const values = {
      ...(props.overview || {}),
      ...(props.effectiveDate
        ? {
            blackoutEndDate: props.effectiveDate.blackoutEndDate,
            blackoutStartDate: props.effectiveDate.blackoutStartDate,
            ...omit(props.effectiveDate, [
              'blackoutStartDate',
              'blackoutEndDate'
            ])
          }
        : {}),
      ...(props.eligibilityFeatures || {}),
      ...(props.employeeContribution || {}),
      ...(props.employerContribution || {}),
      ...(props.vestingPreferences || {}),
      ...(props.distributionFeatures || {}),
      ...(props.loanFeatures || {}),
      ...(props.offboardingInformation
        ? {
            offboardingBlackoutEndDate:
              props.offboardingInformation.blackoutEndDate,
            offboardingBlackoutStartDate:
              props.offboardingInformation.blackoutStartDate,
            ...omit(props.offboardingInformation, [
              'blackoutStartDate',
              'blackoutEndDate'
            ])
          }
        : {}),
      ...(props.recordkeeperAndCustodian || {}),
      ...(props.forfeiturePreferences || {}),
      ...(props.forceOutPreferences || {}),
      csms: props.csms || [],
      isEsa: props.isEsa,
      isPooledPlan: props.isPooledPlan,
      isTpaUser: props.isTpaUser
    };

    this.adminStatus = new PlanStatus(values);
    this.adoptionAgreementDocumentProvider =
      new AdoptionAgreementDocumentProvider(values);
    this.allowAfterTaxContribution = new EmployeeAfterTaxContribution(values);
    this.allowEmployerProfitSharing = new EmployerProfitSharing(values);
    this.allowHardshipFromPartialVestedAccount =
      new AllowHardshipFromPartialVestedAccount(values);
    this.allowHardshipWithdrawalsFromRothAccount =
      new HardshipWithdrawalsFromRothAccounts(values);
    this.allowInServiceAtEarlyAge = new InServiceAtEarlyAge(values);
    this.allowInServiceAtEarlyAgeFromAllAccounts =
      new InServiceAtEarlyAgeFromAllAccounts(values);
    this.allowInServiceAtNormalAge = new InServiceAtNormalAge(values);
    this.allowInServiceAtNormalAgeFromAllAccounts =
      new InServiceAtNormalAgeFromAllAccounts(values);
    this.allowInServiceAtSpecifiedAge = new InServiceAtSpecifiedAge(values);
    this.allowInServiceFromPartialVestedAccount =
      new InServiceFromPartialVestedAccount(values);
    this.allowInServiceFromRolloverAccount = new InServiceFromRolloverAccount(
      values
    );
    this.allowInServiceUponDisability = new InServiceUponDisability(values);
    this.allowLoans = new Loans(values);
    this.allowPartialDistributionsAfterTermination =
      new PartialDistributionAfterTermination(values);
    this.allowPermissibleWithdrawal = new EACAPermissibleWithdrawal(values);
    this.allowPretaxContribution = new EmployeePretaxContribution(values);
    this.allowRollover = new Rollover(values);
    this.allowNonSafeHarborMatch = new NonSafeHarborMatch(values);
    this.allowResidencePurchaseExtension = new ResidencePurchaseExtension(
      values
    );
    this.isSafeHarbor = new SafeHarborMatch(values);
    this.allowSafeHarborNonElective = new SafeHarborNonElective(values);
    this.allowSpecifiedAgeFromRothAccount =
      new InServiceAtSpecifiedAgeFromRothAccount(values);
    this.autoEnrollAmount = new AutoEnrollAmount(values);
    this.autoEnrollEffectiveDate = new AutoEnrollEffectiveDate(values);
    this.autoEscalateAmount = new AutoEscalateAmount(values);
    this.autoEscalateMaximum = new AutoEscalateMaximum(values);
    this.clientSuccessManagerName = new ClientSuccessManager(values);
    this.contributionMatchFormula = new ContributionMatchFormula(values);
    this.conversionType = new ConversionType(values);
    this.custodianAccountNumber = new CustodianAccountNumber(values);
    this.deconvertedToMep = new DeconvertedToMep(values);
    this.deferInDollars = new DeferInDollars(values);
    this.discretionaryMatchFundingIntent = new DiscretionaryMatchFundingIntent(
      values
    );
    this.documentMatchFrequency = new DocumentMatchFrequency(values);
    this.earlyRetirementAge = new EarlyRetirementAge(values);
    this.earlyRetirementAgeType = new EarlyRetirementAgeType(values);
    this.employerMatch = new EmployerMatch(values);
    this.entryDateFrequency = new DualEligibilityEntryDateFrequency(
      values.eligibilityRules
    );
    this.excludeLeasedEmployees = new ExcludeLeasedEmployees(values);
    this.excludeRollover = new ExcludeRollover(values);
    this.excludeServicePriorTo18YearsOld = new ServicePriorTo18YearsOld(values);
    this.excludeServicePriorToPlanExistence = new ServicePriorToPlanExistence(
      values
    );
    this.externalHardDollarBillingAccountNumber =
      new ExternalHardDollarBillingAccountNumber(values);
    this.externalPlanId = new ExternalPlanId(values);
    this.hardshipCriteria = new HardshipCriteria(values);
    this.hardshipFee = new HardshipFee(values);
    this.participantTerminationFee = new ParticipantTerminationFee(values);
    this.inServiceFee = new InServiceFee(values);
    this.rolloverFee = new RolloverFee(values);
    this.disabilityFee = new DisabilityFee(values);
    this.childFee = new ChildFee(values);
    this.permissibleTimeframe = new PermissibleTimeframe(values);
    this.planTerminationFee = new PlanTerminationFee(values);
    this.qdroFee = new QdroFee(values);
    this.rmdFee = new RmdFee(values);
    this.deathFee = new DeathFee(values);
    this.correctiveFee = new CorrectiveFee(values);
    this.permissiveFee = new PermissiveFee(values);
    this.residualFee = new ResidualFee(values);
    this.hasPriorLoans = new PriorLoans(values);
    this.hoursRequired = new HoursRequired(values);
    this.hoursOfServiceRequired = new HoursOfServiceRequired(values);
    this.blackoutEndDate = new IncomingBlackoutEndDate(values);
    this.blackoutStartDate = new IncomingBlackoutStartDate(values);
    this.isAllowed = new ForceOutAllowed(values);
    this.isAutomated = new ForceOutAutomated(values);
    this.isSalaryRothDeferral = new SalaryRothDeferral(values);
    this.lastPayrollDate = new LastPayrollDate(values);
    this.lengthOfEmploymentRequired = new LengthOfEmploymentRequired(values);
    this.liquidationDate = new LiquidationDate(values);
    this.maintenanceFee = new MaintenanceFee(values);
    this.matchFrequency = new MatchFrequency(values);
    this.maxAmount = new MaxAmount(values);
    this.maxOutstandingLoans = new MaxOutstandingLoans(values);
    this.maxYearsResidencePurchase = new MaxYearsResidencePurchase(values);
    this.minimumAge = new MinimumAge(values);
    this.nonSafeHarborMatchType = new NonSafeHarborMatchType(values);
    this.normalRetirementAge = new NormalRetirementAge(values);
    this.normalRetirementAgeType = new NormalRetirementAgeType(values);
    this.notes = new Notes(values);
    this.opportunityId = new OpportunityId(values);
    this.originalEffectivePlanDate = new OriginalEffectivePlanDate(values);
    this.originationFee = new OriginationFee(values);
    this.offboardingBlackoutEndDate = new OutgoingBlackoutEndDate(values);
    this.offboardingBlackoutStartDate = new OutgoingBlackoutStartDate(values);
    this.partnerSystemName = new PartnerSystemName(values);
    this.planDesignProvider = new PlanDesignProvider(values);
    this.planDesignTypeTemplate = new PlanDesignTypeTemplate(values);
    this.planYearEnd = new PlanYearEnd(values);
    this.priorProvider = new PriorProvider(values);
    this.profitSharing = new ProfitSharing(values);
    this.profitSharingStartDate = new ProfitSharingStartDate(values);
    this.profitSharingStrategy = new ProfitSharingType(values);
    this.QACASafeHarborMatch = new QACASafeHarborMatch(values);
    this.QACASafeHarborNonElective = new QACASafeHarborNonElective(values);
    this.rolloverMin = new RolloverMin(values);
    this.safeHarborEffectiveDate = new SafeHarborEffectiveDate(values);
    this.safeHarborMatchType = new SafeHarborMatchType();
    this.salaryRothDeferralEffectiveDate = new SalaryRothDeferralEffectiveDate(
      values
    );
    this.specialParticipationDate = new SpecialParticipationDate(values);
    this.specialEntryDate = new SpecialEntryDate(values);
    this.hasDualEligibility = new HasDualEligibility(values);
    this.employerMatchEntryDateFrequency =
      new DualEligibilityEntryDateFrequency(values.matchEligibilityRules);
    this.employerMatchMinimumAge = new DualEligibilityMinimumAge(
      values.matchEligibilityRules
    );
    this.employerMatchLengthOfEmploymentRequired =
      new DualEligibilityLengthOfEmploymentRequired(
        values.matchEligibilityRules
      );
    this.employerMatchHoursRequired = new DualEligibilityHoursRequired(
      values.matchEligibilityRules
    );
    this.profitSharingEntryDateFrequency =
      new DualEligibilityEntryDateFrequency(
        values.profitSharingEligibilityRules
      );
    this.profitSharingMinimumAge = new DualEligibilityMinimumAge(
      values.profitSharingEligibilityRules
    );
    this.profitSharingLengthOfEmploymentRequired =
      new DualEligibilityLengthOfEmploymentRequired(
        values.profitSharingEligibilityRules
      );
    this.profitSharingHoursRequired = new DualEligibilityHoursRequired(
      values.profitSharingEligibilityRules
    );
    this.inServiceSpecifiedAge = new SpecifiedAge(values);
    this.terminationDate = new TerminationDate(values);
    this.trustee = new Trustee(values);
    this.vestingMethod = new VestingMethod(values);
    this.vestwellStartDate = new VestwellStartDate(values);
    this.allowHardshipElective = new AllowHardshipElective(values);
    this.allowHardshipMatch = new AllowHardshipMatch(values);
    this.allowHardshipProfitSharing = new AllowHardshipProfitSharing(values);
    this.allowHardshipRollover = new AllowHardshipRollover(values);
    this.allowHardshipTransfer = new AllowHardshipTransfer(values);
    this.allowHardshipSafeHarbor = new AllowHardshipSafeHarbor(values);
    this.loanEmail = new LoanEmail(values);
  }
}

export default BasePlan;
