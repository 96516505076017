import {
  ChevronLeft as ChevronLeftIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Link,
  LinkProps,
  Menu,
  MenuItem,
  MenuProps,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { unstable_useId as useId } from '@mui/utils';

import {
  CellClickedEvent,
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  IsRowSelectable,
  RowClassParams,
  RowDataUpdatedEvent,
  RowSelectedEvent,
  RowStyle,
  SelectionChangedEvent,
  SortChangedEvent
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import Badge, { BadgeProps } from '../badge/Badge.component';
import './ag-theme-file-upload-table.css';
import './ag-theme-vestwell-admin-ui.css';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-064941}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Vestwell_Holdings_Inc.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{8}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_October_2025}____[v3]_[01]_MTc2MDM5NjQwMDAwMA==4cc4aaa3a097b2a8ad092a939dd2780a'
);

type NewSort = { colId: string; sort?: 'asc' | 'desc' };

// DataTableBadgeCell

type DataTableBadgeCellProps = Omit<BadgeProps, 'size'>;

export const DataTableBadgeCell: React.FunctionComponent<
  DataTableBadgeCellProps
> = props => <Badge size='small' {...props} />;

// DataTableMenuCell

type DataTableMenuCellProps = Partial<MenuProps>;

export const DataTableMenuCell: React.FunctionComponent<
  DataTableMenuCellProps
> = ({ children, open = false }) => {
  const buttonId = useId();
  const menuId = useId();
  const [buttonEl, setButtonEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setOpen] = useState(open);

  if (
    !children ||
    (Array.isArray(children) && children.filter(Boolean).length === 0)
  ) {
    return (
      <Tooltip title='No applicable action'>
        {/** div wrapper necessary for tooltip to appear despite disabled state */}
        <div>
          <IconButton aria-label='Actions' disabled id={buttonId}>
            <MoreVertIcon />
          </IconButton>
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <IconButton
        aria-controls={isOpen ? menuId : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='Actions'
        data-testid='user-actions-menu-btn'
        id={buttonId}
        onClick={() => setOpen(true)}
        ref={el => setButtonEl(el)}>
        <MoreVertIcon />
      </IconButton>
      {buttonEl && children && (
        <Menu
          MenuListProps={{
            'aria-labelledby': buttonId
          }}
          anchorEl={buttonEl}
          id={menuId}
          onClick={() => setOpen(!isOpen)}
          onClose={() => setOpen(false)}
          open={isOpen}>
          {children}
        </Menu>
      )}
    </>
  );
};

// DataTableMenuWithIcons
interface MenuItemsProps {
  component: JSX.Element;
  isHidden?: boolean;
}
interface DataTableMenuWithIconsProps extends DataTableMenuCellProps {
  className?: any;
  menuItems: MenuItemsProps[];
}

export const DataTableMenuWithIcons: React.FunctionComponent<
  DataTableMenuWithIconsProps
> = ({ open = false, menuItems }) => {
  const buttonId = useId();
  const menuId = useId();
  const [buttonEl, setButtonEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(open);

  return (
    <>
      <IconButton
        aria-controls={isOpen ? menuId : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='Actions'
        id={buttonId}
        onClick={() => {
          setIsOpen(true);
        }}
        ref={el => setButtonEl(el)}
        sx={{
          '&:hover': { backgroundColor: 'white', color: '#42a5f5' }
        }}>
        <MoreVertIcon fontSize='small' />
      </IconButton>
      {buttonEl && (
        <Menu
          MenuListProps={{
            'aria-labelledby': buttonId
          }}
          anchorEl={buttonEl}
          id={menuId}
          key={menuId}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          open={isOpen}>
          {menuItems.map((item, index) => {
            return item.isHidden ? null : (
              <div key={index}>{item.component}</div>
            );
          })}
        </Menu>
      )}
    </>
  );
};

// DataTableStackCell

interface DataTableStackCellProps
  extends React.HTMLAttributes<HTMLBaseElement> {
  primary: string;
  primaryLinkProps?: LinkProps & RouterLinkProps;
  secondary?: string;
  secondaryLinkProps?: LinkProps & RouterLinkProps;
  /**
   * Any icon component, rendered before children.
   * Be sure to add fontSize="inherit" to the icon element for proper sizing and set desired color.
   * We can't add that for you because the icon component is memoized.
   */
  icon?: React.ReactElement;
  /** Wrap overflow content rather than truncating it */
  wrap?: boolean;
}

const DataTableStackCellText: React.FunctionComponent<
  LinkProps & Partial<RouterLinkProps>
> = ({ children, to, onClick, sx, ...props }) =>
  to ? (
    <Link color='primary' component={RouterLink} sx={sx} to={to} {...props}>
      {children}
    </Link>
  ) : onClick ? (
    <Link
      color='primary'
      onClick={onClick}
      sx={{
        '&:hover': { cursor: 'pointer' },
        ...sx
      }}
      {...props}>
      {children}
    </Link>
  ) : (
    <Typography sx={sx} {...props}>
      {children}
    </Typography>
  );

const textTruncationProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

export const DataTableStackCell: React.FunctionComponent<
  DataTableStackCellProps
> = ({
  className,
  primary,
  primaryLinkProps,
  secondary,
  secondaryLinkProps,
  icon,
  wrap
}) => {
  return (
    <Stack
      alignItems='center'
      className={className}
      direction='row'
      spacing={1}
      sx={{
        py: 1
      }}>
      {icon && (
        <Stack // Stack component to prevent weird svg container height when using Box
          sx={{
            fontSize: 20,
            lineHeight: '24px'
          }}>
          {icon}
        </Stack>
      )}
      <Stack
        spacing={wrap ? 0.5 : 0}
        sx={{ overflow: wrap ? 'visible' : 'hidden' }}>
        <DataTableStackCellText
          {...primaryLinkProps}
          sx={{
            ...(wrap
              ? {
                  lineHeight: 1.2
                }
              : textTruncationProps)
          }}
          title={wrap ? undefined : primary} // browser tooltip
          variant='body1'>
          {primary}
        </DataTableStackCellText>
        {secondary && (
          <DataTableStackCellText
            {...secondaryLinkProps}
            sx={{
              ...(wrap
                ? {
                    lineHeight: 1.2
                  }
                : textTruncationProps),
              ...(!secondaryLinkProps
                ? { color: theme => theme.palette.grey[700] }
                : undefined)
            }}
            title={wrap ? undefined : secondary} // browser tooltip
            variant='body2'>
            {secondary}
          </DataTableStackCellText>
        )}
      </Stack>
    </Stack>
  );
};

// DataTablePagination

interface DataTablePaginationProps
  extends React.HTMLAttributes<HTMLBaseElement> {
  currentRowCount: number;
  paginationTotal?: number;
  page?: number;
  pageSize?: number;
  onPageChanged?: (newPage: number, newSort?: NewSort[]) => void;
  onPageSizeChanged?: (newPageSize: number) => void;
  pageSizeOptions?: number[];
  sx?: SxProps;
}

export const DataTablePagination: React.FunctionComponent<
  DataTablePaginationProps
> = ({
  currentRowCount,
  paginationTotal = 0,
  page = 1,
  pageSize = 25,
  onPageChanged,
  onPageSizeChanged,
  pageSizeOptions = [25, 50, 100],
  sx
}) => {
  const [pageState, setPageState] = useState(page);
  const [pageSizeState, setPageSizeState] = useState(pageSize);

  const handlePageChange = (direction: 'next' | 'prev') => {
    const newPage = direction === 'next' ? pageState + 1 : pageState - 1;
    setPageState(newPage);
    if (typeof onPageChanged === 'function') {
      onPageChanged(newPage);
    }
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    setPageState(1); // set page back to 1 when page size changes
    if (typeof onPageChanged === 'function') {
      onPageChanged(1);
    }
    const newPageSize = Number(event.target.value);
    setPageSizeState(newPageSize);
    if (typeof onPageSizeChanged === 'function') {
      onPageSizeChanged(newPageSize);
    }
  };

  useUpdateEffect(() => {
    //if page manually changes in parent component then also set it here
    if (pageState !== page) {
      setPageState(page);
    }
  }, [page]);

  return (
    <>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={1}
        sx={{
          background: theme => theme.palette.grey[25],
          px: 2,
          py: 1.375,
          ...sx
        }}>
        <Box>
          {paginationTotal > 0 && (
            <Typography
              sx={{
                color: theme => theme.palette.grey[900]
              }}
              variant='body2'>
              Showing {page * pageSize - pageSize + 1} -{' '}
              {(page - 1) * pageSize + currentRowCount} of {paginationTotal}
            </Typography>
          )}
        </Box>
        <Stack alignItems='center' direction='row' spacing={1}>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography
              sx={{
                color: theme => theme.palette.grey[700]
              }}
              variant='caption'>
              Rows per page
            </Typography>
            <Select
              data-testid='data-table-page-size'
              disableUnderline
              label='Rows per page'
              onChange={handlePageSizeChange}
              size='small'
              sx={{
                '& .MuiInput-input': {
                  py: 0 // ensure the number lines up with the arrow
                },
                fontSize: 12
              }}
              value={pageSizeState}
              variant='standard'>
              {pageSizeOptions.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack alignItems='center' direction='row'>
            <IconButton
              aria-label='Previous'
              disabled={pageState === 1}
              onClick={() => handlePageChange('prev')}
              size='small'
              sx={{ fontSize: 24 }}>
              <NavigateBeforeIcon fontSize='inherit' />
            </IconButton>
            <IconButton
              aria-label='Next'
              disabled={pageState * pageSizeState >= paginationTotal}
              onClick={() => handlePageChange('next')}
              size='small'
              sx={{ fontSize: 24 }}>
              <NavigateNextIcon fontSize='inherit' />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
    </>
  );
};

const paginateData = (
  data: Record<string, unknown>[] | [],
  pageSize: number,
  page: number
) => {
  const offset = pageSize * (page - 1);
  return [...data].slice(offset, pageSize ? pageSize + offset : data.length);
};

// DataTable

export interface DataTableProps extends React.HTMLAttributes<HTMLBaseElement> {
  /** Passing your own useRef<AgGridReact>(null) allows access to the grid */
  gridRef?: React.RefObject<AgGridReact<any>>;

  gridHeight?: string;

  columnDefs: Array<
    (ColGroupDef | ColDef) & { cellRenderer?: any; cellRendererParams?: any }
  >;

  defaultColDef?: ColDef;

  /** Column sizing behavior for initial render and resizing */
  columnSizing?: 'auto' | 'fit' | 'flex';

  columnTypes?: Record<string, ColDef>;

  'data-testid'?: string;

  rowData?: Record<string, unknown>[] | [];

  /**
   * Allows grouping tree data as detailed in https://www.ag-grid.com/react-data-grid/tree-data/
   * Provide a function that returns an array of strings that should match the parents up the tree
   *
   * ex. parent passed to this function returns ['United States'] and
   * child row passed to this function returns ['United States', 'Illinois']
   */
  getDataPath?: (data: any) => string[];
  /** Allows customizing the column that automatically gets added when row grouping happens */
  autoGroupColumnDef?: ColDef;

  /**
   * If provided, the component will add a expand/collapse icon on the left side of each row.
   */
  detailCellRenderer?: ({ data }: { data: any; context?: any }) => JSX.Element;
  detailColumn?: number;
  detailRowAutoHeight?: boolean;

  /**
   * What you provide here must be separately hooked up to actually filter the data.
   * This property handles the display of the filter side panel only.
   */
  filterSidePanelComponent?: React.ReactElement | React.ReactElement[];

  /**
   * There is a default no rows provided message, but you can override it here.
   */
  emptyPlaceholderComponent?: React.ReactElement | React.ReactElement[];

  groupDefaultExpanded?: number;
  pagination?: boolean;
  /**
   * The default client source handles pagination internally while server
   * side pagination is handled by the server.
   */
  paginationSource?: 'client' | 'server';
  /**
   * You need to provide the total number of results so that the pagination
   * component can display the correct information when doing server side pagination.
   */
  paginationTotal?: number;
  page?: number;
  pageSize?: number;
  onPageChanged?: (newPage: number, newSort?: NewSort[]) => void;
  onPageSizeChanged?: (newPageSize: number) => void;
  /**
   * You can provide a list of page sizes to display in the dropdown.
   * @default [25, 50, 100]
   */
  pageSizeOptions?: number[];
  paginationSx?: SxProps;
  paginationPosition?: 'top' | 'bottom';
  /**
   * Force sorting on grid render
   */
  sort?: { colId: string; sort?: 'asc' | 'desc' | null }[];
  /**
   * If doing server side sorting, you can provide a callback to handle the sorting.
   * Client side sorting is handled internally.
   */
  onSortChanged?: (newSort: NewSort[]) => void;
  /** Cell is clicked. */
  onCellClicked?(event: CellClickedEvent): void;
  /** Value has changed after editing. This event will not fire if editing was cancelled (eg ESC was pressed). */
  onCellValueChanged?(event: CellValueChangedEvent<any>): void;
  /** Callback to be used to determine which rows are selectable. By default rows are selectable, so return `false` to make a row un-selectable. */
  isRowSelectable?: IsRowSelectable;
  /** Type of Row Selection: `single`, `multiple`. */
  rowSelection?: 'single' | 'multiple';
  /** Row selection is changed. Use the grid API `getSelectedNodes()` to get the new list of selected nodes. */
  onSelectionChanged?(event: SelectionChangedEvent): void;
  /** If `true`, row selection won't happen when rows are clicked. Use when you only want checkbox selection. Default: `false` */
  suppressRowClickSelection?: boolean;
  /** Row is selected or deselected. The event contains the node in question, so call the node's `isSelected()` method to see if it was just selected or deselected. */
  onRowSelected?(event: RowSelectedEvent): void;
  /** The client has updated data for the grid by either a) setting new Row Data or b) Applying a Row Transaction. */
  onRowDataUpdated?(event: RowDataUpdatedEvent): void;
  /** The list of grid columns changed. */
  onGridColumnsChanged?(params: AgGridCallbackParams): void;
  /** Allows setting the ID for a particular row node based on the data. */
  primaryKey?: string;
  /** Set to `true` to enable Single Click Editing for cells, to start editing with a single click. Default: `false` */
  singleClickEdit?: boolean;
  /** Provides a context object that is provided to different callbacks the grid uses. Used for passing additional information to the callbacks by your application. */
  context?: any;
  /** Editing a cell has started. */
  onCellEditingStarted?(event: CellEditingStartedEvent): void;
  /** Editing a cell has stopped. */
  onCellEditingStopped?(event: CellEditingStoppedEvent): void;

  suppressHorizontalScroll?: boolean;

  alignedGrids?: { api: GridApi; columnApi: ColumnApi }[];

  stopEditingWhenCellsLoseFocus?: boolean;

  onGridReady?: (params: AgGridCallbackParams) => void;

  readOnlyEdit?: boolean;

  onCellEditRequest?: (params: AgGridCallbackParams) => void;

  suppressRowVirtualisation?: boolean;
  suppressColumnVirtualisation?: boolean;
  headerHeight?: number;
  rowHeight?: number;
  enterNavigatesVerticallyAfterEdit?: boolean;
  disableLastRowBorder?: boolean;
  animateRows?: boolean;
  themeName?: string;

  /** Icons to use inside the grid instead of the grid's default icons. */
  icons?: {
    [key: string]: string;
  };

  /** Switch between layout options: `normal`, `autoHeight`, `print`*/
  domLayout?: 'normal' | 'autoHeight' | 'print';
  suppressCellFocus?: boolean;

  /** set style for each row individually */
  getRowStyle?: (params: RowClassParams) => RowStyle | undefined;
}

type AgGridCallbackParams = {
  /** @link https://www.ag-grid.com/javascript-data-grid/grid-api/ */
  api?: GridApi;
  /** @link https://www.ag-grid.com/javascript-data-grid/column-api/ */
  columnApi?: ColumnApi;
};

export const DataTable: React.FunctionComponent<DataTableProps> = props => {
  const {
    alignedGrids,
    gridRef,
    gridHeight,
    rowHeight,
    columnDefs,
    columnTypes,
    domLayout = 'autoHeight',
    suppressCellFocus = false,
    defaultColDef,
    columnSizing = 'auto',
    'data-testid': testId = 'data-table',
    rowData = [],
    getDataPath,
    groupDefaultExpanded,
    autoGroupColumnDef,
    detailCellRenderer,
    detailColumn = 0,
    detailRowAutoHeight,
    filterSidePanelComponent,
    emptyPlaceholderComponent,
    pagination = false,
    paginationSource = 'client',
    paginationTotal = 0,
    page = 1,
    pageSize = 25,
    onPageChanged,
    onPageSizeChanged,
    pageSizeOptions = [25, 50, 100],
    paginationSx,
    paginationPosition = 'top',
    sort = [],
    onSortChanged,
    onCellClicked,
    onCellValueChanged,
    isRowSelectable,
    rowSelection,
    onSelectionChanged,
    suppressRowClickSelection,
    onRowSelected,
    onRowDataUpdated,
    onGridColumnsChanged,
    primaryKey,
    singleClickEdit = false,
    context = {},
    onCellEditingStarted,
    onCellEditingStopped,
    onGridReady,
    stopEditingWhenCellsLoseFocus = false,
    suppressHorizontalScroll = false,
    onCellEditRequest = () => {},
    readOnlyEdit = false,
    suppressColumnVirtualisation = false,
    suppressRowVirtualisation = false,
    headerHeight = undefined,
    enterNavigatesVerticallyAfterEdit = false,
    disableLastRowBorder,
    animateRows = false,
    themeName = 'ag-theme-vestwell-admin-ui',
    icons,
    getRowStyle
  } = props;

  // automatically infer this since why would they pass a getDataPath unless the data is grouped?
  const treeData = typeof getDataPath !== 'undefined';

  let $grid = useRef<AgGridReact>(null);
  if (typeof gridRef !== 'undefined') {
    $grid = gridRef;
  }

  const [isSidePanelCollapsed, setSidePanelCollapsed] =
    React.useState<boolean>(false);
  const [pageState, setPageState] = React.useState<number>(page);
  const [pageSizeState, setPageSizeState] = React.useState<number>(pageSize);
  const [paginatedData, setPaginatedData] = React.useState<
    Record<string, unknown>[] | []
  >([]);

  const theme = useTheme();

  useEffect(() => {
    if (!pagination) {
      setPaginatedData(rowData);
      return;
    }
    const newPaginatedData =
      paginationSource === 'server'
        ? rowData
        : paginateData(rowData, pageSizeState, pageState);

    if (!isEqual(paginatedData, newPaginatedData)) {
      setPaginatedData(newPaginatedData);
    }
  }, [
    pagination,
    pageSizeState,
    pageState,
    paginatedData,
    paginationSource,
    rowData
  ]);

  useUpdateEffect(() => {
    //if page manually changes in parent component then also set it here
    if (pageState !== page) {
      setPageState(page);
    }
  }, [page]);

  const handlePageChanged = useCallback(
    (newPage: number, newSort?: NewSort[]) => {
      setPageState(newPage);
      if (typeof onPageChanged === 'function') {
        onPageChanged(newPage, newSort);
      }
    },
    [onPageChanged]
  );

  const handlePageSizeChanged = useCallback(
    (newPageSize: number) => {
      setPageSizeState(newPageSize);
      if (typeof onPageSizeChanged === 'function') {
        onPageSizeChanged(newPageSize);
      }
    },
    [onPageSizeChanged]
  );

  if (
    typeof detailCellRenderer !== 'undefined' &&
    columnDefs &&
    // eslint-disable-next-line no-unsafe-optional-chaining
    'cellRenderer' in columnDefs?.[detailColumn]
  ) {
    // automatically handle adding the cellRenderer property to the first column
    // which prompts ag-grid to render the expand details arrow button
    if (typeof columnDefs[detailColumn].cellRenderer === 'function') {
      // if it is already a function, tell ag-grid to still use it
      columnDefs[detailColumn].cellRendererParams = {
        // provide an inner renderer to maintain the original cellRenderer
        innerRenderer: columnDefs[detailColumn].cellRenderer
      };
    }
    columnDefs[detailColumn].cellRenderer = 'agGroupCellRenderer';
  }

  const handleFirstRenderCallback = useCallback(
    (params: AgGridCallbackParams) => {
      if (columnSizing === 'auto') {
        params?.columnApi?.autoSizeAllColumns();
      }

      if (columnSizing === 'fit') {
        params?.api?.sizeColumnsToFit();
      }
    },
    [columnSizing, sort]
  );

  const handleGridReadyCallback = useCallback(
    (event: GridReadyEvent) => {
      event.columnApi.applyColumnState({
        defaultState: { sort: null },
        state: sort
      });

      if (onGridReady) {
        onGridReady(event);
      }
    },
    [columnSizing, sort, onGridReady]
  );

  const handleGridSizeChangedCallback = useCallback(
    (event: GridSizeChangedEvent) => {
      if (columnSizing === 'auto') {
        event.columnApi?.autoSizeAllColumns();
      }

      if (columnSizing === 'fit') {
        event.api?.sizeColumnsToFit();
      }
    },
    [columnSizing]
  );

  // remove the additional tabs from the column hamburger menu
  columnDefs.forEach(columnDef => {
    if ('menuTabs' in columnDef) {
      columnDef.menuTabs = ['generalMenuTab'];
    }
  });
  const getMainMenuItems = useCallback(() => {
    // customize the column hamburger menu
    return ['pinSubMenu', 'resetColumns'];
  }, []);

  const getContextMenuItems = useCallback(() => {
    // returning empty array disables the AG Grid context menu
    // this was desired because the copy functionality returns the raw unformatted
    // value not matching what they clicked on which would be confusing to users
    const result: string[] = [
      // 'copy',
      // 'copyWithHeaders',
      // 'copyWithGroupHeaders',
    ];
    return result;
  }, []);

  const handleOnSortChanged = useCallback(
    (event: SortChangedEvent) => {
      const gridColumns = event.columnApi.getColumnState() || [];

      // compile the sort state of each column into an array containing only columns that are sorted
      const sortState: { colId: string; sort: 'asc' | 'desc' }[] = [];
      gridColumns.forEach(column => {
        if (column.sort) {
          sortState.push({ colId: column.colId, sort: column.sort });
        }
      });

      if (JSON.stringify(sortState) !== JSON.stringify(sort)) {
        if (typeof onSortChanged === 'function') {
          onSortChanged(sortState);
        }
        handlePageChanged(1, sortState);
      }
    },
    [onSortChanged, sort, handlePageChanged]
  );

  const getRowId = useMemo(() => {
    if (primaryKey) {
      return (params: any) => params.data[primaryKey];
    }
    return undefined;
  }, [primaryKey]);

  // memo ensures that if using a filter side panel,
  // the table doesnt flicker every time the inputs are interacted with
  const AgGridTable = useMemo(
    () => (
      <div
        className={clsx(themeName, {
          'disable-last-row-border': disableLastRowBorder
        })}
        data-testid={testId}
        style={{ height: gridHeight, width: '100%' }}>
        <AgGridReact
          alignedGrids={alignedGrids}
          animateRows={animateRows}
          autoGroupColumnDef={autoGroupColumnDef}
          columnDefs={columnDefs}
          columnTypes={columnTypes}
          context={context}
          defaultColDef={defaultColDef}
          detailCellRenderer={detailCellRenderer}
          detailCellRendererParams={{ refreshStrategy: 'rows' }}
          detailRowAutoHeight={detailRowAutoHeight}
          domLayout={domLayout}
          enableCellTextSelection
          enterNavigatesVerticallyAfterEdit={enterNavigatesVerticallyAfterEdit}
          getContextMenuItems={getContextMenuItems}
          getDataPath={getDataPath}
          getMainMenuItems={getMainMenuItems}
          getRowId={getRowId}
          getRowStyle={getRowStyle}
          groupDefaultExpanded={groupDefaultExpanded}
          headerHeight={headerHeight}
          icons={icons}
          isRowSelectable={isRowSelectable}
          masterDetail={typeof detailCellRenderer !== 'undefined'}
          onCellClicked={onCellClicked}
          onCellEditRequest={onCellEditRequest}
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          onCellValueChanged={onCellValueChanged}
          onFirstDataRendered={handleFirstRenderCallback}
          onGridColumnsChanged={onGridColumnsChanged}
          onGridReady={handleGridReadyCallback}
          onGridSizeChanged={handleGridSizeChangedCallback}
          onRowDataUpdated={onRowDataUpdated}
          onRowSelected={onRowSelected}
          onSelectionChanged={onSelectionChanged}
          onSortChanged={handleOnSortChanged}
          readOnlyEdit={readOnlyEdit}
          ref={$grid}
          rowData={paginatedData}
          rowHeight={rowHeight}
          rowSelection={rowSelection}
          singleClickEdit={singleClickEdit}
          stopEditingWhenCellsLoseFocus={stopEditingWhenCellsLoseFocus}
          suppressCellFocus={suppressCellFocus}
          suppressColumnVirtualisation={
            process.env.NODE_ENV === 'test' ||
            process.env.STORYBOOK === 'true' ||
            suppressColumnVirtualisation
          }
          suppressHorizontalScroll={suppressHorizontalScroll}
          suppressRowClickSelection={suppressRowClickSelection}
          suppressRowVirtualisation={suppressRowVirtualisation}
          treeData={treeData}
        />
      </div>
    ),
    [
      gridHeight,
      rowHeight,
      context,
      headerHeight,
      suppressRowVirtualisation,
      suppressColumnVirtualisation,
      readOnlyEdit,
      onCellEditRequest,
      suppressHorizontalScroll,
      defaultColDef,
      alignedGrids,
      stopEditingWhenCellsLoseFocus,
      columnDefs,
      columnTypes,
      domLayout,
      suppressCellFocus,
      detailCellRenderer,
      detailRowAutoHeight,
      getMainMenuItems,
      getContextMenuItems,
      groupDefaultExpanded,
      handleGridReadyCallback,
      paginatedData,
      treeData,
      getDataPath,
      autoGroupColumnDef,
      testId,
      handleOnSortChanged,
      isRowSelectable,
      onCellClicked,
      onCellValueChanged,
      rowSelection,
      suppressRowClickSelection,
      onSelectionChanged,
      onRowSelected,
      onRowDataUpdated,
      onGridColumnsChanged,
      getRowId,
      singleClickEdit,
      onCellEditingStarted,
      onCellEditingStopped,
      enterNavigatesVerticallyAfterEdit,
      disableLastRowBorder,
      animateRows,
      themeName,
      icons,
      getRowStyle
    ]
  );

  const dataTablePagination = pagination && (
    <DataTablePagination
      currentRowCount={paginatedData.length}
      onPageChanged={handlePageChanged}
      onPageSizeChanged={handlePageSizeChanged}
      page={pageState}
      pageSize={pageSizeState}
      pageSizeOptions={pageSizeOptions}
      paginationTotal={
        paginationSource === 'server' ? paginationTotal : rowData?.length
      }
      sx={paginationSx}
    />
  );

  return (
    <>
      {filterSidePanelComponent && (
        <Stack
          data-testid={`${testId}-filter-side-panel`}
          direction='row'
          divider={<Divider flexItem orientation='vertical' />}>
          <Box sx={{ p: 2 }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{ pb: 2 }}>
              {isSidePanelCollapsed ? (
                <IconButton
                  aria-label='Show Filters'
                  onClick={() => setSidePanelCollapsed(false)}>
                  <FilterListIcon fontSize='inherit' />
                </IconButton>
              ) : (
                <>
                  <Stack alignItems='center' direction='row' spacing={1}>
                    <FilterListIcon fontSize='inherit' />
                    <Typography variant='h6'>Filters</Typography>
                  </Stack>
                  <IconButton
                    aria-label='Hide Filters'
                    onClick={() => setSidePanelCollapsed(true)}>
                    <ChevronLeftIcon fontSize='inherit' />
                  </IconButton>
                </>
              )}
            </Stack>
            <Collapse
              easing={theme.transitions.easing.sharp}
              in={!isSidePanelCollapsed}
              orientation='horizontal'>
              {filterSidePanelComponent}
            </Collapse>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            {(!paginatedData || paginatedData.length === 0) &&
              emptyPlaceholderComponent}
            {paginationPosition === 'top' &&
              pagination &&
              paginatedData?.length > 0 &&
              dataTablePagination}
            {paginatedData && paginatedData.length > 0 && AgGridTable}
            {paginationPosition === 'bottom' &&
              pagination &&
              paginatedData?.length > 0 &&
              dataTablePagination}
          </Box>
        </Stack>
      )}
      {!filterSidePanelComponent && (
        <>
          {(!paginatedData || paginatedData.length === 0) &&
            emptyPlaceholderComponent}
          {paginationPosition === 'top' &&
            pagination &&
            paginatedData?.length > 0 &&
            dataTablePagination}
          {paginatedData && paginatedData.length > 0 && AgGridTable}
          {paginationPosition === 'bottom' &&
            pagination &&
            paginatedData?.length > 0 &&
            dataTablePagination}
        </>
      )}
    </>
  );
};

export default DataTable;
