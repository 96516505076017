import AccessControl from '@/components/access-control/AccessControl.component';
import { CardContent } from '@/components/card/Card.component';
import DetailsPage from '@/components/details-page';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import ErrorMessage from '@/components/error-message';
import LinearLoading from '@/components/linear-loading';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import SimpleTextarea from '@/components/simple-textarea';
import TooltipButton from '@/components/tool-tip-button/TooltipButton.component';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { useNotesQuery } from '@/hooks/useNotes.hook';
import { useParticipantSuspiciousActivity } from '@/hooks/useParticipantSuspiciousActivity.hook';
import { ParticipantInfo, PlanDesignDto } from '@/models';
import { DeferralChangeDto } from '@/models/DeferralChangeDTO.model';
import { ParticipantAccountsDto } from '@/models/ParticipantAccountsDTO.model';
import { ParticipantCensusDataDto } from '@/models/ParticipantCensusDataDTO.model';
import { ParticipantExternalId } from '@/models/ParticipantExternalId.model';
import { ParticipantRegistrationInfo } from '@/models/ParticipantInfo.model';
import { PlanV2Dto } from '@/models/PlanV2DTO.model';
import Psa from '@/models/Psa.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { usePlanType } from '@/routes/plans/plan-detail/usePlanType.hook';
import ParticipantService from '@/services/Participant.service';
import { PlanService } from '@/services/Plan.service';
import { PsaService } from '@/services/Psa.service';
import { userService } from '@/services/User.service';
import UserManagementService from '@/services/UserManagement.service';
import { checkForAccountOverride } from '@/utils/VestingOverride';
import { Alert, CardHeader, Grid, Paper, Theme, useTheme } from '@mui/material';
import { blue } from '@mui/material/colors';
import Grid2 from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import clsx from 'clsx';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import Masonry from 'react-masonry-css';
import { useParams } from 'react-router-dom';

import AppliedIncentivesCard from './AppliedIncentives/AppliedIncentivesCard.component';
import { EsaParticipantBalances } from './Balance/EsaParticipantBalances.component';
import ParticipantBalances from './Balance/ParticipantBalances.component';
import ParticipantElectionsTab from './Elections/ParticipantElectionsTab.component';
import ParticipantGoals from './Goals/ParticipantGoals.component';
import ParticipantHoursOfServiceTile from './HoursOfService/ParticipantHoursOfServiceTile.component';
import { Investments } from './Investments/Investments.component';
import LoanDetailsRoute from './Loans/LoanDetails.route';
import LoanRequests from './Loans/LoanRequests.component';
import { NotesInfoCard } from './NotesInfoCard.component';
import ParticipantAccountStatusCard from './ParticipantAccountStatusCard.component';
import { ParticipantBeneficiariesCard } from './ParticipantBeneficiariesCard.component';
import ParticipantContactCard from './ParticipantContactCard.component';
import ParticipantContributionsCard from './ParticipantContributionsCard.component';
import ParticipantEligibilityCard from './ParticipantEligibilityCard.component';
import ParticipantEmploymentCard from './ParticipantEmploymentCard.component';
import ParticipantIdentificationCard from './ParticipantIdentificationCard.component';
import ParticipantStatementCenterCard from './ParticipantStatementCenterCard.component';
import ParticipantVestingDetailsCard from './ParticipantVestingDetailsCard.component';
import ParticipantVestingOverviewCard from './ParticipantVestingOverviewCard.component';
import ParticipantLinkedAccounts from './Personal/ParticipantLinkedAccounts.component';
import ParticipantUserCard from './Personal/ParticipantUserCard.component';
import RolloverDetailRoute from './Rollovers/RolloverDetail.route';
import RolloverRequests from './Rollovers/RolloverRequests.component';
import RothDetails from './RothDetails/RothDetails.component';
import CompletedTransactions from './Transactions/CompletedTransactions.component';
import PendingTransactions from './Transactions/PendingTransactions.component';
import WithdrawalDetailsRoute from './Withdrawals/WithdrawalDetails.route';
import WithdrawalRequests from './Withdrawals/WithdrawalRequests.component';

type ParticipantDetailRouteProps = {
  participantId: string;
  rolloverId?: string;
  loanId?: string;
  withdrawalId?: string;
};

export interface DeferralChangeDtoWithUUID extends DeferralChangeDto {
  uuid: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&:hover': {
      backgroundColor: blue[700]
    },
    backgroundColor: blue[500],
    color: theme.palette.getContrastText(blue[500]),
    height: theme.spacing(5),
    width: theme.spacing(15)
  },
  buttonPosition: {
    marginTop: theme.spacing(4)
  },
  masonryGrid: {
    display: 'flex',
    gap: theme.spacing(2),
    width: 'auto'
  },
  masonryGridColumn: {
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  },
  mutedText: {
    color: theme.palette.text.secondary
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 'max'
  },
  warningBanner: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.warning.dark
    },
    bg: '#ED6C02'
  }
}));

const ParticipantDetailRoute: React.FunctionComponent<
  ParticipantDetailRouteProps
> = () => {
  const [isEditing, setIsEditing] = useState(false);
  const params = useParams<ParticipantDetailRouteProps>();
  const classes = useStyles();
  const dialog = useDialog();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const theme = useTheme<Theme>();
  const token = useUserToken();
  const vestingNotesQuery = useNotesQuery(params.participantId, 'Vesting');
  const electionNotesQuery = useNotesQuery(params.participantId, 'Elections');

  const accountsQuery = useQuery<ParticipantAccountsDto>(
    ['ParticipantService.getParticipantAccounts', params.participantId],
    () => {
      return ParticipantService.getParticipantAccounts(params.participantId);
    },
    {
      enabled: Boolean(params.participantId && token.userHasValidToken)
    }
  );

  const externalIdQuery = useQuery<ParticipantExternalId>(
    ['ParticipantService.getParticipantExternalId', params.participantId],
    () => {
      return ParticipantService.getParticipantExternalId(params.participantId);
    },
    {
      enabled: Boolean(params.participantId && token.userHasValidToken)
    }
  );

  const participantQuery = useQuery<ParticipantInfo, { message: string }>(
    // moshe: hard code the first part of the cache key for testing!
    // otherwise mocking services changes method name, which
    // causes tons of cache collisions and makes tests break :(
    ['ParticipantService.getParticipantById', params.participantId],
    async () => {
      return ParticipantService.getParticipantById(params.participantId);
    },
    {
      enabled: Boolean(params.participantId && token.userHasValidToken)
    }
  );

  const participantCensusDataQuery = useQuery<ParticipantCensusDataDto>(
    [
      'ParticipantService.getParticipantCensusTableData',
      params.participantId,
      participantQuery.data?.sponsorPlanId
    ],
    () => {
      if (!participantQuery.data?.sponsorPlanId)
        throw new Error(`keep the compiler happy`);

      return ParticipantService.getParticipantCensusTableData(
        params.participantId,
        participantQuery.data.sponsorPlanId
      );
    },
    {
      enabled:
        Boolean(params.participantId) &&
        Boolean(participantQuery.data?.sponsorPlanId)
    }
  );

  const planQuery = useQuery<PlanV2Dto, { message: string }>(
    [
      'PlanService.getPlanById',
      participantQuery.data?.sponsorPlanId?.toString()
    ],
    () => {
      if (!participantQuery.data?.sponsorPlanId)
        throw new Error(`keep the compiler happy`);

      return PlanService.getPlanById(participantQuery.data.sponsorPlanId);
    },
    {
      enabled: Boolean(
        params.participantId &&
          token.userHasValidToken &&
          participantQuery.data?.sponsorPlanId
      )
    }
  );

  const planType = usePlanType({
    recordkeeper: planQuery.data?.data.attributes.recordkeeper,
    type: planQuery.data?.data.attributes.type
  });

  const planDesignQuery = useQuery<PlanDesignDto>(
    ['PlanService.getPlanDesignById', participantQuery.data?.sponsorPlanId],
    () => {
      if (!participantQuery.data?.sponsorPlanId)
        throw new Error(`keep the compiler happy`);

      return PlanService.getPlanDesignById(participantQuery.data.sponsorPlanId);
    },
    {
      enabled:
        Boolean(participantQuery.data?.sponsorPlanId) &&
        Boolean(planQuery.data?.data) &&
        !planType.isStateIRA &&
        !planType.isVoyaPlan
    }
  );

  const sponsorPlanQuery = useQuery<PlanV2Dto>(
    ['PlanService.getPlanById', participantQuery.data?.sponsorPlanId],
    () => {
      if (!participantQuery.data?.sponsorPlanId)
        throw new Error(`keep the compiler happy`);

      return PlanService.getPlanById(participantQuery.data.sponsorPlanId);
    },
    {
      enabled: Boolean(participantQuery.data?.sponsorPlanId)
    }
  );

  const psaQuery = useQuery<Psa, { message: string }>(
    ['PsaService.getPsa', params.participantId],
    () => {
      return PsaService.getPsa(params.participantId);
    },
    {
      enabled: Boolean(params.participantId && token.userHasValidToken)
    }
  );

  const refreshParticipantEligibilityMutation = useMutation(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    (isSnackbar: boolean) =>
      ParticipantService.refreshParticipantEligibility(
        participant.participantId as number,
        participant.sponsorPlanId as number
      ),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Failed',
          severity: 'error'
        });
      },
      onSuccess: async (_, isSnackbar) => {
        await queryClient.refetchQueries([
          'ParticipantService.getParticipantById',
          params.participantId
        ]);
        if (isSnackbar) {
          snackbar.showSnackbar({
            message: 'Success! The eligibility calculation has been refreshed.',
            severity: 'success'
          });
        }
      }
    }
  );

  const registrationQuery = useQuery<ParticipantRegistrationInfo>(
    ['ParticipantService.getRegistrationStatus', +params.participantId],
    () => {
      return ParticipantService.getRegistrationStatus(+params.participantId);
    },
    {
      enabled: Boolean(params.participantId && token.userHasValidToken)
    }
  );

  const openEditNotesModal = useCallback(
    (query?, mutation?, e?: () => void) => {
      dialog.openDialog({
        actionButtons: {
          cancelButton: {
            children: 'Cancel'
          },
          submitButton: {
            children: 'Save'
          }
        },
        onSubmit: async values => {
          mutation.mutate(
            {
              id: query.data?.data?.id,
              notes: values.notes,
              participantId: params.participantId,
              serviceNoteSourceId: query.data?.data?.serviceSourceId
            },
            {
              onError: () => {
                snackbar.showSnackbar({
                  message: 'Failed',
                  severity: 'error'
                });
              },
              onSuccess: () => {
                if (typeof e === 'function') {
                  snackbar.hideSnackbar();
                  e();
                } else {
                  query.refetch();
                  snackbar.showSnackbar({
                    message: 'Success! Vesting Notes saved',
                    severity: 'success'
                  });
                }
              }
            }
          );
        },
        steps: [
          {
            fields: {
              notes: {
                component: (
                  <SimpleTextarea
                    data-testid='notes'
                    label=''
                    name='notes'
                    placeholder='Notes'
                  />
                ),
                initialValue: query.data?.data?.notes ?? '',
                label: 'Notes'
              }
            },
            title: 'Edit Notes'
          }
        ]
      });
    },
    []
  );
  const isMainRecordkeeper = useMemo(
    () =>
      [
        'LT',
        'Vestwell (RK) - Folio',
        'Vestwell Sub-Accounting Platform'
      ].includes(planQuery.data?.data?.attributes.recordkeeper ?? ''),
    [planQuery.data?.data.attributes.recordkeeper]
  );

  const isEsaRecordkeeper = useMemo(
    () => planQuery.data?.data?.attributes.recordkeeper === 'Vestwell ESA',
    [planQuery.data?.data.attributes.recordkeeper]
  );

  const isVestwellSubaccounting = useMemo(
    () =>
      planQuery.data?.data.attributes.recordkeeper ===
      'Vestwell Sub-Accounting Platform',
    [planQuery.data?.data.attributes.recordkeeper]
  );

  const masonryStyles = useMemo(() => {
    return {
      breakpointCols: {
        default: 2,
        [theme.breakpoints.values.lg]: 1
      },
      className: clsx('Participant-detail__MasonryGrid', classes.masonryGrid),
      columnClassName: classes.masonryGridColumn
    };
  }, [
    classes.masonryGridColumn,
    classes.masonryGrid,
    theme.breakpoints.values.lg
  ]);

  const employmentData =
    participantCensusDataQuery.data || ({} as ParticipantCensusDataDto);

  const isTimeElapsedPlan = Boolean(
    planDesignQuery.data?.data?.vestingPreferences?.vestingMethod ===
      'Elapsed Time'
  );

  const isVestingOverride = checkForAccountOverride(psaQuery.data);

  const participant = participantQuery.data || ({} as ParticipantInfo);

  const planName = planQuery.data?.data.attributes.name || '';

  const paths = planName
    ? [
        {
          name: planType.urlPlansTypeName,
          to: `/${planType.urlPlansType}`
        },
        {
          name: planName,
          to: `/${planType.urlPlansType}/${participant.sponsorPlanId}`
        }
      ]
    : [];

  const registrationInfo =
    registrationQuery.data || ({} as ParticipantRegistrationInfo);

  const suspiciousQuery = useParticipantSuspiciousActivity(
    params.participantId
  );
  const suspiciousActivity = suspiciousQuery.data?.hasSuspiciousActivity;

  const cantAccessEsa506472 =
    !userService.hasPermission(FeatureLevelPermissions.READ_ESA_506472) &&
    participant.sponsorPlanId === 506472;

  const hasPlan1ReadPermission = userService.hasPermission(
    FeatureLevelPermissions.READ_PLAN_1
  );

  if (
    (participant.sponsorPlanId === 1 && !hasPlan1ReadPermission) ||
    cantAccessEsa506472
  ) {
    return redirectToErrorPage(new Error('403'));
  }

  const error = participantQuery.error || planQuery.error;

  if (error) {
    return redirectToErrorPage(error as Error);
  }

  const makeTabsElement = (): SimpleTabsProps => {
    const personalTabComponents = (
      <Grid container data-testid='grid-personal' spacing={2}>
        <Grid item xs={6}>
          <ParticipantIdentificationCard
            employmentData={employmentData}
            isStateIRA={planType.isStateIRA}
            isVoyaPlan={planType.isVoyaPlan}
            participant={participant}
            planName={planName}
            planType={planQuery.data?.data.attributes.type || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <ParticipantContactCard
            isStateIRA={planType.isStateIRA}
            isVoyaPlan={planType.isVoyaPlan}
            participant={participant}
            planName={planName}
            planType={planQuery.data?.data.attributes.type || ''}
          />
        </Grid>
        {!planType.isStateIRA && (
          <Grid item xs={6}>
            <ParticipantAccountStatusCard
              isStateIRA={planType.isStateIRA}
              isVoyaPlan={planType.isVoyaPlan}
              participant={participant}
              participantId={params.participantId}
              registration={registrationInfo}
              sponsorPlanId={participant.sponsorPlanId}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <ParticipantBeneficiariesCard participantId={+params.participantId} />
        </Grid>
        {!planType.isStateIRA && (
          <Grid item xs={6}>
            <Suspense
              fallback={
                <Paper
                  data-testid='participant-user-list'
                  elevation={0}
                  variant='outlined'>
                  <CardHeader title='Login Information' />
                  <CardContent loading />
                </Paper>
              }>
              <ParticipantUserCard participantId={+params.participantId} />
            </Suspense>
          </Grid>
        )}

        {['401k', 'ESA'].includes(planQuery.data?.data?.attributes?.type) && (
          <Grid item xs={6}>
            <ParticipantLinkedAccounts participantId={+params.participantId} />
          </Grid>
        )}
      </Grid>
    );
    const employmentTabComponents = (
      <Masonry {...masonryStyles}>
        <ParticipantEmploymentCard
          employmentData={employmentData}
          externalId={participant.externalId}
          isEsa={planType.isEsa}
          isStateIRA={planType.isStateIRA}
          isTimeElapsedPlan={isTimeElapsedPlan}
          isVestingOverride={isVestingOverride}
          participant={participant}
          refreshParticipantEligibilityMutation={
            refreshParticipantEligibilityMutation
          }
        />

        <ParticipantEligibilityCard
          dupAddrId={
            externalIdQuery.data?.mapping?.data?.surpasEmployeeDupAddrId || '--'
          }
          isEsa={planType.isEsa}
          isStateIRA={planType.isStateIRA}
          participant={participant}
          refreshParticipantEligibilityMutation={
            refreshParticipantEligibilityMutation
          }
          surpasAccount={externalIdQuery.data?.mapping?.externalId || '--'}
          terminationDate={employmentData.data?.dateOfTermination}
        />

        {!planType.isStateIRA && !planType.isVoyaPlan && !planType.isEsa && (
          <ParticipantHoursOfServiceTile
            participantId={params.participantId}
            planEffectiveDate={planQuery.data?.data.attributes.effectiveDate}
            planId={participant.sponsorPlanId}
          />
        )}
      </Masonry>
    );

    const electionsTabComponents = (
      <ParticipantElectionsTab
        accountInfo={accountsQuery.data}
        electionNotesQuery={electionNotesQuery}
        isEsa={planType.isEsa}
        isStateIRA={planType.isStateIRA}
        isVestwellSubAccounting={isVestwellSubaccounting}
        isVoyaPlan={planType.isVoyaPlan}
        openEditNotesModal={() =>
          openEditNotesModal(
            electionNotesQuery.notesQuery,
            electionNotesQuery.notesMutation
          )
        }
        participant={participant}
        participantId={+params.participantId}
        permission={FeatureLevelPermissions.WRITE_VESTING}
        planData={planQuery.data}
        userHasValidToken={token.userHasValidToken}
      />
    );

    const balanceTabComponents = (
      <>
        <Masonry {...masonryStyles}>
          <ParticipantContributionsCard
            annualMax={participant.contributionsIrsLimit}
            isEsa={planType.isEsa}
            isStateIRA={planType.isStateIRA}
            participantId={+params.participantId}
          />
          {accountsQuery.data &&
            !planType.isStateIRA &&
            !planType.isVoyaPlan &&
            (planType.isEsa ? (
              <EsaParticipantBalances
                accountsResponseData={accountsQuery.data}
              />
            ) : (
              <ParticipantBalances
                accountsResponseData={accountsQuery.data}
                participantsResponseData={participant}
              />
            ))}
          {participantQuery.isSuccess && !planType.isStateIRA && (
            <ParticipantStatementCenterCard participant={participant} />
          )}
        </Masonry>
        {planType.isEsa && <ParticipantGoals />}
      </>
    );

    const vestingTabComponent = (
      <Grid container display='flex' flexWrap='wrap' spacing={4}>
        <Grid item>
          {!planType.isStateIRA && !planType.isVoyaPlan && (
            <ParticipantVestingOverviewCard
              accountInfo={accountsQuery.data}
              isEditing={isEditing}
              isLoading={
                accountsQuery.isFetching ||
                planDesignQuery.isFetching ||
                psaQuery.isFetching
              }
              openEditNotesModal={openEditNotesModal}
              participantId={params.participantId}
              planDesign={planDesignQuery.data}
              psaData={psaQuery.data?.psa}
              setIsEditing={setIsEditing}
              vestingNotesQuery={vestingNotesQuery}
            />
          )}
        </Grid>
        <Grid item>
          {!planType.isStateIRA && !planType.isVoyaPlan && (
            <>
              <NotesInfoCard
                emptyMessage='No vesting-related notes for this participant'
                notes={vestingNotesQuery.notesQuery.data?.data?.notes}
                onClickEdit={() =>
                  openEditNotesModal(
                    vestingNotesQuery.notesQuery,
                    vestingNotesQuery.notesMutation
                  )
                }
                permission={FeatureLevelPermissions.WRITE_VESTING}
                sx={{ mb: 5 }}
                title='vesting'
              />
              <ParticipantVestingDetailsCard
                accountInfo={accountsQuery.data}
                isEditing={isEditing}
                isLoading={
                  accountsQuery.isFetching ||
                  planDesignQuery.isFetching ||
                  psaQuery.isFetching
                }
                participantId={params.participantId}
                planDesign={planDesignQuery.data}
                psaData={psaQuery.data?.psa}
                setIsEditing={setIsEditing}
              />
            </>
          )}
        </Grid>
      </Grid>
    );

    const transactionsTabComponent = (
      <>
        <Grid2 container spacing={4}>
          <Grid2 xs={12}>
            <PendingTransactions participantId={params.participantId} />
          </Grid2>
          <Grid2 xs={planType.isEsa ? 8 : 12}>
            <CompletedTransactions
              isVestwellSubaccounting={isVestwellSubaccounting}
              participantId={params.participantId}
            />
          </Grid2>
          {planType.isEsa && (
            <Grid2 xs={4}>
              <AppliedIncentivesCard participant={participantQuery.data} />
            </Grid2>
          )}
        </Grid2>
      </>
    );

    const withdrawalsTabComponent = params.withdrawalId
      ? {
          component: <WithdrawalDetailsRoute />,
          label: 'Withdrawals',
          path: `/participant/${params.participantId}/withdrawals/${params.withdrawalId}`
        }
      : {
          component: (
            <WithdrawalRequests
              isVestwellSubaccounting={isVestwellSubaccounting}
              participantId={params.participantId}
            />
          ),
          label: 'Withdrawals',
          path: `/participants/${params.participantId}/withdrawals`
        };

    const rothDetailsTabComponent = (
      <RothDetails participantId={params.participantId} />
    );
    const rolloversTabComponent = params.rolloverId
      ? {
          component: (
            <RolloverDetailRoute
              custodianAccountNumber={
                planDesignQuery.data?.data?.recordkeeperAndCustodian
                  ?.custodianAccountNumber
              }
              participantId={params.participantId}
              rolloverId={params.rolloverId}
            />
          ),
          label: 'Rollovers',
          path: `/participant/${params.participantId}/rollovers/${params.rolloverId}`
        }
      : {
          component: (
            <RolloverRequests
              participantId={params.participantId}
              rk={planQuery.data?.data?.attributes?.recordkeeper}
              sponsorPlanId={participantQuery.data?.sponsorPlanId}
            />
          ),
          label: 'Rollovers',
          path: `/participants/${params.participantId}/rollovers`
        };

    const loansTabComponent = params.loanId
      ? {
          component: (
            <LoanDetailsRoute
              loanId={params.loanId}
              participantId={params.participantId}
            />
          ),
          label: 'Loans',
          path: `/participant/${params.participantId}/loans/${params.loanId}`
        }
      : {
          component: (
            <LoanRequests
              participantId={+params.participantId}
              recordkeeper={planQuery.data?.data?.attributes.recordkeeper || ''}
            />
          ),
          label: 'Loans',
          path: `/participants/${params.participantId}/loans`
        };

    const registeredTabs: TabData[] = [
      {
        component: personalTabComponents,
        label: 'Personal',
        path: `/participants/${params.participantId}/personal`
      },
      {
        component: employmentTabComponents,
        label: 'Employment',
        path: `/participants/${params.participantId}/employment`
      },
      {
        component: electionsTabComponents,
        label: 'Elections',
        path: `/participants/${params.participantId}/elections`
      },
      {
        component: balanceTabComponents,
        label: 'Balance',
        path: `/participants/${params.participantId}/balance`
      },
      !planType.isEsa
        ? {
            component: <Investments participantId={+params.participantId} />,
            label: 'Investments',
            path: `/participants/${params.participantId}/investments`
          }
        : undefined,
      !planType.isStateIRA && !planType.isVoyaPlan && !planType.isEsa
        ? {
            component: vestingTabComponent,
            label: 'Vesting',
            path: `/participants/${params.participantId}/vesting`
          }
        : null,
      !planType.isStateIRA
        ? {
            component: transactionsTabComponent,
            label: 'Transactions',
            path: `/participants/${params.participantId}/transactions`
          }
        : undefined,
      isMainRecordkeeper || isEsaRecordkeeper
        ? withdrawalsTabComponent
        : undefined,
      isMainRecordkeeper ? rolloversTabComponent : undefined,
      isMainRecordkeeper ? loansTabComponent : undefined,
      isMainRecordkeeper
        ? {
            component: rothDetailsTabComponent,
            label: 'Roth Details',
            path: `/participants/${params.participantId}/roth-details`
          }
        : undefined
    ].flatMap(tab => tab ?? []);

    const hideInvestmentTab = (tab: TabData): boolean =>
      planType.isStateIRA || planType.isVoyaPlan
        ? tab.label !== 'Investments'
        : true;

    const tabElements = registeredTabs.filter(hideInvestmentTab);

    return {
      tabs: tabElements,
      tabsAriaLabel: 'plan-detail-tabs'
    };
  };

  const tabs = makeTabsElement();

  const requestError =
    participantQuery.error || psaQuery.error || planQuery.error;

  const isLoading =
    participantQuery.isFetching ||
    psaQuery.isFetching ||
    planQuery.isFetching ||
    participantCensusDataQuery.isFetching;

  const isSuccess =
    participantQuery.isSuccess &&
    psaQuery.isSuccess &&
    planQuery.isSuccess &&
    participantCensusDataQuery.isSuccess;

  return (
    <>
      {isLoading && <LinearLoading />}
      {isSuccess && (
        <Grid
          className={classes.root}
          container
          data-testid='participant-detail-component'>
          <Grid item xs={11}>
            <DetailsPage
              copyableNumber={participant.participantId}
              isStateIRA={planType.isStateIRA}
              paths={paths}
              programName={
                sponsorPlanQuery.data?.data?.relationships?.firm?.data?.name
              }
              title={`${participant.lastName}, ${participant.firstName}`}
              variant='participant'
            />
            {[5060].includes(participant.sponsorPlanId) && (
              <Alert severity='error'>
                Due to various circumstances involving this plan, the servicing
                team should not engage with any participant or plan sponsor (or
                anyone calling on their behalf) before informing Legal.
              </Alert>
            )}
          </Grid>
          {!planType.isStateIRA && !planType.isVoyaPlan && (
            <Grid className={classes.buttonPosition} item xs={1}>
              <AccessControl
                requires={[
                  FeatureLevelPermissions.WRITE_MASQUERADE_PARTICIPANT
                ]}>
                <TooltipButton
                  disabled={!participant.registeredOn}
                  handleOnClick={() => {
                    const url = UserManagementService.getSentinelRedirectUrl(
                      'participant',
                      params.participantId
                    );
                    window.open(url, '_blank');
                  }}
                  testId='participant-detail-route-view-portal-btn'
                  tooltipMessage='Saver must be registered in order to view portal'
                  variant='contained'>
                  View Portal
                </TooltipButton>
              </AccessControl>
            </Grid>
          )}
          <Grid container item spacing={2}>
            {suspiciousActivity && (
              <Grid item xs={5}>
                <Alert className={classes.warningBanner} severity='warning'>
                  There is potentially suspicious activity on this account. No
                  changes should be made to this account at this time. Please
                  escalate any requests related to this account to the Legal and
                  Compliance teams.
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <SimpleTabs {...tabs} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {requestError && (
        <ErrorMessage
          error={requestError.message || 'An Unknown Error Occurred'}
        />
      )}
    </>
  );
};

export default ParticipantDetailRoute;
