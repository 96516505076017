import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FC } from 'react';

type BypassYetStepButtonProps = {
  eventName: string;
  completed: boolean;
  sponsorPlanId: number;
  year: number;
  previousStepDone?: boolean;
};
export const BypassYetStepButton: FC<BypassYetStepButtonProps> = props => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const postWorkflowProcessEvent = useMutation(
    [
      'PlanService.postYetProcessEvent',
      props.sponsorPlanId,
      props.year,
      props.eventName,
      { bypass: true }
    ],
    () => {
      return PlanService.postYetProcessEvent(
        props.sponsorPlanId,
        props.year,
        props.eventName,
        { bypass: true }
      );
    },
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Error while bypassing step, please try again later',
          severity: 'error'
        });
      },
      onSuccess: () => {
        snackbar.showSnackbar({
          message: 'Step bypassed successfully',
          severity: 'success'
        });
        queryClient.invalidateQueries([
          'PlanService.getSponsorPlanYearEndTesting',
          props.sponsorPlanId,
          props.year
        ]);
      }
    }
  );

  return (
    <AccessControl
      requires={[FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS]}>
      <Tooltip
        title={
          props.previousStepDone ? '' : 'Please complete the previous step'
        }>
        <span>
          <LoadingButton
            data-testid={`bypass-plan-${props.sponsorPlanId}-yet-${props.year}-${props.eventName}-button`}
            disabled={!props.previousStepDone || props.completed}
            loading={postWorkflowProcessEvent.isLoading}
            onClick={() => postWorkflowProcessEvent.mutateAsync()}
            variant='text'>
            Bypass
          </LoadingButton>
        </span>
      </Tooltip>
    </AccessControl>
  );
};
