import Card, { CardHeader } from '@/components/card';
import CachedIcon from '@mui/icons-material/Cached';
import { Box, CircularProgress } from '@mui/material';
import { useQueries } from '@tanstack/react-query';

import React, { useState } from 'react';

import ActionHistoryStepperFilters, {
  ActionTypes
} from './ActionCenterStepperFilters.component';
import ActionHistoryTable from './ActionHistoryTable.component';

const ActionHistoryCard: React.FunctionComponent = () => {
  const [actionType, setActionType] = useState<ActionTypes | ''>('');

  const queries = useQueries({
    queries: [
      {
        enabled: actionType === ActionTypes.BULK_UPLOAD_FILES,
        queryKey: ['ActionCenterService.getBulkEmailActions'],
        staleTime: Infinity
      },
      {
        enabled: actionType === ActionTypes.BULK_EMAIL,
        queryKey: ['ActionCenterService.getBulkUploadActions'],
        staleTime: Infinity
      },
      {
        enabled: actionType === ActionTypes.BULK_EMPLOYER,
        queryKey: ['ActionCenterService.bulkEmployerList'],
        staleTime: Infinity
      },
      {
        enabled: actionType === ActionTypes.BULK_WELCOME_EMAILS,
        queryKey: ['ActionCenterService.getBulkWelcomeEmailActions'],
        staleTime: Infinity
      },
      {
        enabled: actionType === ActionTypes.ANNUAL_NOTICE,
        queryKey: ['ActionCenterService.getAnnualNoticesActions'],
        staleTime: Infinity
      }
    ]
  });

  const queriesLoading = queries.some(query => query.isRefetching);

  return (
    <Card
      data-testid='action-history-card'
      sx={{ marginBottom: theme => theme.spacing(1), padding: '0px' }}>
      <Box display='flex' height='100%'>
        <ActionHistoryStepperFilters
          onActionTypeChange={newActionType => setActionType(newActionType)}
        />

        <Box display='flex' flex='1' flexDirection='column'>
          <CardHeader
            actionButtonsProps={[
              {
                disabled: !!queriesLoading,
                label: (
                  <>
                    {!queriesLoading ? (
                      <CachedIcon />
                    ) : (
                      <CircularProgress size={24} />
                    )}
                  </>
                ),
                onClick: () => {
                  queries.forEach(query => query.refetch());
                },
                tooltipTitle: 'Refresh bulk actions data'
              }
            ]}
            disableDivider
            title='Action History'
          />
          <Box
            alignItems='center'
            display='flex'
            flex='1'
            justifyContent='center'>
            <ActionHistoryTable actionType={actionType} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
export default ActionHistoryCard;
