import { createSvgIcon, SvgIconProps } from '@mui/material';

import React, { FC } from 'react';

export const ReEnrollmentFull: FC<SvgIconProps> = createSvgIcon(
  <svg
    fill='none'
    height='40'
    viewBox='0 0 40 40'
    width='40'
    xmlns='http://www.w3.org/2000/svg'>
    <rect fill='white' height='40' width='40' />
    <circle
      cx='9.99992'
      cy='10'
      fill='#D9D9D9'
      r='6.66667'
      stroke='#757575'
      strokeWidth='3.33333'
    />
    <circle
      cx='9.99992'
      cy='30'
      r='6.66667'
      stroke='#757575'
      strokeWidth='3.33333'
    />
    <rect
      height='13.3333'
      rx='2'
      stroke='#757575'
      strokeWidth='3.33333'
      width='13.3333'
      x='23.3333'
      y='13.3333'
    />
    <path
      clipRule='evenodd'
      d='M20.9297 26.3983C18.1223 28.8061 14.7869 31.6667 10 31.6667V28.3333C13.4581 28.3333 15.9217 26.3005 18.9153 23.7346C18.9668 23.6905 19.0185 23.6462 19.0703 23.6017C21.8777 21.1939 25.2131 18.3333 30 18.3333V21.6667C26.5419 21.6667 24.0783 23.6995 21.0847 26.2654C21.0332 26.3096 20.9815 26.3539 20.9297 26.3983Z'
      fillRule='evenodd'
      stroke='white'
      strokeWidth='3.33333'
    />
    <path
      d='M10 30C18.3333 30 21.6667 20 30 20'
      stroke='#757575'
      strokeWidth='3.33333'
    />
    <circle cx='9.99992' cy='30' fill='#757575' r='1.66667' />
    <path
      clipRule='evenodd'
      d='M20.9297 13.6017C18.1223 11.1939 14.7869 8.33332 10 8.33332V11.6667C13.4581 11.6667 15.9217 13.6995 18.9153 16.2654C18.9668 16.3095 19.0185 16.3538 19.0703 16.3983C21.8777 18.8061 25.2131 21.6667 30 21.6667V18.3333C26.5419 18.3333 24.0783 16.3005 21.0847 13.7346C21.0332 13.6904 20.9815 13.6461 20.9297 13.6017Z'
      fillRule='evenodd'
      stroke='white'
      strokeWidth='3.33333'
    />
    <path
      d='M10 10C18.3333 10 21.6667 20 30 20'
      stroke='#757575'
      strokeWidth='3.33333'
    />
    <circle cx='30.0001' cy='20' fill='#757575' r='3.33333' />
    <circle cx='9.99992' cy='10' fill='#757575' r='1.66667' />
  </svg>,
  'ReEnrollmentFullIcon'
);

ReEnrollmentFull.displayName = 'ReEnrollmentFullIcon';
