import AccessControl from '@/components/access-control/AccessControl.component';
import DatePicker from '@/components/date-picker';
import SimpleDropdown from '@/components/simple-dropdown';
import Constants from '@/consts/planDesign.constants';
import { InfoOutlined } from '@mui/icons-material';
import { Alert, Box, FormControl, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconTooltip } from '@vestwell-frontend/ui';

import { Field, useFormikContext } from 'formik';
import { FC, useCallback, useMemo, useState } from 'react';

import { EligibilityRules } from './EligibilityRules.component';
import { hideFieldFromTpa } from './utils';

type DualEligibilityProps = {
  data: Record<string, any>;
};

const useStyles = makeStyles(() => ({
  warning: {
    '& .MuiAlert-icon': {
      alignItems: 'center',
      display: 'flex',
      padding: 0
    }
  }
}));

export const DualEligibility: FC<DualEligibilityProps> = props => {
  const classes = useStyles();
  const [tabId, setTabId] = useState(0);

  const { values, errors, initialValues } = useFormikContext<{
    hasDualEligibility: boolean;
    serviceCalculationType: string;
    partnerSystemName: string;
  }>();

  const isSubA = useMemo(
    () => values.partnerSystemName === 'Vestwell Sub-Accounting Platform',
    [values.partnerSystemName]
  );

  const leasedEmployeesOptions = useMemo(
    () =>
      Constants.excludedIncludedOptions?.map(value => ({
        option: value,
        value: value
      })),
    []
  );

  const onTabChange = useCallback((event, id: number) => setTabId(id), []);

  return (
    <>
      {isSubA && (
        <Box mb={3}>
          <Box alignItems='center' display='flex'>
            <Field name='hasDualEligibility' type='checkbox' />
            <Typography mx={1}>Has Dual Eligibility</Typography>
            <IconTooltip
              placement='top'
              trigger={<InfoOutlined fontSize='small' />}>
              Employer match/profit sharing eligibility does not follow employee
              deferrals.
            </IconTooltip>
          </Box>
          {values.hasDualEligibility && (
            <Alert className={classes.warning} severity='warning'>
              Employer discretionary match will be calculated by Vestwell based
              on salary and contribution data provided by the Plan Sponsor.
              Please review important details{' '}
              <a href='/' target='_blank'>
                here
              </a>
              .
            </Alert>
          )}
          {!!initialValues.hasDualEligibility && !values.hasDualEligibility && (
            <Alert className={classes.warning} severity='warning'>
              Warning: Removing dual eligibility will set employer match and
              profit sharing source to the same eligibility as employee
              deferrals and disable match calculation. This change must be
              communicated to employer/payroll provider in order to ensure that
              match will be calculated going forward.
            </Alert>
          )}
        </Box>
      )}
      <Box display='flex' flexDirection='column' gap={3} mb={3}>
        <AccessControl hideFromTPA={hideFieldFromTpa('excludeLeasedEmployees')}>
          <SimpleDropdown
            data-testid='excludeLeasedEmployees'
            disabled={props.data?.excludeLeasedEmployees?.disabled}
            fieldId='excludeLeasedEmployees'
            fieldName='Leased Employees'
            fieldOptions={leasedEmployeesOptions}
          />
        </AccessControl>
        <Box display='flex' gap={5}>
          <AccessControl
            hideFromTPA={hideFieldFromTpa('specialParticipationDate')}>
            <FormControl fullWidth>
              <Field
                as={DatePicker}
                data-testid='specialParticipationDate'
                disabled={props.data?.specialParticipationDate?.disabled}
                label='Special Participation Waiver Date'
                name='specialParticipationDate'
                showFormikErrorOnly
              />
            </FormControl>
          </AccessControl>
          <AccessControl hideFromTPA={hideFieldFromTpa('specialEntryDate')}>
            <FormControl fullWidth>
              <Field
                as={DatePicker}
                data-testid='specialEntryDate'
                disabled={props.data?.specialEntryDate?.disabled}
                label='Special Entry Date'
                name='specialEntryDate'
                showFormikErrorOnly
              />
            </FormControl>
          </AccessControl>
        </Box>
      </Box>

      {!values.hasDualEligibility && (
        <EligibilityRules data={props.data} type='salaryDeferral' />
      )}

      {isSubA && values.hasDualEligibility && (
        <Box sx={{ width: '100%' }}>
          <Box my={3}>
            <Tabs onChange={onTabChange} value={tabId}>
              <Tab label='Salary Deferral & Others' />
              <Tab label='Employer Match' />
              <Tab label='Profit Sharing' />
            </Tabs>
          </Box>
          <Box>
            {tabId === 0 && (
              <EligibilityRules data={props.data} type='salaryDeferral' />
            )}
            {tabId === 1 && (
              <EligibilityRules data={props.data} type='employerMatch' />
            )}
            {tabId === 2 && (
              <EligibilityRules data={props.data} type='profitSharing' />
            )}
          </Box>
        </Box>
      )}
      {isSubA && errors.hasDualEligibility && (
        <Box mt={3}>
          <Alert severity='error'>
            Employer match or profit sharing must have a different eligibility
            rule then employee deferrals for dual eligibility plans.
          </Alert>
        </Box>
      )}
    </>
  );
};
