import CollapsibleTable from '@/components/collapsible-table';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';

import clsx from 'clsx';
import { useMemo } from 'react';

import { PositionsFields } from './PositionsTable.component';
import PositionsTableCell from './PositionsTableCell.component';

interface InvestmentElectionHistorySummaryProps {
  currentModel: {
    state: {
      allocation: string;
      id: string;
      maxTickerLength: number;
      security: {
        cusip: string;
        fundName: string;
        id: string;
      };
    }[];
    fromTs: string;
    investmentOptionId: number;
    modelId?: number;
    modelName?: string;
    modelType: string;
    realizedModelId: number;
    thruTs?: string;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  blurb: {
    background: grey[50],
    fontSize: theme.spacing(1.6),
    fontWeight: 400,
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`
  },
  headerCell: {
    color: alpha(theme.palette.common.black, 0.6),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25)
  },
  headerCols: {
    color: grey[600],
    fontSize: theme.spacing(1.85),
    fontWeight: 500
  },
  modelInfoHeading: {
    color: alpha(theme.palette.common.black, 0.54),
    fontSize: theme.spacing(1.75)
  },
  root: {
    borderLeft: `1px solid ${grey[300]}`,
    maxHeight: 564,
    overflow: 'auto',
    paddingTop: theme.spacing(2)
  },
  securityCell: {
    width: '76%'
  },
  tableHeader: {
    color: grey[600]
  }
}));

const getModelLabel = (modelType?: string) =>
  modelType
    ? `${modelType.charAt(0).toUpperCase()}${modelType.slice(1)}-based`
    : '--';

export const InvestmentElectionHistorySummary = (
  props: InvestmentElectionHistorySummaryProps
): JSX.Element => {
  const classes = useStyles();
  const columns: GridColDef[] = useMemo(
    () => [
      {
        cellClassName: clsx(classes.headerCell, classes.securityCell),
        field: PositionsFields.SECURITY,
        headerName: 'Security'
      },
      {
        cellClassName: classes.headerCell,
        field: PositionsFields.ALLOCATION,
        headerName: 'Allocation'
      }
    ],
    [classes]
  );

  return (
    <Box
      className={classes.root}
      data-testid='participant-investment-election-history-summary-card'>
      <Box display='flex' gap={4} padding={2} paddingTop={0}>
        <Box>
          <Typography
            className={classes.modelInfoHeading}
            data-testid='history-summary-model-id-field'>
            Model ID
          </Typography>
          <Typography data-testid='history-summary-model-id-value'>
            {props.currentModel.realizedModelId}
          </Typography>
        </Box>
        <Box>
          <Typography
            className={classes.modelInfoHeading}
            data-testid='history-summary-model-type-field'>
            Type
          </Typography>
          <Typography data-testid='history-summary-model-type-value'>
            {getModelLabel(props.currentModel.modelType)}
          </Typography>
        </Box>
      </Box>
      <Box
        className={classes.blurb}
        data-testid='history-summary-info-disclaimer'>
        The model allocation shown below reflects the latest version, not the
        allocation as of the investment election change.
      </Box>
      {!props.currentModel.state?.length ? (
        <Box data-testid='history-summary-card-no-data' padding={2}>
          No funds data found for this model.
        </Box>
      ) : (
        <CollapsibleTable
          backgroundPaperElevation={0}
          cellComponent={PositionsTableCell}
          columns={columns}
          data-testid='history-summary-table'
          disablePagination
          pager={{
            rowsPerPage: 100
          }}
          primaryKey='cusip'
          rootPaperElevation={0}
          tableData={props.currentModel.state}
          useSquareBottomContainer
        />
      )}
    </Box>
  );
};

InvestmentElectionHistorySummary.displayName =
  'InvestmentElectionHistorySummary';
