import { createSvgIcon, SvgIconProps } from '@mui/material';

import React, { FC } from 'react';

export const ReEnrollmentPartial: FC<SvgIconProps> = createSvgIcon(
  <svg
    fill='none'
    height='40'
    viewBox='0 0 40 40'
    width='40'
    xmlns='http://www.w3.org/2000/svg'>
    <rect fill='white' height='40' width='40' />
    <circle
      cx='9.99992'
      cy='9.99998'
      r='6.66667'
      stroke='#757575'
      strokeWidth='3.33333'
    />
    <circle
      cx='9.99992'
      cy='30'
      r='6.66667'
      stroke='#757575'
      strokeWidth='3.33333'
    />
    <rect
      height='13.3333'
      rx='2'
      stroke='#757575'
      strokeWidth='3.33333'
      width='13.3333'
      x='23.3333'
      y='3.33331'
    />
    <rect
      height='13.3333'
      rx='2'
      stroke='#757575'
      strokeWidth='3.33333'
      width='13.3333'
      x='23.3333'
      y='23.3333'
    />
    <rect fill='white' height='6.66667' width='20' x='11.6667' y='6.66669' />
    <rect fill='white' height='6.66667' width='20' x='11.6667' y='26.6667' />
    <path
      d='M10 10H30'
      stroke='#757575'
      strokeLinecap='round'
      strokeWidth='3.33333'
    />
    <path
      d='M10 30H30'
      stroke='#757575'
      strokeLinecap='round'
      strokeWidth='3.33333'
    />
    <circle cx='30.0001' cy='10' fill='#757575' r='3.33333' />
    <circle cx='30.0001' cy='30' fill='#757575' r='3.33333' />
  </svg>,
  'ReEnrollmentPartialIcon'
);

ReEnrollmentPartial.displayName = 'ReEnrollmentPartialIcon';
