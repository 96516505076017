import Card, { CardHeader } from '@/components/card/Card.component';
import DownloadCSVButton from '@/components/download-csv-button/DownloadCSVButton.component';
import { SubAccountApiIncludeOption } from '@/models/ops/accounts/SubAccountApiIncludeOption.model';
import FundingSource from '@/models/ops/FundingSourceEnum.model';
import SubAccountService from '@/services/ops/accounts/SubAccount.service';
import formatters from '@/utils/Formatters';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';

import { FC } from 'react';

import { OperationalSubAccounts } from '../../common/OperationalSubAccounts.component';
import { SubAccounts } from '../../common/SubAccounts.component';
import { useSubaPlansDetailRouteParams } from '../useSubaPlansDetailRouteParams.hook';

const exportParticipantSubAccounts = async (planId: string) => {
  return (
    await SubAccountService.searchSubAccounts({
      hasPlanId: true,
      include: [
        SubAccountApiIncludeOption.cashBalance,
        SubAccountApiIncludeOption.totalMarketValue
      ],
      page: 1,
      pageSize: 0,
      planId
    })
  ).results?.map(subAccount => ({
    'Sub Account ID': subAccount.subAccountId,
    // eslint-disable-next-line sort-keys-plus/sort-keys
    'Part ID': subAccount.investorId,
    'Participant Name':
      subAccount.participant?.firstName || subAccount.participant?.lastName
        ? `${subAccount.participant?.firstName} ${subAccount.participant?.lastName}`
        : '',
    // eslint-disable-next-line sort-keys-plus/sort-keys
    'Funding Source': subAccount.fundingSource
      ? FundingSource[subAccount.fundingSource]
      : '',
    'Market Value': subAccount.totalMarketValue,
    // eslint-disable-next-line sort-keys-plus/sort-keys
    'Cash Balance': subAccount.cashBalance,
    'Created At': formatters.formatFromIsoDate(subAccount.createdAt)
  }));
};

export const SubAccountsTab: FC = () => {
  const routeParams = useSubaPlansDetailRouteParams();
  return (
    <Grid container spacing={2}>
      <Grid lg={4} xs={12}>
        <Card>
          <CardHeader title='Operational Accounts' />
          <OperationalSubAccounts
            DataTableProps={{
              columnSizing: 'fit',
              ['data-testid']: 'data-operational-accounts'
            }}
            planId={routeParams.planId}
          />
        </Card>
      </Grid>
      <Grid lg={8} xs={12}>
        <Card>
          <CardHeader title='Participant Sub Accounts'>
            <DownloadCSVButton
              buttonProps={{
                sx: {
                  fontSize: 'small'
                },
                variant: 'outlined'
              }}
              fileName={`SubAccounts-Plan-${routeParams.planId}-Participants`}
              getInfo={() => exportParticipantSubAccounts(routeParams.planId)}
              text='Export CSV'
            />
          </CardHeader>
          <SubAccounts
            accountType={[
              SubAccountType.participantCash,
              SubAccountType.participantOutsideInvestment,
              SubAccountType.participantStandard
            ]}
            customDefaultFilters={{
              hasPlanId: true
            }}
            data-testid='data-participant-accounts'
            planId={routeParams.planId}
          />
        </Card>
      </Grid>
    </Grid>
  );
};
