import CopyToClipboard from '@/components/copy-to-clipboard';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import SimpleTabs, { TabData } from '@/components/simple-tabs';
import { useGetSubaPlanQuery } from '@/hooks/ops/useGetSubaPlanQuery.hook';
import { useParentAccountById } from '@/hooks/ops/useParentAccountById.hook';
import { useTitle } from '@/hooks/useTitle';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { SearchAlerts } from '@/routes/ops/common/SearchAlerts.component';
import { SearchReconExceptions } from '@/routes/ops/common/SearchReconExceptions.component';
import { Transactions } from '@/routes/ops/common/transactions/Transactions.component';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { Stack, Typography } from '@mui/material';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';

import { FC, useMemo, useState } from 'react';

import { Conductor } from '../common/Conductor.component';
import { CreateMenu, CreateMenuItem } from '../common/CreateMenu.component';
import { Holdings } from '../common/holdings/Holdings.component';
import { TransferCashDrawer } from '../common/TransferCashDrawer.component';
import { SubAccountsTab } from './components/SubAccountsTab.component';
import { SubaPlanDetailsHeader } from './components/SubaPlanDetailsHeader.component';
import { useSubaPlansDetailRouteParams } from './useSubaPlansDetailRouteParams.hook';

enum CreateAction {
  CashTransfer = 'CashTransfer'
}

export const SubaPlansDetailRoute: FC = () => {
  const [action, setAction] = useState<CreateAction | null>(null);
  const routeParams = useSubaPlansDetailRouteParams();
  const getParentAccountByIdQuery = useParentAccountById({
    accountId: routeParams.accountId
  });
  const getSubaPlanQuery = useGetSubaPlanQuery({
    planId: routeParams.planId
  });

  const PAGE_NAME =
    getSubaPlanQuery.data?.plan?.name ||
    getSubaPlanQuery.data?.planId ||
    'Plan';

  const title = useTitle();

  const tabElements: TabData[] = useMemo(
    // memo to prevent tabs being unloaded and reloaded when this component state changes
    () => [
      {
        component: <SubAccountsTab />,
        label: 'Sub Accounts',
        path: `/ops/accounts/${routeParams.accountId}/plans/${routeParams.planId}/sub-accounts`
      },
      {
        component: <Holdings planId={routeParams.planId} />,
        label: 'Holdings',
        path: `/ops/accounts/${routeParams.accountId}/plans/${routeParams.planId}/holdings`
      },
      {
        component: (
          <Transactions
            accountId={routeParams.accountId}
            accountLevel={AccountLevel.SubAccount}
            hideAccountLevelToggle
            planId={routeParams.planId}
          />
        ),
        label: 'Transactions',
        path: `/ops/accounts/${routeParams.accountId}/plans/${routeParams.planId}/transactions`
      },
      {
        component: <Conductor planId={routeParams.planId} />,
        label: 'Conductor',
        path: `/ops/accounts/${routeParams.accountId}/plans/${routeParams.planId}/conductor`
      },
      {
        component: (
          <SearchAlerts
            onAlertOpen={alert => {
              title.setPageName(
                `${PAGE_NAME} Alert ${alert.id} ${alert.alertName}`
              );
            }}
            onDrawerClose={() => {
              title.setPageName(`${PAGE_NAME} Alerts`);
            }}
            onTabChange={newAlertType => {
              title.setPageName(
                `${PAGE_NAME} Alerts ${
                  newAlertType ? formatters.displayCase(newAlertType) : ''
                }`
              );
            }}
            planId={routeParams.planId}
          />
        ),
        label: 'Alerts',
        path: `/ops/accounts/${routeParams.accountId}/plans/${routeParams.planId}/alerts`
      },
      {
        component: <SearchReconExceptions planId={routeParams.planId} />,
        label: 'Recon',
        path: `/ops/accounts/${routeParams.accountId}/plans/${routeParams.planId}/recon`
      }
    ],
    [routeParams.accountId, routeParams.planId]
  );

  if (!userService.hasPermission(FeatureLevelPermissions.READ_SUBA_ACCOUNTS)) {
    return redirectToErrorPage(new Error('403'));
  }

  if (getParentAccountByIdQuery.isInitialLoading) return <LinearLoading />;

  return (
    <>
      {action === CreateAction.CashTransfer &&
        getParentAccountByIdQuery.data && (
          <TransferCashDrawer
            onClose={() => setAction(null)}
            onSubmit={() => setAction(null)}
            open
            parentAccountId={getParentAccountByIdQuery.data.parentAccountId}
            planId={routeParams.planId}
          />
        )}
      <Stack position='relative' spacing={4} useFlexGap>
        <Stack>
          <NavigationBreadcrumbs
            data-testid='account-detail-breadcrumbs'
            paths={[
              { name: 'Search Accounts', to: '/ops/accounts' },
              {
                name: getParentAccountByIdQuery.data?.accountName,
                to: `/ops/accounts/${routeParams.accountId}`
              }
            ]}
          />
          <Typography role='heading' variant='h4'>
            {PAGE_NAME}
          </Typography>
          <Stack direction='row' spacing={2}>
            <Stack alignItems='flex-end' direction='row'>
              <Typography data-testid='plan-id' variant='subtitle1'>
                ID: {getSubaPlanQuery.data?.planId}
              </Typography>
              <CopyToClipboard
                copyName='Plan ID'
                copyValue={getSubaPlanQuery.data?.planId}
              />
            </Stack>
          </Stack>
        </Stack>
        <SubaPlanDetailsHeader />
        <CreateMenu>
          {userService.hasPermission(
            FeatureLevelPermissions.WRITE_SUBA_TRANSFER_CASH
          ) && (
            <CreateMenuItem
              data-testid='cash-transfer-menu-item'
              onClick={() => setAction(CreateAction.CashTransfer)}>
              Cash Transfer
            </CreateMenuItem>
          )}
        </CreateMenu>
        <SimpleTabs
          data-testid='plans-detail-tabs'
          onChange={index => {
            if (tabElements[index].label !== 'Alerts') {
              title.setPageName(`${PAGE_NAME} ${tabElements[index].label}`);
            }
          }}
          tabs={tabElements}
          tabsAriaLabel='plans-detail-tabs'
        />
      </Stack>
    </>
  );
};
