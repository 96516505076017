import Constants from '@/consts/planDesign.constants';
import { EligibilityRule } from '@/models/PlanDesign.model';

class DualEligibilityHoursRequired {
  disabled: boolean;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  output: number | string | undefined;

  serviceCalculationTypeValue: string | undefined;

  constructor(eligibilityRules: EligibilityRule[]) {
    this.output = this.transform(eligibilityRules);

    this.serviceCalculationTypeValue =
      this.getServiceCalculationTypeValue(eligibilityRules);

    this.disabled =
      !this.serviceCalculationTypeValue ||
      ['No Requirement', 'Specified Months - Elapsed Time'].includes(
        this.serviceCalculationTypeValue || ''
      );
  }

  private transform = (rules: EligibilityRule[]) => {
    const serviceRequirement = rules?.find(
      rule => rule.eligibilityRequirementTypeName !== 'Age'
    );
    return serviceRequirement?.ruleParameter?.hours?.toString();
  };

  getServiceCalculationTypeValue = (
    rules: EligibilityRule[] | undefined
  ): string | undefined => {
    if (!rules) {
      return '';
    }

    const eligibilityRule = rules.find(rule => {
      return Constants.eligibilityRequirementTypes
        .map(type => type.eligibilityRequirementTypeName)
        .includes(rule.eligibilityRequirementTypeName || '');
    });

    return eligibilityRule?.ruleParameter?.months === 0 &&
      eligibilityRule.eligibilityRequirementTypeId === 2
      ? 'No Requirement'
      : eligibilityRule?.eligibilityRequirementTypeName;
  };

  convertToApiState = (value: string): number | undefined =>
    value ? parseInt(value, 10) : undefined;
}

export default DualEligibilityHoursRequired;
