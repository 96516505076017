import { CardPlaceholder } from '@/components/card';
import CircularLoading from '@/components/circular-loading';
import CollapsibleTable, { Order } from '@/components/collapsible-table';
import ParticipantService from '@/services/Participant.service';
import {
  Box,
  FormControl,
  Unstable_Grid2 as Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Theme,
  Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { orderBy as orderData } from 'lodash';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import CompletedTransactionsCollapsible, {
  CompletedTransactionsTableCell
} from './CompletedTransactionsCollapsible.component';

interface CompletedTransactionsProps {
  participantId: string;
  isVestwellSubaccounting: boolean;
}

export type YearsListParams = {
  start: number;
  finish?: number;
  sort?: 'asc' | 'desc';
};

enum Fields {
  EFFECTIVE_DATE = 'effectiveDate',
  TRANSACTION_TYPE = 'mappedCategory',
  AMOUNT = 'amount',
  COMPLETION_DATE = 'tradeDate'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    amountCellWidth: {
      width: '15%'
    },
    effectiveDateCellWidth: {
      width: '22%'
    },
    headerTableTitle: {
      padding: theme.spacing(2)
    },
    noData: {
      fontSize: theme.spacing(2.4),
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      textAlign: 'center'
    },
    paper: {
      width: '100%'
    },
    title: {
      marginBottom: theme.spacing(1),
      textAlign: 'left'
    },
    transactionTypeCellWidth: {
      width: '32%'
    }
  })
);

const typeOptions = [
  'All',
  'Adjustment',
  'Buy',
  'Contribution',
  'Corrective Earnings',
  'Deposit',
  'Distribution',
  'Dividend',
  'Fee',
  'Forfeiture',
  'Loan Payment',
  'Loan Withdrawal',
  'Rebalance',
  'Rollover',
  'Sell',
  'Transfer'
];

const initialPeriod = {
  label: 'Last 90 Days',
  value: {
    endDate: dayjs().format('MM-DD-YYYY'),
    startDate: dayjs().subtract(90, 'days').format('MM-DD-YYYY')
  }
};

const CompletedTransactions: FC<CompletedTransactionsProps> = props => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('effectiveDate');
  const [type, setType] = useState('All');
  const [period, setPeriod] = useState(initialPeriod);

  const tradeStatus = props.isVestwellSubaccounting ? 'CONFIRMED' : undefined;

  const headerColumns = useMemo(
    () => [
      {
        cellClassName: classes.effectiveDateCellWidth,
        field: Fields.EFFECTIVE_DATE,
        headerName: 'Effective Date'
      },
      {
        cellClassName: classes.transactionTypeCellWidth,
        field: Fields.TRANSACTION_TYPE,
        headerName: 'Transaction Type'
      },
      {
        cellClassName: classes.amountCellWidth,
        field: Fields.AMOUNT,
        headerName: 'Amount'
      },
      { field: Fields.COMPLETION_DATE, headerName: 'Completion Date' }
    ],
    [classes]
  );

  const years = useQuery(
    ['ParticipantService.getGroupedTransactions', props.participantId],
    () => ParticipantService.getTransactionsYears(+props.participantId),
    {
      select: data => [
        {
          label: 'Last 30 Days',
          value: {
            endDate: dayjs().format('MM-DD-YYYY'),
            startDate: dayjs().subtract(30, 'days').format('MM-DD-YYYY')
          }
        },
        initialPeriod,
        {
          label: 'Year to Date',
          value: {
            endDate: dayjs().format('MM-DD-YYYY'),
            startDate: dayjs().startOf('year').format('MM-DD-YYYY')
          }
        },
        ...orderData(
          (data.meta.years || [])
            .filter(item => item.year !== new Date().getFullYear())
            .map(item => {
              const dateOfTheYear = new Date(item.year, 1, 1);

              return {
                label: item.year.toString(),
                value: {
                  endDate: dayjs(dateOfTheYear)
                    .endOf('year')
                    .format('MM-DD-YYYY'),
                  startDate: dayjs(dateOfTheYear)
                    .startOf('year')
                    .format('MM-DD-YYYY')
                }
              };
            }),
          'label',
          'desc'
        )
      ],
      staleTime: Infinity
    }
  );

  const completedTransactionsQuery = useQuery(
    [
      'ParticipantService.getGroupedTransactions',
      props.participantId,
      period.value.startDate,
      period.value.endDate,
      type,
      tradeStatus
    ],
    () =>
      ParticipantService.getGroupedTransactions({
        categories: type === 'All' ? undefined : type,
        endDate: period.value.endDate,
        participantId: props.participantId,
        startDate: period.value.startDate,
        tradeStatus
      }),
    {
      select: completedTransactions =>
        completedTransactions.map(completedTransaction => ({
          ...completedTransaction,
          effectiveDate: dayjs(completedTransaction.effectiveDate),
          id: uuidv4(),
          mappedCategory:
            completedTransaction.category === 'My Contribution'
              ? 'Employee Contribution'
              : completedTransaction.category === 'Unknown'
                ? 'Other'
                : completedTransaction.category,
          tradeDate: dayjs(completedTransaction.tradeDate)
        })),
      staleTime: Infinity
    }
  );

  const sortedData = useMemo(() => {
    const combinedOrderBy =
      orderBy === Fields.EFFECTIVE_DATE
        ? [orderBy, Fields.TRANSACTION_TYPE]
        : orderBy;
    const combinedOrder =
      orderBy === Fields.EFFECTIVE_DATE ? ([order, 'desc'] as Order[]) : order;

    return orderData(
      completedTransactionsQuery.data || [],
      combinedOrderBy,
      combinedOrder
    );
  }, [completedTransactionsQuery.data, order, orderBy]);

  const handleTypeChange = useCallback(
    event => {
      setType(event.target.value);
    },
    [setType]
  );

  const handlePeriodChange = useCallback(
    event => {
      setPeriod(years.data?.find(y => y.label === event.target.value));
    },
    [setPeriod, years.data]
  );

  return (
    <Paper className={classes.paper} elevation={0} variant='outlined'>
      <Stack gap={2}>
        <Box paddingTop={2} px={2}>
          <Typography component='div' id='completedTitle' variant='h5'>
            Completed Transactions
          </Typography>
        </Box>
        <Grid container gap={2} px={2}>
          <Grid lg={1.8} xs={2}>
            <FormControl fullWidth>
              <InputLabel>Time Period</InputLabel>
              <Select
                data-testid='time-period-dd'
                label='Time Period'
                onChange={handlePeriodChange}
                size='small'
                value={period.label}>
                {years.data?.map(year => (
                  <MenuItem key={year.label} value={year.label}>
                    {year.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid lg={2.5} xl={2} xs={3}>
            <FormControl fullWidth>
              <InputLabel>Transaction Type</InputLabel>
              <Select
                data-testid='transaction-type-dd'
                label='Transaction Type'
                onChange={handleTypeChange}
                size='small'
                value={type}>
                {typeOptions.map(type => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {completedTransactionsQuery.isFetching ? (
          <Stack alignItems='center'>
            <CircularLoading />
          </Stack>
        ) : (
          <div className='Plan-Completed_Transactions_Table'>
            <CollapsibleTable
              cellComponent={CompletedTransactionsTableCell}
              collapsibleComponent={CompletedTransactionsCollapsible}
              columns={headerColumns}
              noDataPlaceholderComponent={
                <CardPlaceholder
                  data-testid='no-data-completed-transactions-participant'
                  subtitle={`${
                    completedTransactionsQuery.isError
                      ? 'Error'
                      : 'No data for this participant'
                  }`}
                />
              }
              pager={{
                metaCount: sortedData?.length,
                onPageNumberChanged: (zeroIndexedPageNumber: number) => {
                  return setPageNumber(zeroIndexedPageNumber + 1);
                },
                onRowsPerPageChanged: (newRowsPerPage: number) => {
                  return setRowsPerPage(newRowsPerPage);
                },
                pageNumber: pageNumber - 1,
                rowsPerPage
              }}
              sorter={{
                onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
                  setOrderBy(newOrderBy);
                  setOrder(newOrder);
                },
                order,
                orderBy
              }}
              tableData={sortedData?.slice(
                rowsPerPage * pageNumber - rowsPerPage,
                rowsPerPage * pageNumber
              )}
              useDivAsBackground
            />
          </div>
        )}
      </Stack>
    </Paper>
  );
};

export default CompletedTransactions;
