import AccessControl from '@/components/access-control/AccessControl.component';
import SimpleDropdown from '@/components/simple-dropdown';
import Constants from '@/consts/planDesign.constants';
import { Box } from '@mui/material';

import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { FC, useMemo } from 'react';

import { hideFieldFromTpa } from './utils';

type EligibilityRulesProps = {
  data: Record<string, any>;
  type: 'salaryDeferral' | 'employerMatch' | 'profitSharing';
};

export const EligibilityRules: FC<EligibilityRulesProps> = props => {
  const entryDateFrequencyOptions = useMemo(
    () =>
      Constants.entryDateFrequencies?.map(value => ({
        option: value.type,
        value: value.type
      })),
    []
  );

  const minimumAgeOptions = useMemo(
    () =>
      Constants.minimumAgeOptions.map(value => ({
        option: value === 0 ? 'None' : value?.toString(),
        value: value === 0 ? 'None' : value?.toString()
      })),
    []
  );

  const serviceCalculationTypeOptions = useMemo(
    () =>
      Constants.eligibilityRequirementTypes.map(value => ({
        option: value.eligibilityRequirementTypeName,
        value: value.eligibilityRequirementTypeName
      })),
    []
  );

  const lengthOfEmploymentRequiredOptions = useMemo(
    () =>
      Constants.lengthOfEmploymentRequiredOptions.map(value => ({
        option: value.label,
        value: value.label
      })),
    []
  );

  const fields: {
    entryDateFrequency?: string;
    minimumAge?: string;
    serviceCalculationType?: string;
    lengthOfEmploymentRequired?: string;
    hoursRequired?: string;
  } = {
    ...(props.type === 'salaryDeferral'
      ? {
          entryDateFrequency: 'entryDateFrequency',
          hoursRequired: 'hoursRequired',
          lengthOfEmploymentRequired: 'lengthOfEmploymentRequired',
          minimumAge: 'minimumAge',
          serviceCalculationType: 'serviceCalculationType'
        }
      : {}),
    ...(props.type === 'employerMatch'
      ? {
          entryDateFrequency: 'employerMatchEntryDateFrequency',
          hoursRequired: 'employerMatchHoursRequired',
          lengthOfEmploymentRequired: 'employerMatchLengthOfEmploymentRequired',
          minimumAge: 'employerMatchMinimumAge',
          serviceCalculationType: 'employerMatchServiceCalculationType'
        }
      : {}),
    ...(props.type === 'profitSharing'
      ? {
          entryDateFrequency: 'profitSharingEntryDateFrequency',
          hoursRequired: 'profitSharingHoursRequired',
          lengthOfEmploymentRequired: 'profitSharingLengthOfEmploymentRequired',
          minimumAge: 'profitSharingMinimumAge',
          serviceCalculationType: 'profitSharingServiceCalculationType'
        }
      : {})
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box display='flex' flexDirection='column' gap={2}>
          <AccessControl
            hideFromTPA={hideFieldFromTpa(fields.entryDateFrequency)}>
            <SimpleDropdown
              data-testid={fields.entryDateFrequency}
              disabled={props.data?.[fields.entryDateFrequency]?.disabled}
              fieldId={fields.entryDateFrequency}
              fieldName='Entry Date Frequency'
              fieldOptions={entryDateFrequencyOptions}
            />
          </AccessControl>

          <AccessControl hideFromTPA={hideFieldFromTpa(fields.minimumAge)}>
            <SimpleDropdown
              data-testid={fields.minimumAge}
              disabled={props.data?.[fields.minimumAge]?.disabled}
              fieldId={fields.minimumAge}
              fieldName='Minimum Age Requirement'
              fieldOptions={minimumAgeOptions}
            />
          </AccessControl>

          <AccessControl
            hideFromTPA={hideFieldFromTpa(fields.serviceCalculationType)}>
            <SimpleDropdown
              data-testid={fields.serviceCalculationType}
              disabled={props.data?.[fields.serviceCalculationType]?.disabled}
              fieldId={fields.serviceCalculationType}
              fieldName='Service Calculation Type'
              fieldOptions={serviceCalculationTypeOptions}
            />
          </AccessControl>

          <AccessControl
            hideFromTPA={hideFieldFromTpa(fields.lengthOfEmploymentRequired)}>
            <SimpleDropdown
              data-testid={fields.lengthOfEmploymentRequired}
              disabled={
                props.data?.[fields.lengthOfEmploymentRequired]?.disabled
              }
              fieldId={fields.lengthOfEmploymentRequired}
              fieldName='Length Of Employment Required'
              fieldOptions={lengthOfEmploymentRequiredOptions}
            />
          </AccessControl>

          <AccessControl hideFromTPA={hideFieldFromTpa(fields.hoursRequired)}>
            <Field
              component={TextField}
              data-testid={fields.hoursRequired}
              disabled={props.data?.[fields.hoursRequired]?.disabled}
              fullWidth
              label='Hours Required'
              name={fields.hoursRequired}
            />
          </AccessControl>
        </Box>
      </Box>
    </>
  );
};
