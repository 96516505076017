import DatePicker from '@/components/date-picker/DatePicker';
import { HoldingsGetRequest } from '@/models/ops/holdings/HoldingsGetRequest.model';
import formatters from '@/utils/Formatters';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch
} from '@mui/material';
import { DateValidationError } from '@mui/x-date-pickers';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';

import dayjs, { Dayjs } from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { isTradingDay } from 'sub-accounting-calendar-utility';
import * as yup from 'yup';

const validationSchema = yup.object({
  asOfDate: yup.date().required('As of date is required')
});

type HoldingsFiltersFormProps = {
  initialValues: HoldingsGetRequest;
  onSubmit: (values: HoldingsGetRequest) => void;
};

export const HoldingsFiltersForm: FC<HoldingsFiltersFormProps> = props => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleError = (
    errorReason: DateValidationError,
    value: dayjs.Dayjs | null
  ) => {
    if (!errorReason) {
      setErrorMessage('');
      return;
    }

    if (
      errorReason === 'shouldDisableDate' &&
      !isTradingDay(value.format('YYYY-MM-DD'))
    ) {
      setErrorMessage('As of date must be a trading day');
    }
  };

  const getLastTradingDay = (date?: string) => {
    const startDate = date ? dayjs(date) : dayjs();
    let lastTradingDay = startDate;
    while (!isTradingDay(lastTradingDay.format('YYYY-MM-DD'))) {
      lastTradingDay = lastTradingDay.subtract(1, 'day');
    }
    return lastTradingDay;
  };

  return (
    <Formik<HoldingsGetRequest>
      initialValues={{
        ...props.initialValues,
        asOfDate: getLastTradingDay(props.initialValues.asOfDate).format(
          'YYYY-MM-DD'
        )
      }}
      onSubmit={props.onSubmit}
      validationSchema={validationSchema}>
      {({
        handleSubmit: handleFormSubmit,
        isValid,
        errors,
        setFieldValue,
        touched,
        values
      }) => (
        <Form data-testid='filter-form'>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'>
            <Stack alignItems='center' direction='row' p={2} spacing={2}>
              <FormControl error={touched.asOfDate && Boolean(errors.asOfDate)}>
                <Field
                  as={DatePicker}
                  autoComplete='off'
                  data-testid='asOfDate'
                  disableFuture
                  errorMessage={errorMessage}
                  handleError={handleError}
                  label='As of'
                  name='asOfDate'
                  shouldDisableDate={(date: Dayjs) =>
                    !isTradingDay(date.format('YYYY-MM-DD'))
                  }
                  size='small' // FormControl doesn't pass to our DatePicker
                  variant='outlined'
                />
                {!errorMessage && touched.asOfDate && errors.asOfDate && (
                  <FormHelperText>{errors.asOfDate}</FormHelperText>
                )}
              </FormControl>
              <FormControl size='small'>
                <InputLabel id='date-type-label'>Date Type</InputLabel>
                <Field
                  MenuProps={{
                    'data-testid': 'menu-dateType'
                  }}
                  SelectDisplayProps={{
                    'data-testid': 'date-type-select'
                  }}
                  as={Select}
                  error={touched.dateType && Boolean(errors.dateType)}
                  label='Date Type'
                  labelId='date-type-label'
                  name='dateType'>
                  {Object.values(PositionDateType).map(value => (
                    <MenuItem key={value} value={value}>
                      {formatters.capitalizeFirstChar(value.toLowerCase())}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <Button
                data-testid='apply-button'
                disabled={!isValid}
                onClick={() => handleFormSubmit()}
                type='submit'
                variant='outlined'>
                Apply
              </Button>
            </Stack>
            <FormControlLabel
              control={
                <Switch
                  checked={values.filterToShortPositions}
                  onChange={(_event, newValue) => {
                    setFieldValue('filterToShortPositions', newValue);
                    handleFormSubmit();
                  }}
                />
              }
              label='View short positions only'
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
