import { EligibilityRule } from '@/models/PlanDesign.model';

class DualEligibilityMinimumAge {
  output: number | string | undefined;

  constructor(eligibilityRules: EligibilityRule[]) {
    const minimumAge =
      eligibilityRules?.find(
        (rule: EligibilityRule) => rule.eligibilityRequirementTypeName === 'Age'
      ) ?? {};

    this.output = minimumAge.ruleParameter?.age
      ? minimumAge.ruleParameter?.age === 0
        ? 'None'
        : minimumAge.ruleParameter?.age?.toString()
      : '';
  }

  convertToApiState = (value: string): number | undefined =>
    value ? (value === 'None' ? 0 : parseInt(value, 10)) : undefined;
}

export default DualEligibilityMinimumAge;
