import CollapsibleTable, {
  CellComponentProps
} from '@/components/collapsible-table';
import DocumentDownloadButton from '@/components/document-download-button/DocumentDownloadButton.component';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ProgramDocumentsProps } from '@/routes/ops/investments/ProgramDocumentsTab.component';
import { SlotMetaData } from '@/routes/plans/plan-detail/PlanDocumentsTab/PlanDocumentsTab.component';
import { ProgramService } from '@/services/ops/investments/Program.service';
import formatters from '@/utils/Formatters';
import { Delete, Search } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  TableCell,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { UploadButton } from './UploadButton.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createdDateWidth: {
      width: '15%'
    },
    fileNameWidth: {
      width: '55%'
    },
    fileTypeWidth: {
      width: '30%'
    },
    header: {
      fontSize: theme.spacing(3)
    },
    paper: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
      width: '100%'
    },
    searchInput: {
      marginTop: theme.spacing(3),
      width: theme.spacing(65)
    },
    searchSection: {
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(3)
    },
    size: {
      fontSize: theme.spacing(2)
    }
  })
);

const ProgramDocuments = (props: ProgramDocumentsProps): JSX.Element => {
  const { programId } = props;

  const classes = useStyles();
  const snackbar = useSnackbar();
  const { openDialog } = useDialog();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const documentKeydRef = useRef<string>('');

  const documentsTableColumns: GridColDef[] = [
    {
      cellClassName: classes.fileTypeWidth,
      field: 'slotName',
      headerName: 'File Type'
    },
    {
      cellClassName: classes.fileNameWidth,
      field: 'original_file_name',
      headerName: 'File Name'
    },
    {
      cellClassName: classes.createdDateWidth,
      field: 'created_at',
      headerName: 'Created Date'
    },
    { field: 'upload', headerName: 'Program' },
    { field: 'delete' }
  ];

  const documentsQuery = useQuery<SlotMetaData[]>(
    ['ProgramService.getAllProgramDocuments', programId],
    async () => ProgramService.getAllProgramDocuments(programId),
    {
      enabled: Boolean(programId)
    }
  );

  const documentsData = documentsQuery.data || [];
  const slotsWithUUID = documentsData
    .map((slot: SlotMetaData) => {
      return {
        id: uuidv4(),
        ...slot
      };
    })
    .sort((a, b) => {
      return a.slotName.toLowerCase().localeCompare(b.slotName.toLowerCase());
    });

  if (documentsQuery.isError) {
    const { error } = documentsQuery;
    return redirectToErrorPage(error as Error);
  }

  const uploadDocument = async (document: FormData) => {
    try {
      setIsLoading(true);
      await ProgramService.uploadProgramDocument(programId, document);
      setIsLoading(false);
      documentsQuery.refetch();
      snackbar.showSnackbar({
        message: `Upload was successful`,
        severity: 'success'
      });
    } catch (e) {
      setIsLoading(false);
      snackbar.showSnackbar({
        message: 'Failed to upload file!',
        severity: 'error'
      });
    }
  };

  const handleUploadDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const documentKey = documentKeydRef.current;
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('documentKey', documentKey);
      uploadDocument(formData);
    } else {
      snackbar.showSnackbar({
        message: 'File was not selected. Try again',
        severity: 'error'
      });
    }
    event.target.value = '';
  };

  const handleDeleteDocument = (documentId: number) => {
    setIsLoading(true);
    ProgramService.deleteProgramDocument(programId, documentId)
      .then(() => {
        setIsLoading(false);
        documentsQuery.refetch();
        snackbar.showSnackbar({
          message: `Program Document was deleted`,
          severity: 'success'
        });
      })
      .catch(() => {
        setIsLoading(false);
        snackbar.showSnackbar({
          message: 'Program Document was NOT deleted',
          severity: 'error'
        });
      });
  };

  const documentsTableCell: React.FunctionComponent<CellComponentProps> = (
    cellProps: CellComponentProps
  ) => {
    const { row, column } = cellProps;
    let field;

    if (column.field === 'created_at' && row.uploadHistory.length) {
      field = (
        <Box className={classes.size}>
          {formatters.formatFromIsoDateCustom(
            row.uploadHistory.at(-1)[column.field],
            'MM/DD/YY HH:mm'
          )}
        </Box>
      );
    } else if (
      column.field === 'original_file_name' &&
      row.uploadHistory.length
    ) {
      field = <DocumentDownloadButton doc={row.uploadHistory.at(-1)} />;
    } else if (column.field === 'upload') {
      field = (
        <UploadButton
          data-testid={`upload-file-button-${row.id}`}
          onChange={handleUploadDocument}
          onClick={() => {
            documentKeydRef.current = row.document_key;
          }}
        />
      );
    } else if (column.field === 'delete') {
      field = (
        <IconButton
          aria-label='delete'
          color='error'
          data-testid={`delete-file-button-${row.id}`}
          disabled={!row.uploadHistory.at(-1)}
          onClick={() =>
            row.uploadHistory.at(-1)?.id &&
            openDialog({
              actionButtons: {
                cancelButton: {
                  children: 'CANCEl'
                },
                submitButton: {
                  children: 'DELETE'
                }
              },
              onSubmit: () => {
                handleDeleteDocument(row.uploadHistory.at(-1)?.id);
              },
              steps: [
                {
                  contentText: 'The document will be deleted!',
                  title: 'Delete Program Document'
                }
              ]
            })
          }>
          <Delete />
        </IconButton>
      );
    } else field = row[column.field];

    return (
      <TableCell className={classes.size} component='th' scope='row'>
        <Box>{field}</Box>
      </TableCell>
    );
  };

  return (
    <Paper className={classes.paper} elevation={0} variant='outlined'>
      <div className={classes.searchSection}>
        <Typography className={classes.header} variant='h5'>
          Program Documents
        </Typography>
        <TextField
          InputProps={{
            onChange: event => setSearchTerm(event.target.value),
            placeholder: 'Search program documents',
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            )
          }}
          className={classes.searchInput}
          data-testid='search-documents'
          value={searchTerm}
          variant='outlined'
        />
      </div>
      {(isLoading || documentsQuery.isFetching) && <LinearLoading />}
      <CollapsibleTable
        cellComponent={documentsTableCell}
        columns={documentsTableColumns}
        pager={{
          metaCount: documentsData.length,
          onPageNumberChanged: (zeroIndexedPageNumber: number) => {
            return setPageNumber(zeroIndexedPageNumber + 1);
          },
          onRowsPerPageChanged: (newRowsPerPage: number) => {
            return setRowsPerPage(newRowsPerPage);
          },
          pageNumber: pageNumber - 1,
          rowsPerPage
        }}
        tableData={(slotsWithUUID || []).slice(
          rowsPerPage * pageNumber - rowsPerPage,
          rowsPerPage * pageNumber
        )}
      />
    </Paper>
  );
};

export default ProgramDocuments;
