import Badge from '@/components/badge';
import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import DataTable, {
  DataTableBadgeCell,
  DataTableMenuCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker';
import ViewParentIcon from '@/components/icon/ViewParent';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { orderStatusColorMap } from '@/models/ops/common/OrderStatusColorMap.model';
import { SubAccountOrderDto } from '@/models/ops/sub-account-orders/SubAccountOrderDTO.model';
import { SubAccountOrderSearchRequest } from '@/models/ops/sub-account-orders/SubAccountOrderSearchRequest.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import SubAccountOrderService from '@/services/ops/sub-account-orders/SubAccountOrder.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SecurityOrderType } from '@vestwell-sub-accounting/models/common/SecurityOrderType';
import { TradeType } from '@vestwell-sub-accounting/models/common/TradeType';
import { OrderStatus } from '@vestwell-sub-accounting/models/orderManagement/OrderStatus';

import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';

import DeferSubAccountOrderDialog from './DeferSubAccountOrderDialog.component';
import SubAccountOrderDetailCellRenderer from './SubAccountOrderDetailCellRenderer.component';

type SubAccountOrdersProps = {
  hideFilters?: boolean;
  hideHeader?: boolean;
  hideActions?: boolean;
  customDefaultFilters?: Partial<SubAccountOrderSearchRequest>;
  includeColumns?: string[];
};

const SubAccountOrders = ({
  hideFilters = false,
  hideHeader = false,
  hideActions = false,
  customDefaultFilters,
  includeColumns
}: SubAccountOrdersProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_TRANSACTIONS
  );
  const [selectedSubAccountOrder, setSelectedSubAccountOrder] =
    useState<SubAccountOrderDto>();
  const [openDeferDialog, setOpenDeferDialog] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState<
    keyof SubAccountOrderDto | undefined
  >();
  const [orderByDirection, setOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >();

  const defaultQuery: SubAccountOrderSearchRequest = {
    ...customDefaultFilters // overwrites the above defaults
  };

  const [query, setQuery] =
    useState<SubAccountOrderSearchRequest>(defaultQuery);

  const subAccountOrderQuery = useQuery(
    [
      'SubAccountOrderService.search',
      query,
      page,
      pageSize,
      orderBy,
      orderByDirection
    ],
    () => {
      const queryWithPagination = {
        ...query,
        cusip: query.cusip || undefined,
        endDate: query.endDate || undefined,

        orderBy: orderBy || undefined,

        orderByDirection: orderByDirection || undefined,

        page,

        pageSize,
        // if blank then don't pass it
        startDate: query.startDate || undefined
      };
      return SubAccountOrderService.search(queryWithPagination);
    },
    {
      keepPreviousData: true,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `SubAccountOrder search failed: ${message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  const columnDefs: ColDef[] = [
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: SubAccountOrderDto }) => {
        return (
          <Tooltip title='View Parent Account Order'>
            <IconButton
              href={`/ops/parent-account-orders/${cellData.data.parentAccountOrderId}`}
              size='small'
              target='_blank'>
              <ViewParentIcon sx={{ fontSize: 24 }} />
            </IconButton>
          </Tooltip>
        );
      },
      cellStyle: { paddingRight: 0 },
      field: 'id',
      headerName: '',
      suppressColumnsToolPanel: true,
      width: 85
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: SubAccountOrderDto }) => {
        return (
          <>
            {!hideActions &&
            hasWritePermissions &&
            [OrderStatus.Rejected, OrderStatus.Open].includes(
              cellData.data.orderStatus
            ) ? (
              <DataTableMenuCell>
                {[OrderStatus.Rejected, OrderStatus.Open].includes(
                  cellData.data.orderStatus
                ) && (
                  <MenuItem
                    onClick={() => {
                      setSelectedSubAccountOrder(cellData.data);
                      setOpenDeferDialog(true);
                    }}>
                    Defer
                  </MenuItem>
                )}
              </DataTableMenuCell>
            ) : (
              ' '
            )}
          </>
        );
      },
      cellStyle: { paddingLeft: 0, paddingRight: 0 },
      field: 'id',
      headerName: '',
      suppressColumnsToolPanel: true,
      width: 45
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: SubAccountOrderDto }) => {
        return (
          <DataTableStackCell
            primary={formatters.formatSecurityName(
              cellData.data.security?.symbol,
              cellData.data.security?.cusip
            )}
            secondary={cellData.data.security?.description}
          />
        );
      },
      field: 'security.symbol',
      headerName: 'Security'
    },
    {
      autoHeight: true,
      field: 'tradeType',
      headerName: 'Trade Type',
      sortable: true,
      valueFormatter: ({ value }: { value: string }) => {
        // fetch friendly display name
        const displayTradeType = formatters.getValueKey(TradeType, value);
        return formatters.displayCase(displayTradeType);
      }
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: SubAccountOrderDto }) => {
        const displayOrderStatus = formatters.getValueKey(
          OrderStatus,
          cellData.data.orderStatus
        );
        return (
          <>
            {cellData.data.orderStatus && (
              <DataTableBadgeCell
                color={orderStatusColorMap[cellData.data.orderStatus]}>
                {formatters.displayCase(displayOrderStatus)}
              </DataTableBadgeCell>
            )}
          </>
        );
      },
      field: 'orderStatus',
      headerName: 'Status',
      sortable: true
    },
    {
      autoHeight: true,
      field: 'securityOrderType',
      headerName: 'Order Type',
      sortable: true,
      valueFormatter: ({ value }: { value: string }) => {
        // fetch friendly display name
        const displaySecurityOrderType = formatters.snakeToCamelCase(
          formatters.getValueKey(SecurityOrderType, value)
        ); // snake case to camel case is necessary until the SecurityOrderType enum has its keys updated to camel case to match the convention
        return formatters.displayCase(displaySecurityOrderType);
      }
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: SubAccountOrderDto }) => {
        let displayUnitsAmount = '';
        if (
          [
            SecurityOrderType.DOLLAR_ALL,
            SecurityOrderType.DOLLAR_ORDER
          ].includes(cellData.data.securityOrderType)
        ) {
          displayUnitsAmount = cellData.data.orderAmount
            ? formatters.formatDollars(cellData.data.orderAmount)
            : '';
        } else {
          displayUnitsAmount = cellData.data.orderUnits
            ? formatters.formatDecimal(cellData.data.orderUnits, 3)
            : '';
        }
        return displayUnitsAmount;
      },
      field: 'orderUnits',
      headerName: 'Units/Amount'
    },
    {
      field: 'tradeDate',
      headerName: 'Trade Date',
      sortable: true,
      valueFormatter: ({ value }: { value: string }) =>
        value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : ''
    }
  ];

  if (Array.isArray(includeColumns) && includeColumns.includes('subAccount')) {
    columnDefs.unshift({
      autoHeight: true,
      cellRenderer: (cellData: { data: SubAccountOrderDto }) => {
        const displaySubAccountName = formatters.formatSubAccountName(
          cellData.data.subAccount
        );
        return (
          <DataTableStackCell
            primary={displaySubAccountName}
            primaryLinkProps={{
              target: '_blank',
              to: `/ops/accounts/${cellData.data.parentAccountId}/sub-accounts/${cellData.data.subAccount?.subAccountId}`
            }}
            secondary={cellData.data.subAccount?.subAccountId}
          />
        );
      },
      field: 'subAccount',
      headerName: 'Sub Account',
      sortable: false
    });
  }

  const handleSubmit = (formData: SubAccountOrderSearchRequest) => {
    // copy new form data into query on top of the existing query to preserve the non-form query props
    setQuery(prev => ({
      ...prev,
      ...formData
    }));
  };

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setOrderBy(undefined);
      setOrderByDirection(undefined);
    } else {
      setOrderBy(newSort[0].colId as keyof SubAccountOrderDto);
      setOrderByDirection(newSort[0].sort);
    }
  };

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const detailCellRenderer = useMemo(() => {
    return SubAccountOrderDetailCellRenderer;
  }, []);

  return (
    <>
      {selectedSubAccountOrder && (
        <DeferSubAccountOrderDialog
          onClose={() => {
            setOpenDeferDialog(false);
            setSelectedSubAccountOrder(undefined);
          }}
          open={openDeferDialog}
          subAccountOrder={selectedSubAccountOrder}
        />
      )}
      <Card>
        {!hideHeader && (
          <CardHeader
            data-testid='sub-account-order-header'
            loading={subAccountOrderQuery.isInitialLoading}
            title='Sub Account Orders'
          />
        )}
        <CardContent
          disablePadding
          overlayLoading={subAccountOrderQuery.isInitialLoading}>
          <DataTable
            columnDefs={columnDefs.filter(
              // include columns with no field or if a column include list was provided, include columns that are in the list
              col =>
                !col.field ||
                !includeColumns ||
                includeColumns.includes(col.field)
            )}
            columnSizing='flex'
            data-testid='data-sub-account-orders-table'
            detailCellRenderer={detailCellRenderer}
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-sub-account-orders-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No results found.'
                />
              </Stack>
            }
            filterSidePanelComponent={
              !hideFilters ? (
                <Formik
                  initialValues={{
                    cusip: '',
                    endDate: dayjs().format('YYYY-MM-DD'),
                    orderStatus: [],
                    startDate: dayjs()
                      .subtract(1, 'month')
                      .format('YYYY-MM-DD'),
                    tradeType: []
                  }}
                  onSubmit={(values: SubAccountOrderSearchRequest) =>
                    handleSubmit(values)
                  }>
                  {({ values }) => (
                    <Form data-testid='filter-form'>
                      <Stack
                        alignItems='flex-start'
                        justifyContent='flex-start'
                        spacing={2}>
                        <FormControl sx={{ width: 240 }} variant='outlined'>
                          <Field
                            InputProps={{
                              endAdornment: <SearchIcon />
                            }}
                            as={TextField}
                            data-testid='cusip-input'
                            name='cusip'
                            placeholder='Enter a CUSIP / Ticker'
                            size='small'
                          />
                        </FormControl>

                        <FormControl size='small' sx={{ width: 240 }}>
                          <InputLabel id='tradeType-label' shrink>
                            Trade Type
                          </InputLabel>
                          <Field
                            MenuProps={{
                              'data-testid': 'menu-tradeType'
                            }}
                            as={Select}
                            displayEmpty
                            input={<OutlinedInput label='Trade Type' notched />}
                            label='Trade Type'
                            labelId='tradeType-label'
                            multiple
                            name='tradeType'
                            renderValue={(selected: TradeType[]) => {
                              if (!selected?.length) {
                                return <>Any</>;
                              }
                              return selected
                                .map(value =>
                                  formatters.getValueKey(TradeType, value)
                                )
                                .join(', ');
                            }}>
                            {Object.values(TradeType).map(value => {
                              const displayTradeType = formatters.getValueKey(
                                TradeType,
                                value
                              );
                              return (
                                <MenuItem key={value} value={value}>
                                  <Checkbox
                                    checked={values.tradeType?.includes(value)}
                                    sx={{ py: 0 }}
                                  />
                                  <ListItemText>
                                    {formatters.displayCase(displayTradeType)}
                                  </ListItemText>
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </FormControl>

                        <FormControl size='small' sx={{ width: 240 }}>
                          <InputLabel id='orderStatus-label' shrink>
                            Status
                          </InputLabel>
                          <Field
                            MenuProps={{
                              'data-testid': 'menu-orderStatus'
                            }}
                            as={Select}
                            displayEmpty
                            input={<OutlinedInput label='Status' notched />}
                            label='Status'
                            labelId='orderStatus-label'
                            multiple
                            name='orderStatus'
                            renderValue={(selected: OrderStatus[]) => {
                              if (!selected?.length) {
                                return <>Any</>;
                              }
                              return (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5
                                  }}>
                                  {selected.map(value => {
                                    const displayOrderStatus =
                                      formatters.getValueKey(
                                        OrderStatus,
                                        value
                                      );
                                    return (
                                      <Badge
                                        color={orderStatusColorMap[value]}
                                        key={value}
                                        size='small'>
                                        {formatters.displayCase(
                                          displayOrderStatus
                                        )}
                                      </Badge>
                                    );
                                  })}
                                </Box>
                              );
                            }}>
                            {Object.values(OrderStatus)
                              .sort() // close enough to sorting by key so lets call it good
                              .map(value => {
                                const displayOrderStatus =
                                  formatters.getValueKey(OrderStatus, value);
                                return (
                                  <MenuItem key={value} value={value}>
                                    <Checkbox
                                      checked={values.orderStatus?.includes(
                                        value
                                      )}
                                      sx={{ py: 0 }}
                                    />
                                    <ListItemText>
                                      {formatters.displayCase(
                                        displayOrderStatus
                                      )}
                                    </ListItemText>
                                  </MenuItem>
                                );
                              })}
                          </Field>
                        </FormControl>

                        <Divider flexItem textAlign='left'>
                          Trade Date
                        </Divider>

                        <FormControl>
                          <Field
                            as={DatePicker}
                            autoComplete='off'
                            data-testid='startDate'
                            disableFuture
                            label='From'
                            name='startDate'
                            size='small' // FormControl doesn't pass to our DatePicker
                            sx={{ width: 240 }}
                            variant='outlined'
                          />
                        </FormControl>

                        <FormControl>
                          <Field
                            as={DatePicker}
                            autoComplete='off'
                            data-testid='endDate'
                            disableFuture
                            label='To'
                            name='endDate'
                            size='small' // FormControl doesn't pass to our DatePicker
                            sx={{ width: 240 }}
                            variant='outlined'
                          />
                        </FormControl>

                        <Divider flexItem textAlign='left'>
                          Other
                        </Divider>

                        <FormControl sx={{ width: 240 }} variant='outlined'>
                          <Field
                            as={TextField}
                            data-testid='id-input'
                            name='id'
                            placeholder='Order ID'
                            size='small'
                          />
                        </FormControl>

                        <FormControl sx={{ width: 240 }} variant='outlined'>
                          <Field
                            as={TextField}
                            data-testid='omsBatchId-input'
                            name='omsBatchId'
                            placeholder='OMS Batch ID'
                            size='small'
                          />
                        </FormControl>

                        <Button
                          data-testid='submit'
                          type='submit'
                          variant='outlined'>
                          Apply
                        </Button>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              ) : undefined
            }
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            onSortChanged={handleSortChanged}
            page={page}
            pageSize={pageSize}
            pagination
            paginationSource='server'
            paginationTotal={subAccountOrderQuery.data?.pagination?.total}
            rowData={subAccountOrderQuery.data?.results || []}
            sort={
              orderBy
                ? [
                    {
                      colId: orderBy,
                      sort: orderByDirection
                    }
                  ]
                : []
            }
          />
        </CardContent>
      </Card>
    </>
  );
};
export default SubAccountOrders;
