class HasDualEligibility {
  convertToApiState = value => {
    if (!value) return false;

    if (typeof value === 'string') {
      return value === 'true' ? true : false;
    }

    if (typeof value === 'boolean') {
      return value;
    }

    return false;
  };

  output: boolean;

  constructor(props: Record<string, any>) {
    this.output = props.hasDualEligibility;
  }
}

export default HasDualEligibility;
