import { CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { DisplayBalance } from '@/hooks/useAccountValue.hook';
import VestwellTheme from '@/theme/Vestwell.theme';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/material';

import { ColDef } from 'ag-grid-community';
import Decimal from 'decimal.js';
import { FC, useCallback, useMemo } from 'react';

type HoldingsDataTableProps = {
  data?: DisplayBalance[];
};

export const HoldingsDataTable: FC<HoldingsDataTableProps> = props => {
  const columnDefs = useMemo(
    () =>
      [
        {
          field: 'units',
          maxWidth: 175,
          sortable: true,
          type: 'numericColumn',
          valueFormatter: ({ data }) =>
            data !== null && data !== undefined
              ? formatters.formatDecimal(data.confirmedUnits, 3)
              : ''
        },
        {
          field: 'price',
          maxWidth: 175,
          sortable: true,
          type: 'numericColumn',
          valueFormatter: ({ data }) =>
            data !== null && data !== undefined
              ? formatters.formatDollars(data.price)
              : ''
        },
        {
          field: 'value',
          maxWidth: 180,
          minWidth: 180,
          sortable: true,
          type: 'numericColumn',
          valueFormatter: ({ data }) =>
            data !== null && data !== undefined
              ? formatters.formatDollars(data.confirmedValue)
              : ''
        }
      ] as ColDef<DisplayBalance>[],
    [props.data]
  );

  const autoGroupColumnDef = useMemo(() => {
    return {
      autoHeight: true,
      cellRendererParams: {
        innerRenderer: (cellData: { data: DisplayBalance }) => {
          return (
            <DataTableStackCell
              primary={
                formatters.formatSecurityName(
                  cellData.data.security?.symbol,
                  cellData.data.security?.cusip
                ) || '\u2014'
              }
              secondary={cellData.data.security?.description}
            />
          );
        },
        suppressCount: true
      },
      field: 'security.symbol',
      headerName: 'Security',
      minWidth: 300,
      resizable: true,
      sortable: true
    };
  }, [props.data]);

  const getDataPath = useCallback((getDataPathData: DisplayBalance) => {
    return getDataPathData.orgHierarchy;
  }, []);

  return (
    <DataTable
      autoGroupColumnDef={autoGroupColumnDef}
      columnDefs={columnDefs}
      data-testid='data-holdings-table'
      emptyPlaceholderComponent={
        <Stack
          alignItems='center'
          data-testid='no-data-holdings-table'
          justifyContent='center'
          sx={{ height: '100%' }}>
          <CardPlaceholder
            icon={<SearchIcon fontSize='inherit' />}
            subtitle='No results found.'
          />
        </Stack>
      }
      getDataPath={getDataPath}
      getRowStyle={params =>
        params.data.confirmedUnits &&
        new Decimal(params.data.confirmedUnits).lessThan(new Decimal(0)) && {
          backgroundColor: VestwellTheme.palette.warning.lightBg
        }
      }
      rowData={props.data}
    />
  );
};
