import Constants from '@/consts/planDesign.constants';
import { EligibilityRule } from '@/models/PlanDesign.model';
import ServiceCalculationType from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/eligibility-features/ServiceCalculationType';

import { isEmpty } from 'lodash';

class DefaultServiceCalculationType extends ServiceCalculationType {
  eligibilityRules: EligibilityRule[] | undefined;

  convertToApiState = (
    serviceCalculationType: string | undefined,
    minimumAge: number | undefined,
    lengthOfEmploymentRequired: number | undefined,
    hoursRequired: number | undefined,
    entryDateFrequency: string | undefined,
    entryDateFrequencyId: number | undefined
  ): EligibilityRule[] | undefined => {
    const serviceRequirement: Partial<EligibilityRule> = {
      ...Constants.eligibilityRequirementTypes.find(
        rule => rule.eligibilityRequirementTypeName === serviceCalculationType
      )
    };

    const result = [];

    if (minimumAge || minimumAge === 0) {
      result.push({
        eligibilityRequirementTypeId: 1,
        eligibilityRequirementTypeName: 'Age',
        entryDateFrequencyId: entryDateFrequencyId,
        entryDateFrequencyType: entryDateFrequency,
        ruleParameter: {
          age: minimumAge
        }
      });
    }

    if (!isEmpty(serviceRequirement)) {
      if (serviceRequirement.eligibilityRequirementTypeId === -1) {
        serviceRequirement.eligibilityRequirementTypeName =
          'Specified Months - Elapsed Time';
        serviceRequirement.eligibilityRequirementTypeId = 2;
      }

      serviceRequirement.ruleParameter = {
        hours: hoursRequired,
        months: lengthOfEmploymentRequired
      };

      const failsafe =
        serviceRequirement.eligibilityRequirementTypeId === 6
          ? 'rolling'
          : serviceRequirement.eligibilityRequirementTypeId === 5
            ? 'revert'
            : null;

      if (failsafe) {
        serviceRequirement.ruleParameter.failsafe = failsafe;
      }

      result.push({
        ...serviceRequirement,
        entryDateFrequencyId: entryDateFrequencyId,
        entryDateFrequencyType: entryDateFrequency
      });
    }

    return result;
  };

  output: string | undefined;

  constructor(eligibilityRules: EligibilityRule[] | undefined) {
    super(
      Constants.eligibilityRequirementTypes.map(
        type => type.eligibilityRequirementTypeName
      )
    );
    this.output = this.transform(eligibilityRules);
    this.eligibilityRules = eligibilityRules;
  }

  private transform = (
    rules: EligibilityRule[] | undefined
  ): string | undefined => {
    if (!rules) {
      return '';
    }

    const eligibilityRule = rules.find(rule => {
      return Constants.eligibilityRequirementTypes
        .map(type => type.eligibilityRequirementTypeName)
        .includes(rule.eligibilityRequirementTypeName || '');
    });

    return eligibilityRule?.ruleParameter?.months === 0 &&
      eligibilityRule.eligibilityRequirementTypeId === 2
      ? 'No Requirement'
      : eligibilityRule?.eligibilityRequirementTypeName;
  };
}

export default DefaultServiceCalculationType;
