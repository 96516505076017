import { createSvgIcon, SvgIconProps } from '@mui/material';

import React, { FC } from 'react';

export const ReEnrollmentNone: FC<SvgIconProps> = createSvgIcon(
  <svg
    fill='none'
    height='40'
    viewBox='0 0 40 40'
    width='40'
    xmlns='http://www.w3.org/2000/svg'>
    <rect fill='white' height='40' width='40' />
    <circle
      cx='10.3332'
      cy='9.99998'
      r='6.66667'
      stroke='#757575'
      strokeWidth='3.33333'
    />
    <circle
      cx='10.3332'
      cy='30'
      r='6.66667'
      stroke='#757575'
      strokeWidth='3.33333'
    />
  </svg>,
  'ReEnrollmentNoneIcon'
);

ReEnrollmentNone.displayName = 'ReEnrollmentNoneIcon';
