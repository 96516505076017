import {
  DataTableBadgeCell,
  DataTableMenuCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { OffsettingOperationalAccountTypes } from '@/models/ops/transactions/ReverseTransactionRequest.model';
import { TransactionDto } from '@/models/ops/transactions/TransactionDTO.model';
import { TransactionStatus } from '@/models/ops/transactions/TransactionStatus.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { MenuItem } from '@mui/material';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { ConfirmedTransactionStatus } from '@vestwell-sub-accounting/models/common/ConfirmedTransactionStatus';
import { PendingTransactionStatus } from '@vestwell-sub-accounting/models/common/PendingTransactionStatus';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';

import { useToggle } from 'react-use';

type CommonParams = {
  accountLevel: AccountLevel;
  hideActions: boolean;
  hideColumns?: string[];
  hideDetailCell: boolean;
  includeColumns?: string[];
  setSelectedTransactions: (transactions: TransactionDto[]) => void;
};

type ConfirmedTransactionParams = CommonParams & {
  setOffsettingOperationalAccountType: (
    type: OffsettingOperationalAccountTypes
  ) => void;
  toggleOpenEditDialog?: ReturnType<typeof useToggle>[1];
  toggleOpenReverseDialog?: ReturnType<typeof useToggle>[1];
  transactionStatus: TransactionStatus.Confirmed;
};

type PendingTransactionParams = CommonParams & {
  toggleOpenCancelDialog: ReturnType<typeof useToggle>[1];
  toggleOpenConfirmationDialog: ReturnType<typeof useToggle>[1];
  transactionStatus: TransactionStatus.Pending;
};

export const getColDefs = (
  params: ConfirmedTransactionParams | PendingTransactionParams
) =>
  [
    {
      cellRenderer: (cellData: { data: TransactionDto }) => (
        <>
          {!params.hideActions &&
            userService.hasPermission(
              FeatureLevelPermissions.WRITE_SUBA_TRANSACTIONS
            ) &&
            cellData.data.status !== PendingTransactionStatus.Cancelled &&
            cellData.data.status !== ConfirmedTransactionStatus.Reversed && (
              <DataTableMenuCell>
                {params.transactionStatus === TransactionStatus.Pending && (
                  <MenuItem
                    onClick={() => {
                      params.setSelectedTransactions([cellData.data]);
                      params.toggleOpenCancelDialog(true);
                    }}>
                    Cancel
                  </MenuItem>
                )}
                {params.transactionStatus === TransactionStatus.Pending && (
                  <MenuItem
                    onClick={() => {
                      params.toggleOpenConfirmationDialog(true);
                      params.setSelectedTransactions([cellData.data]);
                    }}>
                    Update to confirmed
                  </MenuItem>
                )}
                {params.transactionStatus === TransactionStatus.Confirmed && (
                  <MenuItem
                    onClick={() => {
                      params.setSelectedTransactions([cellData.data]);
                      params.toggleOpenEditDialog(true);
                    }}>
                    Edit
                  </MenuItem>
                )}
                {params.transactionStatus === TransactionStatus.Confirmed && (
                  <MenuItem
                    onClick={() => {
                      params.setSelectedTransactions([cellData.data]);
                      params.toggleOpenReverseDialog(true);
                    }}>
                    Reverse (No Offset)
                  </MenuItem>
                )}
                {params.transactionStatus === TransactionStatus.Confirmed &&
                  params.accountLevel === AccountLevel.SubAccount && (
                    <MenuItem
                      onClick={() => {
                        params.setOffsettingOperationalAccountType(
                          SubAccountType.error
                        );
                        params.setSelectedTransactions([cellData.data]);
                        params.toggleOpenReverseDialog(true);
                      }}>
                      Reverse &amp; Offset
                    </MenuItem>
                  )}
              </DataTableMenuCell>
            )}
        </>
      ),
      cellStyle: {
        paddingLeft: 16,
        paddingRight: 16
      },
      headerName: 'Actions',
      hide: params.hideActions && params.hideDetailCell,
      maxWidth: 110,
      minWidth: 110,
      suppressColumnsToolPanel: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: TransactionDto }) => (
        <DataTableBadgeCell
          color={
            {
              CANCELLED: 'neutral',
              CONFIRMED: 'success',
              PENDING: 'neutral',
              REVERSED: 'info'
            }[cellData.data.status] as 'info' | 'neutral' | 'success'
          }>
          {formatters.capitalizeFirstChar(cellData.data.status.toLowerCase())}
        </DataTableBadgeCell>
      ),
      field: 'status',
      headerName: 'Status',
      sortable: false // unsupported by sub-accounting API
    },
    params.accountLevel === AccountLevel.SubAccount && {
      field: 'accountId',
      headerName: 'Sub Account ID',
      resizable: true,
      tooltipField: 'accountId'
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: TransactionDto }) => {
        const displayBaseType = formatters.getValueKey(
          TransactionBaseType,
          cellData.data.transactionBaseType
        );
        return (
          <DataTableStackCell
            primary={
              displayBaseType ? formatters.displayCase(displayBaseType) : ''
            }
            secondary={
              cellData.data.transactionTypeCode
                ? formatters.displayCase(cellData.data.transactionTypeCode)
                : ''
            }
          />
        );
      },
      field: 'transactionBaseType',
      headerName: 'Base Type / Type',
      resizable: true,
      sortable: true
    },
    {
      cellRenderer: (cellData: { data: TransactionDto }) => {
        return (
          <DataTableStackCell
            primary={
              formatters.formatSecurityName(
                cellData.data.security?.symbol,
                cellData.data.security?.cusip
              ) || '\u2014'
            }
            secondary={cellData.data.security?.description}
          />
        );
      },
      field: 'security.symbol',
      headerName: 'Security',
      resizable: true,
      sortable: false // unsupported by sub-accounting API
    },
    {
      field: 'units',
      headerName: 'Units',
      resizable: true,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: number }) =>
        value ? formatters.formatDecimal(value, 3) : ''
    },
    {
      field: 'securityUnitPrice',
      headerName: 'Price',
      resizable: true,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value ? formatters.formatDollars(value) : ''
    },
    {
      field: 'amount',
      headerName: 'Amount',
      resizable: true,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value ? formatters.formatDollars(value) : ''
    },
    {
      field: 'tradeDate',
      headerName: 'Trade Date',
      resizable: true,
      sortable: true,
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
    },
    {
      field: 'sourceTransactionId',
      headerName: 'Source ID',
      resizable: true,
      sortable: true,
      tooltipField: 'sourceTransactionId'
    },
    params.accountLevel === AccountLevel.SubAccount && {
      field: 'transferSubAccountId',
      headerName: 'Transfer Sub Account ID',
      initialHide: true, // only sometimes useful; available but hidden by default
      minWidth: 200,
      sortable: true
    }
  ].filter(col => {
    if (params.includeColumns)
      return params.includeColumns.includes(col?.field);
    if (!col) return false;
    if (params.hideColumns?.includes(col.field)) return false;
    return true;
  });
