import CircularLoading from '@/components/circular-loading';
import { ForfeiturePreferences } from '@/routes/plans/plan-detail/PlanForfeituresTab/ForfeiturePreferences.component';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  Paper,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';

interface ForfeitureInfoProps {
  sponsorPlanId: number;
}

const forfeitureFields = [
  {
    key: 'offsetEmployerContribution',
    label: 'Offset employer contributions'
  },
  {
    key: 'payPlanFees',
    label: 'Pay plan fee'
  }
];

const ValueChangeListener = () => {
  const {
    values,
    submitForm
  }: { values: Record<string, any>; submitForm: () => Promise<any> } =
    useFormikContext() as {
      values: Record<string, any>;
      submitForm: () => Promise<any>;
    };

  useEffect(() => {
    if (values.date) {
      submitForm();
    }
  }, [values.date, submitForm]);

  return null;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2)
}));

const StyledForm = styled(Form)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(1)
}));

export const ForfeitureInfo: FC<ForfeitureInfoProps> = props => {
  const [date, setDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));

  const balance = useQuery(
    ['PlanService.getSubaPlanDetails', props.sponsorPlanId, date],
    () => {
      return PlanService.getSubaPlanDetails(props.sponsorPlanId, date);
    },
    {
      enabled: Boolean(props.sponsorPlanId),
      staleTime: Infinity
    }
  );

  const planQuery = useQuery(
    ['PlanService.getPlanById', props.sponsorPlanId?.toString()],
    () => {
      return PlanService.getPlanById(props.sponsorPlanId);
    },
    {
      enabled: Boolean(props.sponsorPlanId),
      staleTime: Infinity
    }
  );

  const planDesignData = useQuery(
    ['PlanService.getPlanDesignById', props.sponsorPlanId],
    () => {
      return PlanService.getPlanDesignById(props.sponsorPlanId);
    },
    {
      enabled: Boolean(props.sponsorPlanId),
      staleTime: Infinity
    }
  );

  const validateDate = (value: Date) => {
    if (!value) return 'Required';
    else if (value.toString() === 'Invalid Date') return 'Wrong date value!';
    else if (
      value &&
      planQuery.isSuccess &&
      dayjs(value).isBefore(planQuery.data?.data.attributes.effectiveDate)
    )
      return `Date cannot be before ${planQuery.data?.data.attributes.effectiveDate}`;
    else if (dayjs(value).isAfter(Date.now())) {
      return 'Date cannot be in the future.';
    }

    return null;
  };

  return (
    <StyledPaper data-testid='forfeitureInfo' elevation={0} variant='outlined'>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={2}>
        <Stack direction='row'>
          <Stack data-testid='forfeitureBalance'>
            <Typography
              color={theme => theme.palette.grey[700]}
              variant='caption'>
              Total Balance
            </Typography>
            {balance.isLoading ? (
              <CircularLoading />
            ) : (
              <Typography variant='h4'>
                {formatters.formatDollars(
                  balance?.data?.forfeituresBalance ?? 0
                )}
              </Typography>
            )}
          </Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Formik
              initialValues={{
                date: dayjs(Date.now()).format('YYYY-MM-DD')
              }}
              onSubmit={e => {
                setDate(dayjs(e.date).format('YYYY-MM-DD'));
              }}>
              {formikProps => {
                return (
                  <StyledForm data-testid='endDateForm'>
                    <Stack alignItems='flex-start' justifyContent='flex-start'>
                      <FormControl
                        fullWidth
                        size='small'
                        sx={{
                          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
                            WebkitTransform: 'translate(14px, 8px) scale(1)',
                            transform: 'translate(14px, 8px) scale(1)'
                          }
                        }}>
                        <Field
                          as={DatePicker}
                          displayEmpty
                          input={<OutlinedInput label='As of Date' notched />}
                          label='As of Date'
                          name='date'
                          onChange={(newValue: Date | null) => {
                            formikProps.setFieldValue('date', newValue);
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              data-testid='endDateInput'
                              error={Boolean(formikProps.errors.date)}
                              sx={{
                                '& .MuiInputBase-input': {
                                  py: 1
                                },
                                py: 0
                              }}
                            />
                          )}
                          validate={validateDate}
                          value={dayjs(formikProps.values.date)}
                        />
                        <FormHelperText error>
                          {formikProps.errors.date}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <ValueChangeListener />
                  </StyledForm>
                );
              }}
            </Formik>
          </LocalizationProvider>
        </Stack>
        <Stack data-testid='forfeitureSettings'>
          <Typography
            color={theme => theme.palette.grey[700]}
            variant='caption'>
            Forfeiture Settings
          </Typography>
          {forfeitureFields.map(field => (
            <ForfeiturePreferences
              key={field.key}
              label={field.label}
              name={field.key}
              planDesignData={planDesignData.data?.data?.forfeiturePreferences}
              sponsorPlanId={props.sponsorPlanId}
            />
          ))}
        </Stack>
      </Stack>
    </StyledPaper>
  );
};
