import { CardContent, CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { SubaPlanDto } from '@/models/ops/suba-plans/SubaPlanDto.model';
import { SubaPlanIncludeOption } from '@/models/ops/suba-plans/SubaPlanIncludeOption.model';
import { SubaPlanSearchRequest } from '@/models/ops/suba-plans/SubaPlanSearchRequest.model';
import { SubaPlanService } from '@/services/ops/suba-plans/SubaPlan.service';
import formatters from '@/utils/Formatters';
import { useUrlStateParams } from '@/utils/Url';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Card,
  Divider,
  FormControl,
  OutlinedInput,
  Stack
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { ColDef } from 'ag-grid-community';
import { AxiosError } from 'axios';
import { Field, Form, Formik } from 'formik';
import { FC, useMemo } from 'react';

import { useAccountsDetailRouteParams } from '../useAccountsDetailRouteParams.hook';

type PlansTabProps = {
  urlStateParamName?: string;
};

export const PlansTab: FC<PlansTabProps> = props => {
  const routeParams = useAccountsDetailRouteParams();

  const defaultSearchRequest: SubaPlanSearchRequest = {
    parentAccountId: routeParams.accountId,
    query: ''
  };

  const { showSnackbar } = useSnackbar();

  const [searchRequest, setSearchRequest] =
    useUrlStateParams<SubaPlanSearchRequest>(
      defaultSearchRequest,
      props.urlStateParamName || 'plans',
      value => JSON.stringify(value),
      value => {
        try {
          return JSON.parse(value);
        } catch {
          return defaultSearchRequest;
        }
      }
    );

  const searchPlansQuery = useQuery(
    ['SubaPlanService.search', searchRequest],
    () =>
      SubaPlanService.search({
        ...searchRequest,
        include: [SubaPlanIncludeOption.marketValueAndCashBalance]
      }),

    {
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Plan search failed: ${err.response?.data ? err.response.data : err.message}`,
          severity: 'error'
        });
      }
    }
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: SubaPlanDto }) => (
          <DataTableStackCell
            primary={cellData.data.plan?.name || cellData.data.planId}
            primaryLinkProps={{
              target: '_blank',
              to: `/ops/accounts/${routeParams.accountId}/plans/${cellData.data.planId}`
            }}
            secondary={
              cellData.data.plan?.name
                ? `ID: ${cellData.data.planId}`
                : undefined
            }
          />
        ),
        field: 'planId',
        flex: 1,
        headerName: 'Plan',
        sortable: true
      },
      {
        field: 'planSetupType',
        sortable: true
      },
      {
        field: 'totalMarketValue',
        headerName: 'Market Value',
        sortable: true,
        type: 'numericColumn',
        valueFormatter: ({ value }) => formatters.formatDollars(value)
      },
      {
        field: 'cashBalance',
        headerName: 'Cash Balance',
        sortable: true,
        type: 'numericColumn',
        valueFormatter: ({ value }) => formatters.formatDollars(value)
      }
    ],
    []
  );

  const handlePageChange = (newPage: number) => {
    setSearchRequest({
      ...searchRequest,
      page: newPage
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setSearchRequest({
      ...searchRequest,
      pageSize: newPageSize
    });
  };

  const handleSortChange = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setSearchRequest({
        ...searchRequest,
        orderBy: undefined,
        orderByDirection: undefined
      });
    } else {
      setSearchRequest({
        ...searchRequest,
        orderBy: newSort[0].colId as keyof SubaPlanDto,
        orderByDirection: newSort[0].sort
      });
    }
  };

  const handleSubmit = (values: SubaPlanSearchRequest) => {
    setSearchRequest({
      ...searchRequest,
      ...values,
      page: 1
    });
  };

  return (
    <Card>
      <CardContent>
        <Formik<SubaPlanSearchRequest>
          initialValues={{
            query: ''
          }}
          onSubmit={handleSubmit}>
          <Form>
            <Stack alignItems='center' direction='row' spacing={2} useFlexGap>
              <FormControl fullWidth variant='outlined'>
                <Field
                  as={OutlinedInput}
                  autoComplete='off'
                  data-testid='query-input'
                  endAdornment={<SearchIcon color='secondary' />}
                  inputProps={{
                    'aria-labelledby': 'plans-tab'
                  }}
                  name='query'
                  placeholder='Plan name or Plan ID'
                  size='small'
                />
              </FormControl>
              <Button
                data-testid='apply-button'
                type='submit'
                variant='outlined'>
                Apply
              </Button>
            </Stack>
          </Form>
        </Formik>
      </CardContent>
      <Divider />
      <CardContent disablePadding loading={searchPlansQuery.isLoading}>
        <DataTable
          columnDefs={columnDefs}
          columnSizing='flex'
          data-testid='plans-data-table'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                data-testid='no-plans-card-placeholder'
                title='No plans found'
              />
            </Stack>
          }
          onPageChanged={handlePageChange}
          onPageSizeChanged={handlePageSizeChange}
          onSortChanged={handleSortChange}
          page={searchRequest.page}
          pageSize={searchRequest.pageSize}
          pagination
          paginationSource='server'
          paginationTotal={searchPlansQuery.data?.pagination?.total}
          rowData={searchPlansQuery.data?.results || []}
          sort={
            searchRequest.orderBy
              ? [
                  {
                    colId: searchRequest.orderBy,
                    sort: searchRequest.orderByDirection
                  }
                ]
              : []
          }
        />
      </CardContent>
    </Card>
  );
};
