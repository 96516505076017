import Constants from '@/consts/planDesign.constants';

class DiscretionaryMatchFundingIntent {
  considerPredefinedValue: boolean;

  disabled: boolean;

  options = Constants.discretionaryMatchFundingIntentOptions;

  mapValueToOption: Record<string, string> = {
    annually: 'Annually',
    'per pay-period': 'Per pay-period'
  };

  mapOptionToValue: Record<string, string> = {
    Annually: 'annually',
    'Per pay-period': 'per pay-period'
  };

  convertToApiState = (value: string | undefined): string | null => {
    if (!value) {
      return null;
    }

    return this.mapOptionToValue[value];
  };

  output: string | undefined;

  setConsiderPredefinedValue = (value: boolean): void => {
    this.considerPredefinedValue = value;
  };

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.disabled = !props.allowNonSafeHarborMatch;

    this.output = props.discretionaryMatchFundingIntent
      ? this.mapValueToOption[props.discretionaryMatchFundingIntent]
      : props.allowNonSafeHarborMatch &&
          props.documentMatchFrequency === 'per pay-period'
        ? 'Per pay-period'
        : undefined;

    this.considerPredefinedValue =
      !props.allowNonSafeHarborMatch ||
      props.documentMatchFrequency !== 'per pay-period';
  }
}

export default DiscretionaryMatchFundingIntent;
