import { GroupIcon } from '@/components/icon/GroupIcon';
import Link from '@/components/link/Link.component';
import ParticipantService from '@/services/Participant.service';
import {
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { FC } from 'react';

type ParticipantLinkedAccountsProps = {
  participantId: number;
};

const tableColumns = [
  { id: 'participantId', label: 'Participant ID' },
  { id: 'planName', label: 'Plan' },
  { id: 'planType', label: 'Type' }
];

const ParticipantLinkedAccounts: FC<ParticipantLinkedAccountsProps> = props => {
  const participantLinkedAccountsQuery = useQuery(
    ['ParticipantService.getLinkedAccounts', props.participantId],
    () => ParticipantService.getLinkedAccounts(props.participantId),
    {
      enabled: Boolean(props.participantId)
    }
  );

  return (
    <Paper
      data-testid='participant-linked-accounts'
      elevation={0}
      variant='outlined'>
      <CardHeader title='Linked Accounts' />
      {participantLinkedAccountsQuery.data?.length > 0 && (
        <TableContainer
          aria-label='users table'
          aria-labelledby='tableTitle'
          data-testid='participant-user-table'>
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(c => {
                  return <TableCell key={c.id}>{c.label}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {participantLinkedAccountsQuery.data.map(u => {
                return (
                  <TableRow key={u.participantId}>
                    <TableCell>
                      <Link
                        target='_blank'
                        to={`/participants/${u.participantId}`}>
                        <Typography variant='body1'>
                          {u.participantId}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>{u.planName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='body1'>{u.planType}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!participantLinkedAccountsQuery.data?.length && (
        <Grid
          alignItems='center'
          container
          data-testid='no-user-data'
          direction='column'
          justifyContent='space-around'
          pb={5}
          pt={3}>
          <GroupIcon />
          <Typography className='no-user' variant='body1'>
            No Data Available
          </Typography>
        </Grid>
      )}
    </Paper>
  );
};

export default ParticipantLinkedAccounts;
