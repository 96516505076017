import { EligibilityRule } from '@/models/PlanDesign.model';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class DualEligibilityEntryDateFrequency {
  output: string | undefined;

  constructor(eligibilityRules: EligibilityRule[]) {
    this.output = eligibilityRules?.[0]?.entryDateFrequencyType ?? '';
  }

  convertToApiState = Helper.convertValueToApiState;
}

export default DualEligibilityEntryDateFrequency;
