import { Badge } from '@/components/badge/Badge.component';
import Card, { CardContent } from '@/components/card';
import { Link } from '@/components/link/Link.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { AlertDto } from '@/models/ops/alerts/AlertDTO.model';
import { executionStatusColorMap } from '@/models/ops/workflows/ExecutionStatusColorMap.model';
import { workflowStatusColorMap } from '@/models/ops/workflows/WorkflowStatusColorMap.model';
import { WorkflowStatusEnumsObject } from '@/models/ops/workflows/WorkflowStatusEnumObject.model';
import WorkflowService from '@/services/ops/workflows/Workflow.service';
import formatters from '@/utils/Formatters';
import SubdirectoryArrowRightOutlinedIcon from '@mui/icons-material/SubdirectoryArrowRightOutlined';
import { Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';
import { AlertType } from '@vestwell-sub-accounting/models/common/AlertType';
import { WorkflowExecutionStatusEnum } from '@vestwell-sub-accounting/models/conductor/WorkflowExecutionStatus.model';

import { AxiosError } from 'axios';
import { useMemo } from 'react';

type CashTransferRejectedAlertDetailFieldsProps = {
  alert: AlertDto;
};

export const CashTransferRejectedAlertDetailFields = ({
  alert
}: CashTransferRejectedAlertDetailFieldsProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const request = alert.details?.request ?? {};
  const status = alert.details?.status ?? {};

  const fromSubAccount = request.fromSubAccount ?? {};
  const toSubAccount = request.toSubAccount ?? {};

  const workflowSearchQuery = useQuery(
    ['WorkflowService.search', request.sourceTracerId],
    () => {
      return WorkflowService.search({
        tracerId: request.sourceTracerId
      });
    },
    {
      keepPreviousData: true,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Workflow search failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const fromSubAccountsLinks = useMemo(() => {
    const links = [];
    if (request.fromSubAccountId) {
      links.push({
        label: request.fromSubAccountId,
        target: '_blank',
        to: `/ops/accounts/${fromSubAccount.parentAccountId}/sub-accounts/${request.fromSubAccountId}/holdings`
      });
    }
    return links;
  }, [request]);

  const toSubAccountsLinks = useMemo(() => {
    const links = [];
    if (request.toSubAccountId) {
      links.push({
        label: request.toSubAccountId,
        target: '_blank',
        to: `/ops/accounts/${toSubAccount.parentAccountId}/sub-accounts/${request.toSubAccountId}/holdings`
      });
    }
    return links;
  }, [request]);

  const fromParentAccountsLinks = useMemo(() => {
    const links = [];
    if (fromSubAccount.parentAccountId) {
      links.push({
        label: fromSubAccount.parentAccountId,
        target: '_blank',
        to: `/ops/accounts/${fromSubAccount.parentAccountId}/sub-accounts`
      });
    }
    return links;
  }, [request]);

  const toParentAccountsLinks = useMemo(() => {
    const links = [];
    if (toSubAccount.parentAccountId) {
      links.push({
        label: toSubAccount.parentAccountId,
        target: '_blank',
        to: `/ops/accounts/${toSubAccount.parentAccountId}/sub-accounts`
      });
    }
    return links;
  }, [request]);

  const fromAccountDetails =
    fromSubAccount.planId && fromSubAccount.parentAccount
      ? `${fromSubAccount.parentAccount.accountName ?? ''} - ID ${
          fromSubAccount.planId
        }`
      : '';

  return (
    <>
      <TextStack
        direction='column'
        id='alert-detail-fields'
        spacing={1}
        sx={{ mb: 1.5, mt: 3.5, p: 0 }}>
        <TextStackItem>
          <TextLabel>Type / Sub Type</TextLabel>
          <TextValue>
            {formatters.displayCase(
              formatters.getValueKey(AlertType, alert?.alertType)
            )}{' '}
            /{' '}
            {formatters.displayCase(
              formatters.getValueKey(AlertSubType, alert.alertSubType)
            )}
          </TextValue>
        </TextStackItem>
      </TextStack>
      {alert.details?.request && (
        <Card>
          <CardContent>
            <Typography variant='subtitle1'>Transfer Details</Typography>
            <TextStack direction='column'>
              <TextStackItem>
                <TextLabel>Amount</TextLabel>
                <TextValue>
                  {request.amount
                    ? formatters.formatDollars(request.amount)
                    : ''}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Transfer Date</TextLabel>
                <TextValue>
                  {request.transferDate
                    ? formatters.formatFromIsoDateCustom(
                        request.transferDate,
                        'MM/DD/YYYY'
                      )
                    : null}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Rejection Reason</TextLabel>
                <TextValue>{status.statusReason}</TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Request ID</TextLabel>
                <TextValue>{status.cashTransferRequestId}</TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Request Tracer ID</TextLabel>
                <TextValue>{request.tracerId}</TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Comment</TextLabel>
                <TextValue>{request.comment ?? 'n/a'}</TextValue>
              </TextStackItem>
            </TextStack>
          </CardContent>
        </Card>
      )}
      {request.fromSubAccount && (
        <Card>
          <CardContent>
            <Typography variant='subtitle1'>From</Typography>
            <TextStack direction='column'>
              <TextStackItem>
                <TextLabel>Sub Account</TextLabel>
                <TextValue links={fromSubAccountsLinks}> </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Parent Account</TextLabel>
                <TextValue
                  detail={fromAccountDetails}
                  links={fromParentAccountsLinks}>
                  {' '}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Custodian Account Number</TextLabel>
                <TextValue>
                  {fromSubAccount.parentAccount?.custodianAccountNumber}
                </TextValue>
              </TextStackItem>
            </TextStack>
          </CardContent>
        </Card>
      )}
      {request.toSubAccount && (
        <Card>
          <CardContent>
            <Typography variant='subtitle1'>To</Typography>
            <TextStack direction='column'>
              <TextStackItem>
                <TextLabel>Sub Account</TextLabel>
                <TextValue links={toSubAccountsLinks}> </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Parent Account</TextLabel>
                <TextValue
                  detail={toSubAccount.parentAccount?.accountName ?? ''}
                  links={toParentAccountsLinks}>
                  {' '}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Custodian Account Number</TextLabel>
                <TextValue>
                  {toSubAccount.parentAccount?.custodianAccountNumber}
                </TextValue>
              </TextStackItem>
            </TextStack>
          </CardContent>
        </Card>
      )}
      <Card>
        <CardContent loading={workflowSearchQuery.isInitialLoading}>
          {workflowSearchQuery.data?.results?.length ? (
            <>
              <Typography variant='subtitle1'>
                {formatters.displayCase(
                  workflowSearchQuery.data.results[0].workflowName
                ) ?? ''}
              </Typography>
              <Stack spacing={1}>
                {workflowSearchQuery.data.results[0].executionStatus && (
                  <Badge
                    color={
                      executionStatusColorMap[
                        workflowSearchQuery.data.results[0].executionStatus
                      ]
                    }
                    maxWidth='fit-content'>
                    {`Workflow ${formatters.getValueKey(WorkflowExecutionStatusEnum, workflowSearchQuery.data.results[0].executionStatus)}`}
                  </Badge>
                )}
                {workflowSearchQuery.data.results[0].workflowStatus && (
                  <Stack direction='row' spacing={1} useFlexGap>
                    <SubdirectoryArrowRightOutlinedIcon
                      color='primary'
                      fontSize='small'
                    />
                    <Link
                      maxWidth={200}
                      target='_blank'
                      textOverflow='ellipsis'
                      to={`/ops/workflows/tracer:${alert.tracerId}`}>
                      {alert.tracerId}
                    </Link>
                    <Badge
                      color={
                        workflowStatusColorMap[
                          workflowSearchQuery.data.results[0].workflowStatus
                        ]
                      }>
                      {formatters.displayCase(
                        formatters.getValueKey(
                          WorkflowStatusEnumsObject,
                          workflowSearchQuery.data?.results[0].workflowStatus
                        )
                      )}
                    </Badge>
                  </Stack>
                )}
              </Stack>
            </>
          ) : (
            <Typography color='GrayText' variant='subtitle1'>
              No workflow found for this alert
            </Typography>
          )}
        </CardContent>
      </Card>
      <TextStack direction='column'>
        <TextStackItem>
          <TextLabel>Created</TextLabel>
          <TextValue>
            {alert.createdAt
              ? formatters.formatFromIsoDateCustom(
                  alert.createdAt,
                  'MM/DD/YYYY HH:mm:ss'
                )
              : null}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Last Updated</TextLabel>
          <TextValue>
            {alert.updatedAt
              ? formatters.formatFromIsoDateCustom(
                  alert.updatedAt,
                  'MM/DD/YYYY HH:mm:ss'
                )
              : null}
          </TextValue>
        </TextStackItem>
      </TextStack>
    </>
  );
};
