import { ReEnrollmentFull } from '@/components/icon/ReEnrollmentFull';
import { ReEnrollmentNone } from '@/components/icon/ReEnrollmentNone';
import { ReEnrollmentPartial } from '@/components/icon/ReEnrollmentPartial';
import TooltipButton from '@/components/tool-tip-button/TooltipButton.component';
import { Program } from '@/models/ops/investments/Program.model';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { useFormik } from 'formik';
import { isMatch, pick } from 'lodash';
import { FC, useCallback } from 'react';
import * as yup from 'yup';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1)
}));

const StyledFormControlLabel = styled(FormControlLabel)({
  justifyContent: 'space-between',
  marginLeft: 0
});

const validationSchema = (currentProgram, newProgram) =>
  yup.object({
    reEnrollmentOption: yup
      .string()
      .required()
      .test(
        'no re-enrollment check',
        'Cannot select no re-enrollment. The new program is incompatible.',
        values => values !== 'none' || isMatch(newProgram, currentProgram)
      )
  });

export type ReEnrollmentType = 'full' | 'partial' | 'none';

type ReEnrollmentModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
  currentProgramName: string;
  currentProgramId: number;
  newModelIds: Pick<
    Program,
    'targetDateSeriesId' | 'riskSeriesId' | 'goalSeriesId' | 'fundLineupId'
  >;
  newProgramName: string;
  newProgramId: number;
  onSubmit: (reEnrollmentOption: ReEnrollmentType) => void;
  isSubmitting: boolean;
};

export const ReEnrollmentModal: FC<ReEnrollmentModalProps> = props => {
  const currentProgramQuery = useQuery<Program>(
    ['ProgramService.getProgram', props.currentProgramId],
    () => ProgramService.getProgram(props.currentProgramId),
    { cacheTime: 0, enabled: Boolean(props.currentProgramId) }
  );

  const form = useFormik<{ reEnrollmentOption: ReEnrollmentType | null }>({
    enableReinitialize: true,
    initialValues: { reEnrollmentOption: null },
    onSubmit: () => undefined,
    validationSchema: validationSchema(
      pick(currentProgramQuery.data, [
        'targetDateSeriesId',
        'riskSeriesId',
        'goalSeriesId',
        'fundLineupId'
      ]),
      props.newModelIds
    )
  });

  const onModalClose = useCallback(() => {
    if (props.isSubmitting) return;

    form.resetForm();
    props.onClose();
  }, [props.isSubmitting]);

  const onSubmit = useCallback(() => {
    props.onSubmit(form.values.reEnrollmentOption);
    form.resetForm();
  }, [form.values.reEnrollmentOption]);

  return (
    <Dialog onClose={onModalClose} open={props.isModalOpen}>
      <DialogTitle>Change investment program</DialogTitle>
      <DialogContent dividers>
        <Grid alignItems='stretch' container>
          <Grid item xs={5.5}>
            <StyledBox>
              <Typography variant='subtitle2'>Current Program</Typography>
              <Typography variant='body1'>
                {props.currentProgramName}
              </Typography>
              <Typography variant='body2'>
                ID: {props.currentProgramId}
              </Typography>
            </StyledBox>
          </Grid>
          <Grid alignContent='center' item textAlign='center' xs={1}>
            <ArrowForward />
          </Grid>
          <Grid item xs={5.5}>
            <StyledBox>
              <Typography variant='subtitle2'>New Program</Typography>
              <Typography variant='body1'>{props.newProgramName}</Typography>
              <Typography variant='body2'>ID: {props.newProgramId}</Typography>
            </StyledBox>
          </Grid>
        </Grid>
        <Typography mb={2} mt={2} variant='body1'>
          Select a re-enrollment method
        </Typography>
        <FormControl data-testid='reEnrollmentRadio'>
          <RadioGroup
            name='reEnrollment'
            onChange={event =>
              form.setFieldValue('reEnrollmentOption', event.target.value)
            }>
            <Stack divider={<Divider />} spacing={2}>
              {[
                {
                  Icon: ReEnrollmentFull,
                  content:
                    "Re-enroll all savers in the plan in the new program's QDIA",
                  title: 'Full Re-enrollment',
                  value: 'full'
                },
                {
                  Icon: ReEnrollmentPartial,
                  content:
                    "Re-enroll savers that are still in program's QDIA only, re-elect new investment strategies for the rest",
                  title: 'Partial Re-enrollment',
                  value: 'partial'
                },
                {
                  Icon: ReEnrollmentNone,
                  content: 'Savers stay in their current investment elections',
                  title: 'No Re-enrollment',
                  value: 'none'
                }
              ].map(({ Icon, content, title, value }) => (
                <StyledFormControlLabel
                  control={<Radio />}
                  key={value}
                  label={
                    <>
                      <Stack alignItems='center' direction='row' spacing={2}>
                        <Icon fontSize='large' />
                        <Stack>
                          <Typography variant='subtitle1'>{title}</Typography>
                          <Typography variant='body2'>{content}</Typography>
                        </Stack>
                      </Stack>
                      {value === 'none' && form.errors.reEnrollmentOption && (
                        <Typography color='error' variant='caption'>
                          {form.errors.reEnrollmentOption}
                        </Typography>
                      )}
                    </>
                  }
                  labelPlacement='start'
                  value={value}
                />
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button disabled={props.isSubmitting} onClick={onModalClose}>
          Cancel
        </Button>
        <TooltipButton
          disabled={!(form.dirty && form.isValid) || props.isSubmitting}
          handleOnClick={onSubmit}
          testId='confirm-rebalance-btn'
          tooltipMessage='You must select and option to proceed without making updates'
          tooltipPlacement='top'
          variant='contained'>
          Continue
        </TooltipButton>
      </DialogActions>
    </Dialog>
  );
};

ReEnrollmentModal.displayName = 'ReEnrollmentModal';
