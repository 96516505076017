import { Typography } from '@mui/material';

import ActionHistoryCard from './ActionHistoryCard.component';

const ActionCenterRoute = (): JSX.Element => {
  return (
    <>
      <Typography mb={5} mt={2} variant='h4'>
        Action Center
      </Typography>
      <ActionHistoryCard />
    </>
  );
};

export default ActionCenterRoute;
