import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { BulkAction } from '@/models/BulkAction.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import { Box, Link, Theme, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useMutation } from '@tanstack/react-query';

import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  infoBox: {
    display: 'flex',
    gap: theme.spacing(8),
    marginLeft: theme.spacing(3)
  },
  infoTitle: {
    color: grey[700],
    fontSize: '14px',
    fontWeight: '500',
    width: '100px'
  },
  tableCell: { borderBottom: 'none', p: '10px 16px' },
  tableHeader: { color: grey[700], padding: '5px 16px' }
}));

interface ActionHistoryTableCollapsibleComponentProps {
  row: BulkAction;
}

export const ActionHistoryTableCollapsibleComponent = (
  props: ActionHistoryTableCollapsibleComponentProps
): JSX.Element => {
  const { row } = props;
  const classes = useStyles();

  const { mutate: downloadDocument } = useMutation(
    ['PlanService.getDocument'],
    (documentId: number) => {
      return ActionCenterService.getDocument(documentId);
    },
    {
      onSuccess: data => {
        InMemoryFileDownloadService.triggerFileDownload(
          data.base64Data,
          data.originalFileName
        );
      }
    }
  );

  const columns: GridColDef[] = [
    {
      field: 'entityId',
      flex: 1,
      headerName: 'Plan ID',
      valueFormatter: params => params.value || EMPTY_FIELD_PLACEHOLDER
    },
    {
      field: row.actionType === 'email' ? 'templateName' : 'documentName',
      flex: 1,
      headerName: row.actionType === 'email' ? 'Template' : 'Document'
    }
  ];

  return (
    <Box
      data-testid={`action-history-table-collapsible-action-${row.actionId}`}
      display='flex'
      flexDirection='column'
      gap='10px'
      mb={6}
      mt={4}
      paddingBottom='15px'>
      {!!row.metadata?.plans?.length && (
        <Box className={classes.infoBox}>
          <Typography className={classes.infoTitle}>Plans Updated</Typography>
          <Box display='flex' flexWrap='wrap'>
            {row.metadata.plans.map((plan: number, index: number) => {
              const separate =
                row.metadata.plans.length - 1 === index ? '' : ',';

              return (
                <>
                  <Link
                    component={RouterLink}
                    mr='8px'
                    target='_blank'
                    to={`/plans/${plan}/plan`}
                    underline='none'>
                    {plan}
                    {separate}
                  </Link>
                </>
              );
            })}
          </Box>
        </Box>
      )}
      {row.metadata?.zipFileUploadName && (
        <Box className={classes.infoBox}>
          <Typography className={classes.infoTitle}>Uploaded File</Typography>
          <div>
            <Tooltip
              style={{
                pointerEvents: !row.metadata.zipFileUploadHistoryId
                  ? 'none'
                  : undefined
              }}
              title={`Click to download ${row.metadata.zipFileUploadName}`}>
              <Link
                color='primary'
                onClick={() =>
                  row.metadata.zipFileUploadHistoryId
                    ? downloadDocument(row.metadata.zipFileUploadHistoryId)
                    : null
                }
                sx={{
                  color: !row.metadata.zipFileUploadHistoryId
                    ? 'black !important'
                    : null,
                  cursor: 'pointer'
                }}>
                {row.metadata.zipFileUploadName}
              </Link>
            </Tooltip>
          </div>
        </Box>
      )}

      {!!row.errordata?.length && (
        <Box className={classes.infoBox}>
          <Typography className={classes.infoTitle}>
            Failed to {row.actionType === 'email' ? 'update' : 'upload'}
          </Typography>

          <DataGridPro
            autoHeight
            columns={columns}
            disableColumnResize
            disableRowSelectionOnClick
            getRowId={errorRow => errorRow.entityId}
            hideFooter
            rows={row.errordata}
            sx={{
              '.MuiDataGrid-columnSeparator': {
                display: 'none'
              },
              border: '0px !important'
            }}
          />
        </Box>
      )}

      {!!row.errordata?.entities?.length && (
        <Box className={classes.infoBox}>
          <Typography className={classes.infoTitle}>Failed emails</Typography>
          <Typography>
            {row.metadata?.templateType === 'BBOO' ? 'Sponsor' : 'Participant'}{' '}
            Ids:{' '}
          </Typography>
          <Typography>{row.errordata.entities.join(', ')}</Typography>
        </Box>
      )}

      {!!row.metadata?.successEntities?.length && (
        <Box className={classes.infoBox}>
          <Typography className={classes.infoTitle}>Sent emails</Typography>
          <Typography>
            {row.metadata?.templateType === 'BBOO' ? 'Sponsor' : 'Participant'}{' '}
            Ids:{' '}
          </Typography>
          <Typography>{row.metadata?.successEntities.join(', ')}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ActionHistoryTableCollapsibleComponent;
