import CopyToClipboard from '@/components/copy-to-clipboard';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import LinearLoading from '@/components/linear-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import SimpleTabs, { TabData } from '@/components/simple-tabs';
import { useParentAccountById } from '@/hooks/ops/useParentAccountById.hook';
import { useTitle } from '@/hooks/useTitle';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import Conductor from '@/routes/ops/accounts/common/Conductor.component';
import { CreateAchRequestDialog } from '@/routes/ops/accounts/common/CreateAchRequestDialog.component';
import { Holdings } from '@/routes/ops/accounts/common/holdings/Holdings.component';
import { SearchReconExceptions } from '@/routes/ops/common/SearchReconExceptions.component';
import { CreateTransactionDialog } from '@/routes/ops/common/transactions/CreateTransactionDialog.component';
import { Transactions } from '@/routes/ops/common/transactions/Transactions.component';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { Box, Link, Stack, Typography } from '@mui/material';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SearchAlerts } from '../../common/SearchAlerts.component';
import {
  CreateMenu,
  CreateMenuItem,
  HeadingMenuItem
} from '../common/CreateMenu.component';
import ParentAccountOrders from '../common/ParentAccountOrders.component';
import { TransferCashDrawer } from '../common/TransferCashDrawer.component';
import { AccountDetailsHeader } from './components/AccountDetailsHeader.component';
import { PlansTab } from './components/PlansTab.component';
import { SubAccountsTab } from './components/SubAccountsTab.component';
import { useAccountsDetailRouteParams } from './useAccountsDetailRouteParams.hook';

enum CreateAction {
  AchRequest = 'AchRequest',
  CashTransfer = 'CashTransfer',
  Transaction = 'Transaction'
}

type AccountsDetailRouteProps = {
  accountId: string;
};

export const AccountsDetailRoute: FC = () => {
  const title = useTitle();
  const { accountId } = useParams<AccountsDetailRouteProps>();
  const routeParams = useAccountsDetailRouteParams();
  const [action, setAction] = useState<CreateAction | null>(null);
  const getParentAccountByIdQuery = useParentAccountById({
    accountId
  });

  const tabElements: TabData[] = useMemo(
    // memo to prevent tabs being unloaded and reloaded when this component state changes
    () =>
      [
        getParentAccountByIdQuery.data?.accountType ===
          ParentAccountType.SuperOmnibus && {
          component: <PlansTab />,
          label: 'Plans',
          path: `/ops/accounts/${accountId}/plans`
        },
        {
          component: <SubAccountsTab parentAccountId={routeParams.accountId} />,
          label: 'Sub Accounts',
          path: `/ops/accounts/${accountId}/sub-accounts`
        },
        {
          component: <Holdings parentAccountId={accountId} />,
          label: 'Holdings',
          path: `/ops/accounts/${accountId}/holdings`
        },
        {
          component: <ParentAccountOrders parentAccountId={accountId} />,
          label: 'Trading',
          path: `/ops/accounts/${accountId}/trading`
        },
        {
          component: <Transactions accountId={accountId} />,
          label: 'Transactions',
          path: `/ops/accounts/${accountId}/transactions`
        },
        {
          component: <Conductor accountId={accountId} />,
          label: 'Conductor',
          path: `/ops/accounts/${accountId}/conductor`
        },
        {
          component: (
            <SearchAlerts
              onAlertOpen={alert => {
                title.setPageName(
                  `${name} Alert ${alert.id} ${alert.alertName}`
                );
              }}
              onDrawerClose={() => {
                title.setPageName(`${name} Alerts`);
              }}
              onTabChange={newAlertType => {
                title.setPageName(
                  `${name} Alerts ${
                    newAlertType ? formatters.displayCase(newAlertType) : ''
                  }`
                );
              }}
              parentAccountId={accountId}
            />
          ),
          label: 'Alerts',
          path: `/ops/accounts/${accountId}/alerts`
        },
        {
          component: <SearchReconExceptions parentAccountId={accountId} />,
          label: 'Recon',
          path: `/ops/accounts/${accountId}/recon`
        }
      ].filter(Boolean),
    [accountId, getParentAccountByIdQuery.data, routeParams.accountId]
  );

  if (!userService.hasPermission(FeatureLevelPermissions.READ_SUBA_ACCOUNTS)) {
    return redirectToErrorPage(new Error('403'));
  }

  const name =
    getParentAccountByIdQuery.data?.accountType === 'SuperOmnibus' ||
    getParentAccountByIdQuery.data?.accountType === 'House'
      ? getParentAccountByIdQuery.data?.accountName
      : getParentAccountByIdQuery.data?.plan?.name;

  const paths = [{ name: 'Search Accounts', to: '/ops/accounts' }];

  if (getParentAccountByIdQuery.isInitialLoading) return <LinearLoading />;

  return (
    <>
      {action === CreateAction.Transaction &&
        getParentAccountByIdQuery.data && (
          <CreateTransactionDialog
            accountId={accountId}
            accountLevel={AccountLevel.ParentAccount}
            custodianId={getParentAccountByIdQuery.data.custodianId}
            onClose={() => setAction(null)}
            open
            parentAccountType={getParentAccountByIdQuery.data.accountType}
          />
        )}

      {action === CreateAction.AchRequest && getParentAccountByIdQuery.data && (
        <CreateAchRequestDialog
          accountId={accountId}
          onClose={() => setAction(null)}
          open
          parentAccountId={getParentAccountByIdQuery.data.parentAccountId}
          planId={getParentAccountByIdQuery.data.planId}
        />
      )}

      {action === CreateAction.CashTransfer &&
        getParentAccountByIdQuery.data && (
          <TransferCashDrawer
            onClose={() => setAction(null)}
            onSubmit={() => setAction(null)}
            open
            parentAccountId={getParentAccountByIdQuery.data.parentAccountId}
            planId={
              getParentAccountByIdQuery.data.accountType ===
              ParentAccountType.PlanLevel
                ? getParentAccountByIdQuery.data.planId
                : undefined
            }
          />
        )}

      {!getParentAccountByIdQuery.isInitialLoading &&
        getParentAccountByIdQuery.data && (
          <Stack position='relative' spacing={4} useFlexGap>
            <Stack>
              <Box
                data-testid='account-detail-header'
                display='flex'
                justifyContent='space-between'>
                <NavigationBreadcrumbs
                  data-testid='account-detail-breadcrumbs'
                  paths={paths}
                />
              </Box>
              <Typography role='heading' variant='h4'>
                {name}
              </Typography>
              <Stack direction='row' spacing={2}>
                <Stack alignItems='flex-end' direction='row'>
                  <Typography data-testid='detail_id' variant='subtitle1'>
                    ID: {getParentAccountByIdQuery.data?.parentAccountId}
                  </Typography>
                  <CopyToClipboard
                    copyName={`Account ID `}
                    copyValue={String(
                      getParentAccountByIdQuery.data?.parentAccountId
                    )}
                  />
                </Stack>
                <Stack alignItems='flex-end' direction='row'>
                  <Typography data-testid='detail_id' variant='subtitle1'>
                    View plan details:
                  </Typography>
                  <Link
                    href={`/plans/${getParentAccountByIdQuery.data?.planId}/plan`}
                    target='_blank'>
                    <OpenInNewIcon fontSize='inherit' />
                  </Link>
                </Stack>
              </Stack>
            </Stack>
            <AccountDetailsHeader accountId={accountId} />
            <CreateMenu>
              <HeadingMenuItem>For Parent Account</HeadingMenuItem>
              <CreateMenuItem
                data-testid='transaction-menu-item'
                onClick={() => setAction(CreateAction.Transaction)}>
                Transaction
              </CreateMenuItem>
              {userService.hasPermission(
                FeatureLevelPermissions.WRITE_SUBA_INITIATE_ACH
              ) && (
                <CreateMenuItem
                  data-testid='ach-request-menu-item'
                  onClick={() => setAction(CreateAction.AchRequest)}>
                  ACH Request
                </CreateMenuItem>
              )}
              {userService.hasPermission(
                FeatureLevelPermissions.WRITE_SUBA_TRANSFER_CASH
              ) && (
                <CreateMenuItem
                  data-testid='cash-transfer-menu-item'
                  onClick={() => setAction(CreateAction.CashTransfer)}>
                  Cash Transfer
                </CreateMenuItem>
              )}
            </CreateMenu>
            <SimpleTabs
              data-testid='accounts-detail-tabs'
              onChange={index => {
                if (tabElements[index].label !== 'Alerts') {
                  title.setPageName(`${name} ${tabElements[index].label}`);
                }
              }}
              tabs={tabElements}
              tabsAriaLabel='accounts-tabs'
            />
          </Stack>
        )}
    </>
  );
};
