import planDesignConsts from '@/consts/planDesign.constants';
import {
  DiscretionaryMatchDollarCap,
  EligibilityRule,
  PlanDesign,
  SafeHarborType
} from '@/models/PlanDesign.model';
import ApiService from '@/services/Api.service';
import { parseDateToZonedTime } from '@/utils/Dates';

import dayjs from 'dayjs';
import { camelCase, isNumber } from 'lodash';
import { read, utils } from 'xlsx';

type ColumnDef = {
  alternates?: string[];
  field: string;
  fieldName?: string;
  formatter?: (value: string) => unknown;
  section?: keyof PlanDesign;
  sectionName?: string;
  type: 'boolean' | 'date' | 'datestring' | 'number' | 'string';
};

const formatters: Record<string, ColumnDef['formatter']> = {
  boolean: value => String(value).toLowerCase() === 'true',
  date: value => (value ? parseDateToZonedTime(value) : value),
  datestring: value => (dayjs(value).isValid() ? value : null),
  number: value => Number(value)
};

const fundingSourcesKeys = [
  'employerMatch',
  'profitSharing',
  'QACASafeHarborMatch',
  'QACASafeHarborNonElective'
];

const eligibilityRulesKeys = [
  'hoursRequired',
  'lengthOfEmploymentRequired',
  'minimumAge',
  'serviceCalculationType'
];

export const getPlanDesignColumnDefs = (
  safeHarborTypes: SafeHarborType[]
): ColumnDef[] => [
  {
    field: 'sponsorPlanId',
    type: 'number'
  },
  {
    field: 'planName',
    type: 'string'
  },
  {
    field: 'allowHardshipElective',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowHardshipFromPartialVestedAccount',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowHardshipMatch',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    alternates: ['allow_hardship_ps'],
    field: 'allowHardshipProfitSharing',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowHardshipRollover',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    alternates: ['allow_hardship_sh'],
    field: 'allowHardshipSafeHarbor',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    alternates: ['allow_hardship_sh_apply'],
    field: 'allowHardshipSafeHarborApply',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowHardshipTransfer',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowHardshipWithdrawals',
    fieldName: 'Allow Hardship Withdrawals',
    formatter: (value: string): string | null => {
      const uiValue = planDesignConsts.hardshipWithdrawalsOptions.find(
        option => option.toLowerCase() === value.toLowerCase()
      );
      return uiValue || null;
    },
    section: 'distributionFeatures',
    sectionName: 'Distribution',
    type: 'string'
  },
  {
    field: 'allowHardshipWithdrawalsFromRothAccount',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowInServiceAtEarlyAge',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowInServiceAtEarlyAgeFromAllAccounts',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowInServiceAtNormalAge',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowInServiceAtNormalAgeFromAllAccounts',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowInServiceAtSpecifiedAge',
    fieldName: 'Allow In Service At Specified Age',
    formatter: (value: string): string | null => {
      const uiValue = planDesignConsts.inServiceAtSpecifiedAgeOptions.find(
        option => option.toLowerCase() === value.toLowerCase()
      );
      return uiValue || null;
    },
    section: 'distributionFeatures',
    sectionName: 'Distribution',
    type: 'string'
  },
  {
    field: 'allowInServiceFromPartialVestedAccount',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowInServiceFromRolloverAccount',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowInServiceUponDisability',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowPartialDistributionsAfterTermination',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'allowSpecifiedAgeFromRothAccount',
    section: 'distributionFeatures',
    type: 'boolean'
  },
  {
    field: 'earlyRetirementAge',
    section: 'distributionFeatures',
    type: 'number'
  },
  {
    field: 'earlyRetirementAgeType',
    fieldName: 'Early Retirement Age Type',
    formatter: (value: string): string | null => {
      const uiValue = planDesignConsts.earlyRetirementAgeTypeOptions.find(
        option => option.toLowerCase() === value.toLowerCase()
      );
      return uiValue || null;
    },
    section: 'distributionFeatures',
    sectionName: 'Distribution',
    type: 'string'
  },
  {
    field: 'hardshipCriteria',
    fieldName: 'Hardship criteria',
    formatter: (value: string): string | null => {
      const uiValue = planDesignConsts.hardshipCriteriaOptions.find(
        option => option.toLowerCase() === value.toLowerCase()
      );
      return uiValue || null;
    },
    section: 'distributionFeatures',
    sectionName: 'Distribution',
    type: 'string'
  },
  {
    field: 'inServiceSpecifiedAge',
    section: 'distributionFeatures',
    type: 'number'
  },
  {
    field: 'normalRetirementAge',
    section: 'distributionFeatures',
    type: 'number'
  },
  {
    field: 'normalRetirementAgeType',
    fieldName: 'Normal Retirement Age Type',
    formatter: (value: string): string | null => {
      const uiValue = planDesignConsts.normalRetirementAgeTypeOptions.find(
        option => option.toLowerCase() === value.toLowerCase()
      );
      return uiValue || null;
    },
    section: 'distributionFeatures',
    sectionName: 'Distribution',
    type: 'string'
  },
  {
    field: 'originalEffectivePlanDate',
    section: 'effectiveDate',
    type: 'date'
  },
  {
    alternates: ['plan_year'],
    field: 'planYearEnd',
    fieldName: 'Plan Year End',
    formatter: (value: string): string | null => {
      const uiValue = planDesignConsts.planYearEndOptions.find(
        option =>
          option.toLowerCase() === dayjs(value).format('MMMM DD').toLowerCase()
      );

      return uiValue || null;
    },
    section: 'effectiveDate',
    sectionName: 'Plan Effective and Year-End Dates',
    type: 'string'
  },
  {
    field: 'safeHarborEffectiveDate',
    section: 'effectiveDate',
    type: 'date'
  },
  {
    alternates: ['eligibility_entry'],
    field: 'entryDateFrequency',
    fieldName: 'Entry Date Frequency',
    formatter: (value: string): string | null => {
      const uiValue = planDesignConsts.entryDateFrequencies.find(
        frequency => frequency.type.toLowerCase() === value?.toLowerCase()
      )?.type;
      return uiValue || null;
    },
    section: 'eligibilityFeatures',
    sectionName: 'Eligibility Plan Features',
    type: 'string'
  },
  {
    field: 'excludeLeasedEmployees',
    section: 'eligibilityFeatures',
    type: 'boolean'
  },
  {
    alternates: ['eligibility_hours'],
    field: 'hoursRequired',
    section: 'eligibilityFeatures',
    type: 'number'
  },
  {
    alternates: ['eligibility_months'],
    field: 'lengthOfEmploymentRequired',
    fieldName: 'Length Of Employment Required',
    formatter: (value: string): number | null => {
      const uiValue = planDesignConsts.lengthOfEmploymentRequiredOptions.find(
        option => option.value === Number(value)
      )?.value;

      return uiValue || uiValue === 0 ? uiValue : null;
    },
    section: 'eligibilityFeatures',
    sectionName: 'Eligibility Plan Features',
    type: 'number'
  },
  {
    alternates: ['eligibility_age'],
    field: 'minimumAge',
    fieldName: 'Minimum Age Requirement',
    formatter: (value: string): number | null => {
      const uiValue = planDesignConsts.minimumAgeOptions.find(
        option => option === Number(value)
      );

      return uiValue || uiValue === 0 ? uiValue : null;
    },
    section: 'eligibilityFeatures',
    sectionName: 'Eligibility Plan Features',
    type: 'number'
  },
  {
    alternates: ['eligibility_rule'],
    field: 'serviceCalculationType',
    fieldName: 'Service Calculation Type',
    formatter: (
      value: string
    ): {
      eligibilityRequirementTypeId: number;
      eligibilityRequirementTypeName: string;
    } | null => {
      const uiValue = planDesignConsts.eligibilityRequirementTypes.find(
        type =>
          type.eligibilityRequirementTypeName.toLowerCase() ===
          value?.toLowerCase()
      );

      return uiValue || null;
    },
    section: 'eligibilityFeatures',
    sectionName: 'Eligibility Plan Features',
    type: 'string'
  },
  {
    alternates: ['eligibility_override_start_date'],
    field: 'specialEntryDate',
    section: 'eligibilityFeatures',
    type: 'datestring'
  },
  {
    field: 'specialParticipationDate',
    section: 'eligibilityFeatures',
    type: 'date'
  },
  {
    field: 'allowAfterTaxContribution',
    section: 'employeeContribution',
    type: 'boolean'
  },
  // allowPermissibleWithdrawal and permissibleTimeframe fields must be shown in the Employee Contribution
  // section (since these fields are dependent on autoEnrollAmount), though in DB they are stored in the
  // plan_distribution_features table
  {
    field: 'allowPermissibleWithdrawal',
    section: 'employeeContribution',
    type: 'boolean'
  },
  {
    field: 'permissibleTimeframe',
    section: 'employeeContribution',
    type: 'number'
  },
  {
    field: 'allowPretaxContribution',
    section: 'employeeContribution',
    type: 'boolean'
  },
  {
    field: 'autoEnrollAmount',
    fieldName: 'Auto Enroll Amount',
    formatter: (value: string): number | null => {
      const uiValue = planDesignConsts.autoEnrollAmountOptions.find(
        option => option.key === parseInt(value, 10)
      )?.key;

      return isNumber(uiValue) ? uiValue : null;
    },
    section: 'employeeContribution',
    sectionName: 'Employee Contribution',
    type: 'number'
  },
  {
    field: 'autoEnrollEffectiveDate',
    section: 'employeeContribution',
    type: 'date'
  },
  {
    field: 'autoEscalateAmount',
    fieldName: 'Auto Escalate Amount',
    formatter: (value: string): number | null => {
      const uiValue = planDesignConsts.autoEscalateAmountOptions.find(
        option => option.key === parseInt(value, 10)
      )?.key;

      return isNumber(uiValue) ? uiValue : null;
    },
    section: 'employeeContribution',
    sectionName: 'Employee Contribution',
    type: 'number'
  },
  {
    alternates: ['auto_escalate_ceiling'],
    field: 'autoEscalateMaximum',
    section: 'employeeContribution',
    type: 'number'
  },
  {
    field: 'isSalaryRothDeferral',
    section: 'employeeContribution',
    type: 'boolean'
  },
  {
    alternates: ['is_profit_sharing'],
    field: 'allowEmployerProfitSharing',
    section: 'employerContribution',
    type: 'boolean'
  },
  {
    field: 'allowRollover',
    fieldName: 'Allow Rollover',
    formatter: (value: string): string | null => {
      const uiValue = planDesignConsts.rolloverOptions.find(
        option => option.toLowerCase() === value.toLowerCase()
      );
      return uiValue || null;
    },
    section: 'employerContribution',
    sectionName: 'Employer Contribution',
    type: 'string'
  },
  {
    field: 'isSafeHarbor',
    section: 'employerContribution',
    type: 'boolean'
  },
  {
    field: 'allowSafeHarborNonElective',
    section: 'employerContribution',
    type: 'boolean'
  },
  {
    field: 'documentMatchFrequency',
    fieldName: 'Document Match Frequency',
    formatter: (value: string): string | null => {
      const uiValue = planDesignConsts.documentMatchFrequencyOptions.find(
        option => option.toLowerCase() === value.toLowerCase()
      );
      return uiValue === 'N/A - No Match'
        ? 'N/A - No Match'
        : uiValue
          ? uiValue.toLowerCase()
          : null;
    },
    section: 'employerContribution',
    sectionName: 'Employer Contribution',
    type: 'string'
  },
  {
    field: 'isEmployerMatching',
    section: 'employerContribution',
    type: 'boolean'
  },
  {
    alternates: ['non_safe_harbor_contribution_type'],
    field: 'nonSafeHarborMatchType',
    fieldName: 'Non-Safe Harbor Match Type',
    formatter: (value: string): string | null => {
      const uiValue = planDesignConsts.nonSafeHarborMatchTypeOptions.find(
        option => option.toLowerCase() === value.toLowerCase()
      );
      return uiValue || null;
    },
    section: 'employerContribution',
    sectionName: 'Employer Contribution',
    type: 'string'
  },
  {
    field: 'profitSharingStrategy',
    fieldName: 'Profit Sharing Type',
    formatter: (value: string): string | null => {
      const uiValue = planDesignConsts.profitSharingTypes.find(
        option => option.value?.toLowerCase() === value?.toLowerCase()
      )?.value;
      return uiValue || null;
    },
    section: 'employerContribution',
    sectionName: 'Employer Contribution',
    type: 'string'
  },
  {
    alternates: ['safe_harbor_strategy'],
    field: 'safeHarborMatchType',
    fieldName: 'Safe Harbor Contribution Type',
    formatter: (value: string): string | null => {
      const uiValue = safeHarborTypes.find(
        option => option.code.toLowerCase() === value.toLowerCase()
      )?.code;
      return uiValue || null;
    },
    section: 'employerContribution',
    sectionName: 'Employer Contribution',
    type: 'string'
  },
  {
    alternates: ['force_out_preferences.exclude_rollover'],
    field: 'excludeRollover',
    section: 'forceOutPreferences',
    type: 'boolean'
  },
  {
    alternates: ['force_out_preferences.is_allowed'],
    field: 'isAllowed',
    section: 'forceOutPreferences',
    type: 'boolean'
  },
  {
    alternates: ['force_out_preferences.max_amount'],
    field: 'maxAmount',
    section: 'forceOutPreferences',
    type: 'number'
  },
  {
    alternates: ['force_out_preferences.rollover_min'],
    field: 'rolloverMin',
    section: 'forceOutPreferences',
    type: 'number'
  },
  {
    field: 'allowLoans',
    section: 'loanFeatures',
    type: 'string' // historically this field is of a string type. "true" instead of true
  },
  {
    field: 'allowResidencePurchaseExtension',
    section: 'loanFeatures',
    type: 'boolean'
  },
  {
    field: 'maxOutstandingLoans',
    section: 'loanFeatures',
    type: 'number'
  },
  {
    field: 'maxYearsResidencePurchase',
    section: 'loanFeatures',
    type: 'number'
  },
  {
    alternates: ['vesting_sched_qm'],
    field: 'QACASafeHarborMatch',
    fieldName: 'QACA Safe Harbor Match',
    formatter: (
      value: string
    ): {
      fundingSourceId: number;
      fundingSourceName: string;
      vestingScheduleId: number;
      vestingScheduleName: string;
    } | null => {
      const uiValue = planDesignConsts.vestingSchedules.find(
        option => option.id === Number(value)
      );
      return uiValue
        ? {
            fundingSourceId: 14,
            fundingSourceName: 'QACA SH Match',
            vestingScheduleId: Number(value),
            vestingScheduleName: uiValue.value
          }
        : null;
    },
    section: 'vestingPreferences',
    sectionName: 'Vesting',
    type: 'number'
  },
  {
    alternates: ['vesting_sched_qn'],
    field: 'QACASafeHarborNonElective',
    fieldName: 'QACA Safe Harbor Non-Elective',
    formatter: (
      value: string
    ): {
      fundingSourceId: number;
      fundingSourceName: string;
      vestingScheduleId: number;
      vestingScheduleName: string;
    } | null => {
      const uiValue = planDesignConsts.vestingSchedules.find(
        option => option.id === Number(value)
      );
      return uiValue
        ? {
            fundingSourceId: 15,
            fundingSourceName: 'QACA SH Nonelective',
            vestingScheduleId: Number(value),
            vestingScheduleName: uiValue.value
          }
        : null;
    },
    section: 'vestingPreferences',
    sectionName: 'Vesting',
    type: 'number'
  },
  {
    alternates: ['vesting_sched_mat'],
    field: 'employerMatch',
    fieldName: 'Employer Match',
    formatter: (
      value: string
    ): {
      fundingSourceId: number;
      fundingSourceName: string;
      vestingScheduleId: number;
      vestingScheduleName: string;
    } | null => {
      const uiValue = planDesignConsts.vestingSchedules.find(
        option => option.id === Number(value)
      );
      return uiValue
        ? {
            fundingSourceId: 13,
            fundingSourceName: 'Employer Match',
            vestingScheduleId: Number(value),
            vestingScheduleName: uiValue.value
          }
        : null;
    },
    section: 'vestingPreferences',
    sectionName: 'Vesting',
    type: 'number'
  },
  {
    field: 'excludeServicePriorTo18YearsOld',
    section: 'vestingPreferences',
    type: 'boolean'
  },
  {
    field: 'excludeServicePriorToPlanExistence',
    section: 'vestingPreferences',
    type: 'boolean'
  },
  {
    field: 'hoursOfServiceRequired',
    section: 'vestingPreferences',
    type: 'number'
  },
  {
    alternates: ['vesting_sched_ps'],
    field: 'profitSharing',
    fieldName: 'Profit Sharing',
    formatter: (
      value: string
    ): {
      fundingSourceId: number;
      fundingSourceName: string;
      vestingScheduleId: number;
      vestingScheduleName: string;
    } | null => {
      const uiValue = planDesignConsts.vestingSchedules.find(
        option => option.id === Number(value)
      );
      return uiValue
        ? {
            fundingSourceId: 3,
            fundingSourceName: 'Profit Sharing',
            vestingScheduleId: Number(value),
            vestingScheduleName: uiValue.value
          }
        : null;
    },
    section: 'vestingPreferences',
    sectionName: 'Vesting',
    type: 'number'
  },
  {
    field: 'vestingMethod',
    fieldName: 'Vesting Method',
    formatter: (value: string): string | null => {
      const uiValue = planDesignConsts.vestingMethod.find(
        option => option.toLowerCase() === value.toLowerCase()
      );
      return uiValue || null;
    },
    section: 'vestingPreferences',
    sectionName: 'Vesting',
    type: 'string'
  }
];

export class PlanDesignService {
  static getDiscretionaryMatchDollarCaps(): Promise<
    DiscretionaryMatchDollarCap[]
  > {
    return ApiService.getJson(`/plan-design/discretionary-match-dollar-caps`);
  }

  static getSafeHarborTypes(): Promise<SafeHarborType[]> {
    return ApiService.getJson('/plan-design/safe-harbor-types');
  }

  static parseSheet<T>(file: File | ArrayBuffer): {
    headers: string[];
    rows: T[];
  } {
    const { Sheets, SheetNames } = read(file, {
      cellDates: true,
      dateNF: 'string',
      raw: true,
      type: 'binary'
    });

    const parsedJson = utils.sheet_to_json<T>(Sheets[SheetNames[0]], {
      dateNF: 'string',
      header: 1,
      raw: true,
      rawNumbers: false
    });

    const headers: string[] = Array.isArray(parsedJson[0]) ? parsedJson[0] : [];
    const rows = parsedJson.slice(1);

    return { headers, rows };
  }

  static getPlanDesignFromFile(
    sponsorPlanId: number,
    headers: string[],
    rows: string[][],
    safeHarborTypes: SafeHarborType[]
  ): {
    supportedValues: PlanDesign;
    unsupportedValues: Record<string, Record<string, any>>;
  } {
    const keyedRows = rows.map(row => {
      return row.reduce<Record<string, string>>((acc, value, index) => {
        acc[headers[index]] = value;
        return acc;
      }, {});
    });

    const headersHash = headers.reduce<Record<string, string>>(
      (hash, header) => {
        hash[camelCase(header).toLowerCase().trim()] = header;
        return hash;
      },
      {}
    );

    const columns = getPlanDesignColumnDefs(safeHarborTypes).map(col => {
      const header = headersHash[camelCase(col.field).toLowerCase()]
        ? headersHash[camelCase(col.field).toLowerCase()]
        : col.alternates?.find(
            alternate => headersHash[camelCase(alternate).toLowerCase()]
          );

      return {
        ...col,
        header
      };
    });

    const idHeaderIndex = columns.findIndex(
      col => col.field === 'sponsorPlanId'
    );

    if (idHeaderIndex === -1) {
      throw new Error('No sponsor plan id column found');
    }

    const originalKeyedData = keyedRows.find(
      row => Number(row[headers[idHeaderIndex]]) === sponsorPlanId
    );

    if (!originalKeyedData) {
      throw new Error(`No sponsor plan data found in the file`);
    }

    const data: Record<string, string> = Object.entries(
      originalKeyedData || {}
    ).reduce((acc, [key, value]) => {
      const column = columns.find(col => col.header === key);

      if (column) {
        return {
          ...acc,
          [column.field]: value
        };
      }

      return acc;
    }, {});

    const unsupportedValues: Record<string, Record<string, any>> = {};

    let ageRequirement: EligibilityRule = {};

    let serviceRequirement: EligibilityRule = {};

    const supportedValues = Object.entries(data).reduce(
      (acc: PlanDesign, [fieldName, fieldValue]) => {
        const col = columns.find(col => col.field === fieldName);
        if (!col?.section) {
          return acc;
        }

        if (fieldValue === undefined) {
          return acc;
        }

        let formattedValue;

        if (col.formatter) {
          formattedValue = col.formatter(fieldValue);

          if (formattedValue === null) {
            unsupportedValues[col.field] = {
              ...col,
              value: fieldValue
            };
            return acc;
          }
        } else {
          formattedValue = formatters[col.type]
            ? formatters[col.type]?.(fieldValue)
            : fieldValue;
        }

        if (fundingSourcesKeys.includes(col.field)) {
          return {
            ...acc,
            vestingPreferences: {
              ...(acc.vestingPreferences || {}),
              fundingSources: [
                ...(acc.vestingPreferences?.fundingSources || []),
                ...(formattedValue ? [formattedValue] : [])
              ]
            }
          };
        }

        if (eligibilityRulesKeys.includes(col.field)) {
          switch (col.field) {
            case 'hoursRequired':
              serviceRequirement.ruleParameter = {
                ...(serviceRequirement.ruleParameter || {}),
                ...(formattedValue || formattedValue === 0
                  ? { hours: formattedValue }
                  : {})
              };
              break;
            case 'lengthOfEmploymentRequired':
              serviceRequirement.ruleParameter = {
                ...(serviceRequirement.ruleParameter || {}),
                ...(formattedValue || formattedValue === 0
                  ? { months: formattedValue }
                  : {})
              };
              break;
            case 'minimumAge':
              ageRequirement =
                formattedValue || formattedValue === 0
                  ? {
                      eligibilityRequirementTypeId: 1,
                      eligibilityRequirementTypeName: 'Age',
                      ruleParameter: { age: formattedValue }
                    }
                  : {};

              break;
            case 'serviceCalculationType':
              serviceRequirement = {
                ...serviceRequirement,
                ...(formattedValue || {})
              };
              break;
          }

          return acc;
        }

        return {
          ...acc,
          [col.section]: {
            ...(acc[col.section] || {}),
            [col.field]: formattedValue
          }
        };
      },
      {}
    );

    if (ageRequirement.eligibilityRequirementTypeId) {
      supportedValues.eligibilityFeatures = {
        ...(supportedValues.eligibilityFeatures || {}),
        eligibilityRules: [ageRequirement]
      };
    }

    if (serviceRequirement.eligibilityRequirementTypeId) {
      supportedValues.eligibilityFeatures = {
        ...(supportedValues.eligibilityFeatures || {}),
        eligibilityRules: [
          ...(supportedValues.eligibilityFeatures?.eligibilityRules || []),
          serviceRequirement
        ]
      };
    }

    supportedValues.employeeContribution = {
      ...(supportedValues.employeeContribution || {}),
      ...('autoEscalateAmount' in unsupportedValues
        ? {}
        : {
            autoEscalateAmount:
              supportedValues.employeeContribution.autoEscalateAmount || 0
          }),
      ...('autoEnrollAmount' in unsupportedValues
        ? {}
        : {
            autoEnrollAmount:
              supportedValues.employeeContribution.autoEnrollAmount || 0
          })
    };

    supportedValues.employerContribution = {
      ...(supportedValues.employerContribution || {}),
      ...('safeHarborMatchType' in unsupportedValues
        ? {}
        : {
            safeHarborMatchType:
              supportedValues.employerContribution.safeHarborMatchType ||
              'N/A - No Safe Harbor'
          })
    };

    supportedValues.employerContribution.allowNonSafeHarborMatch =
      !!supportedValues.employerContribution.nonSafeHarborMatchType;

    supportedValues.effectiveDate = {
      ...(supportedValues.effectiveDate || {}),
      ...(supportedValues.employerContribution.allowEmployerProfitSharing &&
      supportedValues.effectiveDate.originalEffectivePlanDate
        ? {
            profitSharingStartDate:
              supportedValues.effectiveDate.originalEffectivePlanDate
          }
        : {})
    };

    supportedValues.forceOutPreferences = {
      ...(supportedValues.forceOutPreferences || {}),
      ...(supportedValues.forceOutPreferences?.isAllowed
        ? { isAutomated: true }
        : {})
    };

    return { supportedValues, unsupportedValues };
  }
}
