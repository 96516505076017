import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { ConversionResponse } from '@/models/BulkCashAllocation.model';
import {
  ConversionStatus,
  UpdateConversionDto
} from '@/models/ConversionDTO.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import { userService } from '@/services/User.service';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button, Link, Stack, styled, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMemo } from 'react';

interface ActionHistoryCashAllocationTableCollapsibleComponentProps {
  row: ConversionResponse;
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: grey[100],
  padding: theme.spacing(4)
}));

const StyledLink = styled(Link)(() => ({
  cursor: 'pointer'
}));

export const ActionHistoryCashAllocationTableCollapsibleComponent = (
  props: ActionHistoryCashAllocationTableCollapsibleComponentProps
): JSX.Element => {
  const queryClient = useQueryClient();
  const userInfo = userService.getUser();

  const isUploadingUser = useMemo(
    () => userInfo?.nickname === props.row?.submittedBy?.id,
    [userInfo, props.row]
  );

  const { mutate: downloadDocument } = useMutation(
    ['ActionCenterService.getDocument'],
    (documentId: number) => {
      return ActionCenterService.getDocument(documentId);
    },
    {
      onSuccess: data => {
        InMemoryFileDownloadService.triggerFileDownload(
          data.base64Data,
          data.originalFileName
        );
      }
    }
  );

  const approveUpload = useMutation(
    ['ActionCenterService.approveBulkUploadCashAllocation'],
    (dto: UpdateConversionDto) =>
      ActionCenterService.approveBulkUploadCashAllocation(dto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['ActionCenterService.bulkCashAllocationList']
        });
      }
    }
  );

  return (
    <>
      <StyledGrid container>
        <Grid xs={6}>
          <Stack
            data-testid={`action-history-table-collapsible-action-${props.row.documentId}`}
            spacing={2}>
            <Typography variant='subtitle1'>Details</Typography>
            <TextStack direction='column'>
              <TextStackItem>
                <TextLabel>Download File</TextLabel>
                <TextValue>
                  <Stack>
                    {props.row.fileName}
                    <StyledLink
                      onClick={() =>
                        props.row.documentId
                          ? downloadDocument(props.row.documentId)
                          : null
                      }>
                      <Stack direction='row'>
                        Download
                        <FileDownloadOutlinedIcon fontSize='small' />
                      </Stack>
                    </StyledLink>
                  </Stack>
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Submitted by</TextLabel>
                <TextValue>{props.row.submittedBy.id}</TextValue>
              </TextStackItem>
            </TextStack>
          </Stack>
        </Grid>
        {!isUploadingUser &&
          props.row.status == ConversionStatus.PendingApproval && (
            <Grid xs={6}>
              <Button
                onClick={() =>
                  approveUpload.mutate({
                    documentId: props.row.documentId,
                    id: props.row.id
                  })
                }
                variant='contained'>
                Approve
              </Button>
            </Grid>
          )}
      </StyledGrid>
    </>
  );
};
