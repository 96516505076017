import { PlanService } from '@/services/Plan.service';
import { useQuery } from '@tanstack/react-query';
import type { Payroll } from '@vestwell-api/scala';

export default function usePayrollSetups(sponsorPlanId: number) {
  const payrollSetups = useQuery(
    ['PlanService.getPayrollSetups', sponsorPlanId],
    () => PlanService.getPayrollSetups(sponsorPlanId),
    {
      select: (data: Payroll.GetSetup.ResponseBody[]) => {
        const integratedPayGroups = data?.filter(
          p =>
            (p.integrationMethod180 || p.integrationMethod360) &&
            p.payrollProviderId !== 57
        );
        const isApiPlan =
          !!integratedPayGroups?.length &&
          integratedPayGroups.every(p => p.integrationMethod180 === 3);
        return { isApiPlan, payrollSetups: data };
      }
    }
  );

  return payrollSetups;
}
