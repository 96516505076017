import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import LinearLoading from '@/components/linear-loading';
import { PlanAdminStatus } from '@/models/ops/common/PlanAdminStatus.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ProgramReenrollPage } from '@/routes/ops/investments/ProgramReenrollPage.component';
import InvestmentService from '@/services/Investment.service';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { PlanService } from '@/services/Plan.service';
import { useQuery } from '@tanstack/react-query';

import { useCallback, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

type ProgramReenrollRouteParams = {
  newProgramId: string;
  planId: string;
};

const ProgramReenrollRoute = (): JSX.Element => {
  const { planId, newProgramId } = useParams<ProgramReenrollRouteParams>();

  const hasOnboardingPermission = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_INVESTMENTS_REENROLLMENT_SUBMIT]
  });
  const hasAllPermission = useHasPermissions({
    requires: [
      FeatureLevelPermissions.WRITE_INVESTMENTS_REENROLLMENT_GLOBAL_SUBMIT
    ]
  });

  const planQuery = useQuery(
    ['InvestmentService.getPlanFunds', 'plan', planId],
    () => InvestmentService.getPlanFunds(+planId, +newProgramId),
    { cacheTime: 0, enabled: !!planId }
  );

  const plansInfo = useQuery(
    ['PlanService.getPlanById', planId],
    () => PlanService.getPlanById(planId),
    { enabled: !!planId }
  );

  const hasReEnrollmentPermission = useMemo(() => {
    return (
      hasAllPermission.isAllowed ||
      (hasOnboardingPermission.isAllowed &&
        plansInfo.data?.data?.attributes.status === PlanAdminStatus.Onboarding)
    );
  }, [
    plansInfo.data?.data?.attributes.status,
    hasAllPermission,
    hasOnboardingPermission
  ]);

  const onSubmit = useCallback(
    async data => {
      await ProgramService.postProgramChangePartialReenrollment(
        +newProgramId,
        +planId,
        data
      );

      await planQuery.refetch();
    },
    [planId, newProgramId]
  );

  if (planQuery.isLoading || plansInfo.isLoading) return <LinearLoading />;

  if (hasReEnrollmentPermission) {
    return (
      <ProgramReenrollPage
        currentProgram={planQuery.data?.currentProgram}
        name={planQuery.data.name}
        newProgram={planQuery.data?.newProgram}
        onSubmit={onSubmit}
        planId={planId}
      />
    );
  } else {
    return <Navigate to={{ pathname: `/` }} />;
  }
};

export default ProgramReenrollRoute;
