import {
  CreateParticipantHours,
  CreateRothDetailsDto,
  DocumentDto,
  DocumentMetadataListDto,
  ParticipantHoursCreatedDto,
  ParticipantHoursOfServiceDto,
  ParticipantHoursTotalDto,
  ParticipantHoursToUpdateDto,
  ParticipantInfo,
  RolloverListDto,
  RothDetailsResponseDto,
  TransactionListDto,
  UpdatedHoursOfServiceDto,
  WithdrawalDto,
  WithdrawalFees,
  WithdrawalListDto,
  WithdrawalResponseDto,
  WithdrawalStatus
} from '@/models';
import { AutoRebalanceHistory } from '@/models/AutoRebalanceHistory.model';
import { BankAccountDto } from '@/models/BankAccountsDTO.model';
import { ContributionYTD } from '@/models/ContributionDTO.model';
import { DeferralChangeDto } from '@/models/DeferralChangeDTO.model';
import FraudrankerResult from '@/models/FraudrankerResult.model';
import {
  LoanAddressUpdateRequest,
  LoanDetailsResponseDto,
  LoanExpectedPaymentAttributes,
  LoanReamortizeRequest,
  LoanResponseDto,
  LoanTradeRequestDto,
  LoanTransactionResponseDto,
  LoanValidationResult,
  UpdateLoanDto
} from '@/models/LoanDTO.model';
import { MergeParticipantsDto } from '@/models/MergeParticipantsDto.model';
import {
  OverrideVestedPercentDto,
  ParticipantAccountsDto
} from '@/models/ParticipantAccountsDTO.model';
import {
  Beneficiary,
  UpdateBeneficiariesDTO
} from '@/models/ParticipantBeneficiary.model';
import { ParticipantCensusDataDto } from '@/models/ParticipantCensusDataDTO.model';
import ParticipantDto, {
  ParticipantBeneficiaryResponse,
  ParticipantMeta,
  ParticipantUserInfo,
  RegistrationStatusDto
} from '@/models/ParticipantDTO.model';
import { ParticipantEligibilityDto } from '@/models/ParticipantEligibilityDTO.model';
import { ParticipantExternalId } from '@/models/ParticipantExternalId.model';
import { DefaultHoursOfServiceDto } from '@/models/ParticipantHoursOfServiceDTO.model';
import { ParticipantRegistrationInfo } from '@/models/ParticipantInfo.model';
import { ParticipantLinkedAccount } from '@/models/ParticipantLinkedAccount.model';
import { ParticipantPositionsDto } from '@/models/ParticipantPositionsDTO.model';
import {
  SuspiciousActivityDto,
  UpdateSuspiciousActivityDto
} from '@/models/ParticipantSuspiciousActivity.model';
import {
  FormattedPendingTransaction,
  PendingTransactionDto
} from '@/models/PendingTransactionsDTO';
import { RebalanceHistoryDto } from '@/models/RebalanceDTO.model';
import { RmdBalance, RmdCalculatedAmount } from '@/models/RmdDTO.model';
import {
  FormattedRolloverDto,
  RolloverCreateDto,
  RolloverResponseDto,
  RolloverTradeRequestDto,
  RolloverTransactionDto,
  UpdateRolloverStatusDto
} from '@/models/RolloversDTO.model';
import {
  ServiceNoteDto,
  ServiceNoteSourceDto
} from '@/models/ServiceNotesDTO.model';
import {
  GetSubaWithholdingContext,
  SubaWithholdingDto,
  WithholdingEstimateRequest,
  WithholdingEstimateResponse
} from '@/models/SubaWithholdingDTO.model';
import {
  ContributionTransactionData,
  GroupedTransactionDto
} from '@/models/TransactionDTO.model';
import {
  UpdateDeferralRateAttributes,
  UpdateDeferralRateEmails,
  UpdateDeferralRateOldValues,
  UpdateDeferralRateResponse
} from '@/models/UpdateDeferralRate.model';
import { UpdateParticipantInfoDto } from '@/models/UpdateParticipantInfoDTO.model';
import { UpdateParticipantRegistrationInfo } from '@/models/UpdateParticipantRegistrationInfo.model';
import {
  CustomWithdrawalDetailsDto,
  CustomWithdrawalResponse,
  CustomWithdrawalRothBasisResponse,
  CustomWithdrawalRothCostBasisDto,
  DistributionAccountsDto,
  ForceOutDistributionResponse,
  FormattedWithdrawalDto,
  SubmitForceOutDistributionResponse,
  WithdrawalAddressUpdateRequest,
  WithdrawalDestinationDto,
  WithdrawalFraudRankUpdateRequest,
  WithdrawalSierraRequest,
  WithdrawalTradeRequestDto,
  WithdrawalTransactionDto
} from '@/models/WithdrawalsDTO.model';
import ApiService from '@/services/Api.service';
import formatters from '@/utils/Formatters';
import { Participants } from '@vestwell-api/scala';

import dayjs from 'dayjs';
import {
  ApiResponseDataRebalanceRequestResponse,
  ModelSnapshotResponse,
  ParticipantAddrVerificationResultResponse,
  ParticipantVerificationServiceResultResponse
} from 'scala-sdk';

const ONE_YEAR_IN_HOURS = 8736;

const extractPlanId = (dto: ParticipantDto): number => {
  const planLinks = dto.data.relationships.plan.data;

  if (planLinks.length !== 1) {
    throw new Error(
      `Participant with id ${dto.data.id} had ${planLinks.length} plan relationship links, expected 1`
    );
  }

  const link = planLinks[0];

  if (link.type !== 'plan') {
    throw new Error(
      `Participant with id ${dto.data.id} had plan relationship of type: ${link.type}`
    );
  }

  return link.id;
};

class ParticipantService {
  static async getStatementForParticipant(
    participantId: string | number,
    statementId: string | number
  ): Promise<DocumentDto> {
    return ApiService.getJson(
      `/participant/${participantId}/statements/${statementId}`
    );
  }

  static async getDeferralChanges(
    participantId: string | number
  ): Promise<DeferralChangeDto[]> {
    const dto: DeferralChangeDto[] = (await ApiService.getJson(
      `/participant/${participantId}/deferral/changes/all`
    )) as any;

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async getTransactionsForYear(
    participantId: string | number,
    year: number
  ): Promise<TransactionListDto> {
    const dto = (await ApiService.getJson(
      `participant/${participantId}/transactions`,
      {
        interval: `PT${ONE_YEAR_IN_HOURS}H`,
        startDate: `${year}-01-01`
      }
    )) as TransactionListDto;

    return dto;
  }

  static async getContributionYTD(
    participantId: string | number,
    year: number
  ): Promise<ContributionYTD> {
    const dto = await ApiService.getJson<ContributionYTD>(
      `participant/${participantId}/contributions/ytd`,
      {
        year
      }
    );

    return dto;
  }

  static async getGroupedTransactions(query: {
    categories?: string;
    endDate?: string;
    participantId: string | number;
    startDate?: string;
    tradeStatus?: string;
  }): Promise<GroupedTransactionDto[]> {
    return ApiService.getJson(
      `participant/${query.participantId}/transactions/grouped`,
      query
    );
  }

  static async getTransactionsYears(
    participantId: number
  ): Promise<Participants.GetTransactionsYears.ResponseBody> {
    return ApiService.getJson(
      `participant/${participantId}/transactions/years`
    );
  }

  static async getContributionTransactions(
    participantId: string | number,
    ucid: string
  ): Promise<ContributionTransactionData[]> {
    const dto = (await ApiService.getJson(
      `participant/${participantId}/transactions/contributions`,
      {
        ucid
      }
    )) as TransactionListDto;

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    const viewData = dto.data.map(transactionDto => {
      return {
        amount: transactionDto.attributes.dollars,
        effectiveDate: transactionDto.attributes.effectiveDate,
        fundingSource: transactionDto.attributes.fundingSource,
        id: transactionDto.id,
        status: transactionDto.attributes.status,
        ticker: transactionDto.attributes.security.ticker,
        tracerId: transactionDto.attributes.tracerId,
        tradeDate: transactionDto.attributes.tradeDate,
        transactionSubtype: transactionDto.attributes.transactionSubtype
      } as ContributionTransactionData;
    });

    return viewData;
  }

  static async getStatementsForParticipant(
    participantId: string | number
  ): Promise<DocumentMetadataListDto> {
    const dto = await ApiService.getJson<DocumentMetadataListDto>(
      `participant/${participantId}/statements`
    );

    return dto;
  }

  static async getPendingTransactions(
    participantId: string | number
  ): Promise<FormattedPendingTransaction[]> {
    const dto = (await ApiService.getJson(
      `participant/${participantId}/transactions/pending`
    )) as PendingTransactionDto;

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto.data
      .map(i => i.attributes)
      .filter(i => i.amount > 0) as FormattedPendingTransaction[];
  }

  static async getParticipantAccounts(
    participantId: string | number
  ): Promise<ParticipantAccountsDto> {
    const dto: ParticipantAccountsDto = await ApiService.getJson(
      `/participant/${participantId}/accounts`
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async updateAccountFreezeVesting(
    participantId: number,
    accountId: number
  ): Promise<any> {
    return ApiService.patchJson<any, any>(
      `/participant/${participantId}/accounts/${accountId}/freeze-vesting`,
      {}
    );
  }

  static async overrideVestedPercent(
    participantId: number,
    accountId: number,
    data: OverrideVestedPercentDto
  ): Promise<any> {
    return ApiService.patchJson<any, any>(
      `/participant/${participantId}/accounts/${accountId}/override-vested-percents`,
      data
    );
  }

  static async recalculateVesting(participantId: number): Promise<any> {
    return ApiService.postJson<any, any>(
      `/participant/${participantId}/vesting/recalculate`,
      {}
    );
  }

  static async getParticipantExternalId(
    participantId: string | number
  ): Promise<ParticipantExternalId> {
    const dto: ParticipantExternalId = await ApiService.getJson(
      `/participant/externalMapping/byInternal/${participantId}`
    );

    if (!dto) {
      throw new Error(`invalid JSON received from backend `);
    }

    return dto;
  }

  static async getParticipantCensusTableData(
    participantId: string | number,
    planId: number
  ): Promise<ParticipantCensusDataDto> {
    const dto: ParticipantCensusDataDto = await ApiService.getJson(
      `/participant/${participantId}/sponsorplan/${planId}`
    );

    if (!dto) {
      throw new Error(`invalid JSON received from backend `);
    }

    return dto;
  }

  static async getParticipantHoursOfService(
    participantId: number,
    planId: number | undefined,
    pageNumber: number,
    pageSize: number,
    startDate: string,
    endDate: string
  ): Promise<ParticipantHoursOfServiceDto> {
    if (!planId || !startDate || !endDate) {
      return DefaultHoursOfServiceDto;
    }

    const dto: ParticipantHoursOfServiceDto = await ApiService.getJson(
      `/participant/${participantId}/plan/${planId}/hours`,
      {
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
        pageNumber,
        pageSize,
        startDate: dayjs(startDate).format('YYYY-MM-DD')
      }
    );

    if (!dto) {
      throw new Error('invalid JSON received from backend');
    }

    if (!dto.data.length) {
      return DefaultHoursOfServiceDto;
    }

    return dto;
  }

  static async getParticipantTotalHours(
    participantId: number,
    planId: number | undefined,
    startDate: string,
    endDate: string
  ): Promise<number> {
    if (!planId || !startDate || !endDate) {
      return 0;
    }

    const dto: ParticipantHoursTotalDto = await ApiService.getJson(
      `/participant/${participantId}/plan/${planId}/participantHours`,
      {
        endDate: dayjs(endDate).format('MM/DD/YYYY'),
        startDate: dayjs(startDate).format('MM/DD/YYYY')
      }
    );

    if (!dto) {
      throw new Error('invalid JSON received from backend');
    }

    if (!dto.hoursPerParticipant[0].total) {
      return 0;
    }

    return dto.hoursPerParticipant[0].total;
  }

  static async updateParticipantHoursOfService(
    participantId: number,
    planId: number | undefined,
    data: ParticipantHoursToUpdateDto
  ): Promise<UpdatedHoursOfServiceDto> {
    return ApiService.patchJson<
      ParticipantHoursToUpdateDto,
      UpdatedHoursOfServiceDto
    >(`/participant/${participantId}/plan/${planId}/hours`, data);
  }

  static async createParticipantHoursOfService(
    participantId: number,
    planId: number | undefined,
    data: CreateParticipantHours
  ): Promise<ParticipantHoursCreatedDto> {
    return ApiService.postJson<
      CreateParticipantHours,
      ParticipantHoursCreatedDto
    >(`/participant/${participantId}/plan/${planId}/hours`, data);
  }

  static async getRegistrationStatus(
    participantId: number
  ): Promise<ParticipantRegistrationInfo> {
    return ApiService.getJson(
      `/participant/${participantId}/registrationStatus`
    );
  }

  static async updateRegistrationStatus(
    participantId: number,
    body: UpdateParticipantRegistrationInfo
  ): Promise<RegistrationStatusDto> {
    return ApiService.putJson<
      UpdateParticipantRegistrationInfo,
      RegistrationStatusDto
    >(`/participant/${participantId}/registrationStatus`, body);
  }

  static async updateIdentificationInfo(
    participantId: number,
    planId: number,
    body: UpdateParticipantInfoDto,
    hasSSNPermission: boolean
  ): Promise<ParticipantDto> {
    return hasSSNPermission
      ? ApiService.patchJson<UpdateParticipantInfoDto, ParticipantDto>(
          `/participant/${participantId}/plan/ssn/${planId}`,
          body
        )
      : ApiService.patchJson<UpdateParticipantInfoDto, ParticipantDto>(
          `/participant/${participantId}/plan/${planId}`,
          body
        );
  }

  static async updateContactInfo(
    participantId: number,
    planId: number,
    body: UpdateParticipantInfoDto
  ): Promise<ParticipantDto> {
    return ApiService.patchJson<UpdateParticipantInfoDto, ParticipantDto>(
      `/participant/${participantId}/plan/contact/${planId}`,
      body
    );
  }

  static async updateEmploymentDates(
    participantId: number,
    planId: number,
    body: UpdateParticipantInfoDto
  ): Promise<ParticipantDto> {
    return ApiService.patchJson<UpdateParticipantInfoDto, ParticipantDto>(
      `/participant/${participantId}/plan/dates/${planId}`,
      body
    );
  }

  static async updateSurpasAccount(
    participantId: number,
    planId: number,
    body: UpdateParticipantInfoDto
  ): Promise<ParticipantDto> {
    return ApiService.patchJson<UpdateParticipantInfoDto, ParticipantDto>(
      `/participant/${participantId}/plan/surpas/${planId}`,
      body
    );
  }

  static async getParticipantById(
    participantId: string | number
  ): Promise<ParticipantInfo> {
    const dto: ParticipantDto = (await ApiService.getJson(
      `/participant/${participantId}`
    )) as any;

    if (!dto || !dto.data || !dto.data.attributes) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    const participant = {
      accountBalance: {
        employeeBalance: {
          afterTax:
            dto.data.attributes.accountBalance?.employeeBalance?.afterTax,
          preTax: dto.data.attributes.accountBalance?.employeeBalance?.preTax,
          roth: dto.data.attributes.accountBalance?.employeeBalance?.roth,
          total: dto.data.attributes.accountBalance?.employeeBalance?.total
        },
        loanBalance: {
          total: dto.data.attributes.accountBalance?.loanBalance?.total
        },
        rolloverBalance: {
          preTax: dto.data.attributes.accountBalance?.rolloverBalance?.preTax,
          roth: dto.data.attributes.accountBalance?.rolloverBalance?.roth,
          total: dto.data.attributes.accountBalance?.rolloverBalance?.total
        },
        totalBalance: dto.data.attributes.accountBalance?.totalBalance,
        updatedOn: dto.data.attributes.accountBalance?.updatedOn
      },
      address: dto.data.attributes.contactInfo?.address,
      addressUpdatedAt: dto.data.attributes.addressUpdatedAt,
      beneficiary: dto.data.attributes.beneficiary,
      birthDate: dto.data.attributes.birthDate,
      contributionsIrsLimit: dto.data.attributes.contributions?.irsLimit,
      createdAt: dto.data.attributes.createdAt,
      deferralElections: {
        afterTaxAmt: dto.data.attributes.deferralElections?.afterTaxAmt,
        afterTaxType: dto.data.attributes.deferralElections?.afterTaxType,
        preTaxAmt: dto.data.attributes.deferralElections?.preTaxAmt,
        preTaxType: dto.data.attributes.deferralElections?.preTaxType,
        rothAmt: dto.data.attributes.deferralElections?.rothAmt,
        rothType: dto.data.attributes.deferralElections?.rothType
      },
      email: dto.data.attributes.contactInfo?.email,
      emailUpdatedAt: dto.data.attributes.emailUpdatedAt,
      employeeClass: dto.data?.attributes?.employeeClass,
      employeeStatus: dto.data.attributes.employeeStatus,
      employmentStatus: dto.data.attributes.employmentStatus,
      esaGroupId: dto.data.attributes.esaGroupId,
      externalId: dto.data.attributes.externalId,
      firstName: dto.data.attributes.firstName,
      hireDate: dto.data.attributes.hireDate,
      investmentElection: dto.data.attributes.investmentElection,
      isDeceased: dto.data.attributes.isDeceased,
      isDisabled: dto.data.attributes.isDisabled,
      isExcludedEmployee: dto.data?.attributes?.isExcludedEmployee,
      lastName: dto.data.attributes.lastName,
      mailingAddress: dto.data.attributes.contactInfo?.mailingAddress,
      mfaLastDisabled: dto.data.attributes.mfaLastDisabled,
      middleName: dto.data.attributes.middleName,
      nameUpdatedAt: dto.data.attributes.nameUpdatedAt,
      participantId: dto.data.id,
      personalEmail: dto.data.attributes.contactInfo?.personalEmail,
      phoneNumber: dto.data.attributes.contactInfo?.phoneNumber,
      physicalSameAsMailing:
        dto.data.attributes.contactInfo?.physicalSameAsMailing,
      registeredOn: dto.data.attributes.registeredOn,
      relationships: dto.data.relationships,
      sponsorPlanId: extractPlanId(dto),
      ssn: dto.data.attributes.ssn,
      stateIraAccountStatus: dto.data.attributes.stateIraAccountStatus,
      stateIraCipStatus: dto.data.attributes.stateIraCipStatus,
      stateIraPerEmployerStatus: dto.data.attributes.stateIraPerEmployerStatus,
      updatedAt: dto.data.attributes.updatedAt,
      userEmail: dto.data.attributes.contactInfo?.userEmail,
      vestingAmounts: dto.data.attributes.vestingAmounts,
      workEmail: dto.data.attributes.contactInfo?.workEmail
    } as unknown as ParticipantInfo;

    if (dto.data.attributes.eligibility) {
      return {
        ...participant,
        eligibilityRequirements: {
          isAgeRequirementMet:
            dto.data.attributes.eligibility.ageRequirementDetail?.isMet,
          isServiceRequirementMet:
            dto.data.attributes.eligibility.serviceRequirementDetail?.isMet
        },
        eligibilityStatus: dto.data.attributes.eligibility.status ?? '--',
        entryDate: dto.data.attributes.eligibility.entryDate ?? '--',
        isLtpt: dto.data?.attributes?.eligibility?.isLtpt,
        isOverride: dto.data.attributes.eligibility.isOverride,
        overrideNotes: dto.data?.attributes?.eligibility?.overrideNotes
          ? dto.data?.attributes?.eligibility?.overrideNotes
          : ''
      };
    }
    return participant;
  }

  static async removeParticipantFromPlan(
    participantId: number,
    planId: number,
    dryRun = false
  ): Promise<{ data?: string; errors?: string[] }> {
    const result = await ApiService.deleteJson(
      `/participant/${participantId}/remove/plan/${planId}`,
      dryRun ? { dryRun } : {}
    );
    return result;
  }

  static async overrideEligibilityStatus(
    participantId: number,
    sponsorPlanId: number,
    data: {
      eligibility: {
        status: string;
        entryDate?: string;
        isOverride: boolean;
        overrideNotes: string;
      };
    }
  ): Promise<{ message: string }> {
    const response: { message: string } = await ApiService.patchJson(
      `/participant/${participantId}/override/eligibility/sponsorPlan/${sponsorPlanId}`,
      data
    );

    return response;
  }

  static async refreshParticipantEligibility(
    participantId: number,
    sponsorPlan: number
  ): Promise<ParticipantEligibilityDto> {
    return ApiService.postJson(
      `/participant/${participantId}/eligibility/sponsorPlan/${sponsorPlan}`,
      {}
    );
  }

  static async getWithdrawal(
    participantId: number,
    withdrawalId: number
  ): Promise<WithdrawalDto> {
    const withdrawalDetails: WithdrawalDto = await ApiService.getJson(
      `/participant/${participantId}/withdrawals/${withdrawalId}`
    );

    if (!withdrawalDetails) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return withdrawalDetails;
  }

  static async getWithdrawals(
    participantId: number
  ): Promise<FormattedWithdrawalDto[]> {
    const dto: WithdrawalListDto = await ApiService.getJson(
      `/participant/${participantId}/withdrawals`
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto?.data?.map(rawWithdrawal => {
      return {
        amount: formatters.formatDollars(
          rawWithdrawal.attributes.context.withdrawalDerivedAmounts
            .withdrawalAmount
        ),
        amountValue:
          rawWithdrawal.attributes.context.withdrawalDerivedAmounts
            .withdrawalAmount,
        dateRequested: formatters.formatFromIsoDateCustom(
          rawWithdrawal.attributes.createdAt,
          'YYYY-MM-DD'
        ),
        id: +rawWithdrawal.id,
        participantId: rawWithdrawal.attributes.participantId,
        reason: formatters.formatWithdrawalReason(
          rawWithdrawal.attributes.withdrawalReason
        ),
        status: rawWithdrawal.attributes.status
      };
    });
  }

  static async getWithdrawalTransactions(
    participantId: number,
    withdrawalId: number
  ): Promise<WithdrawalTransactionDto[]> {
    const dto: WithdrawalTransactionDto[] = await ApiService.getJson(
      `/participant/${participantId}/withdrawals/${withdrawalId}/transactions`
    );

    return dto;
  }

  static async getWithdrawalTradeRequests(
    participantId: number,
    withdrawalId: number
  ): Promise<WithdrawalTradeRequestDto[]> {
    const dto: WithdrawalTradeRequestDto[] = await ApiService.getJson(
      `/participant/${participantId}/withdrawals/${withdrawalId}/tradeRequests`
    );

    return dto;
  }

  static async updateWithdrawalStatus(
    participantId: number,
    withdrawalId: number,
    status: WithdrawalStatus,
    forceSkipLocationValidation: boolean,
    sendEmail: boolean
  ): Promise<WithdrawalResponseDto> {
    const dto: WithdrawalResponseDto = await ApiService.patchJson(
      `/participant/${participantId}/withdrawals/${withdrawalId}`,
      {
        forceSkipLocationValidation,
        sendEmail,
        status
      }
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async completeWithdrawalFraudCheck(
    withdrawalId: number
  ): Promise<WithdrawalResponseDto> {
    const dto: WithdrawalResponseDto = await ApiService.patchJson(
      `/participant/withdrawals/${withdrawalId}/fraudCheckComplete`,
      {}
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for withdrawalId=${withdrawalId}`
      );
    }

    return dto;
  }

  static async updateWithdrawalFees(
    participantId: number,
    withdrawalId: number,
    fees: WithdrawalFees
  ): Promise<WithdrawalResponseDto> {
    const dto: WithdrawalResponseDto = await ApiService.patchJson(
      `/participant/${participantId}/withdrawals/${withdrawalId}/fees`,
      { fees }
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async updateWithdrawalNotes(
    participantId: number,
    withdrawalId: number,
    notes: string
  ): Promise<WithdrawalResponseDto> {
    const dto: WithdrawalResponseDto = await ApiService.patchJson(
      `/participant/${participantId}/withdrawals/${withdrawalId}/notes`,
      { notes }
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async updateFraudNotes(
    withdrawalId: number,
    notes: string
  ): Promise<string> {
    return ApiService.patchJson('/participant/withdrawals/fraudNotes', {
      id: withdrawalId,
      notes
    });
  }

  static async updateFraudRank(
    data: WithdrawalFraudRankUpdateRequest
  ): Promise<string> {
    return ApiService.putJson(
      '/participant/withdrawals/update-fraud-data',
      data
    );
  }

  static async scheduleRequestToLT(
    participantId: number,
    withdrawalId: number
  ): Promise<WithdrawalResponseDto> {
    const dto: WithdrawalResponseDto = await ApiService.postJson(
      `/participant/${participantId}/withdrawals/${withdrawalId}/schedule`,
      {}
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from /lt/withdrawal/update/${withdrawalId}`
      );
    }

    return dto;
  }

  static async getRollovers(
    participantId: number
  ): Promise<FormattedRolloverDto[]> {
    const dto: RolloverListDto = await ApiService.getJson(
      `/participant/${participantId}/rollovers`
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto?.data?.map(rawRollover => {
      return {
        accountProvider: rawRollover.attributes.accountProvider,
        accountType: rawRollover.attributes.accountType,
        createdAt: formatters.formatFromIsoDateCustom(
          rawRollover.attributes.createdAt,
          'YYYY-MM-DD'
        ),
        id: +rawRollover.id,
        participantId: rawRollover.attributes.participantId,
        pretaxAmount: formatters.formatDollars(
          rawRollover.attributes.preTaxAmount
        ),
        rothAmount: formatters.formatDollars(rawRollover.attributes.rothAmount),
        status: rawRollover.attributes.status,
        updatedAt: formatters.formatFromIsoDateCustom(
          rawRollover.attributes.updatedAt,
          'YYYY-MM-DD'
        )
      };
    });
  }

  static async getRolloverTransactions(
    participantId: number,
    rolloverId: number
  ): Promise<RolloverTransactionDto[]> {
    const dto: RolloverTransactionDto[] = await ApiService.getJson(
      `/participant/${participantId}/rollover/${rolloverId}/transactions`
    );

    return dto;
  }

  static async getRolloverTradeRequests(
    participantId: number,
    rolloverId: number
  ): Promise<RolloverTradeRequestDto[]> {
    const dto: RolloverTradeRequestDto[] = await ApiService.getJson(
      `/participant/${participantId}/rollover/${rolloverId}/tradeRequests`
    );

    return dto;
  }

  static async updateParticipantEmployment(
    participantId: number,
    planId: number,
    body: UpdateParticipantInfoDto
  ): Promise<ParticipantDto> {
    return ApiService.patchJson<UpdateParticipantInfoDto, ParticipantDto>(
      `/participant/${participantId}/plan/${planId}/employment`,
      body
    );
  }

  static async updateDeferralRate(
    participantId: number,
    planId: number,
    attributes: UpdateDeferralRateAttributes,
    emails: UpdateDeferralRateEmails,
    oldValues: UpdateDeferralRateOldValues
  ): Promise<UpdateDeferralRateResponse> {
    const response = (await ApiService.putJson(
      `/participant/${participantId}/deferral`,
      { attributes, emails, oldValues, planId }
    )) as any;
    return response;
  }

  static async getRollover(
    participantId: number,
    rolloverId: number
  ): Promise<RolloverResponseDto> {
    const dto: RolloverResponseDto = await ApiService.getJson(
      `/participant/${participantId}/rollover/${rolloverId}`
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async updateRolloverStatus(
    participantId?: number,
    rolloverId?: number,
    updateRolloverStatusDto?: UpdateRolloverStatusDto
  ): Promise<RolloverResponseDto> {
    const dto: RolloverResponseDto = await ApiService.patchJson(
      `/participant/${participantId}/rollover/${rolloverId}`,
      updateRolloverStatusDto
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async getBankAccount(
    participantId: number,
    bankAccountId: number
  ): Promise<BankAccountDto> {
    if (bankAccountId < 1) {
      throw new Error(`invalid bankAccountId provided`);
    }

    const dto: BankAccountDto = await ApiService.getJson(
      `/participant/${participantId}/bank-accounts/${bankAccountId}`
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId} /participants/:participantId/bank-accounts`
      );
    }

    return dto;
  }

  static async rebalanceHoldings(
    participantId: number
  ): Promise<ApiResponseDataRebalanceRequestResponse> {
    const dto: ApiResponseDataRebalanceRequestResponse =
      await ApiService.postJson(`/participant/rebalance`, {
        participantIds: [+participantId]
      });

    if (!dto) {
      throw new Error(
        `Invalid JSON received from backend for rebalancing participantId=${participantId} /participants/rebalance`
      );
    }

    return dto;
  }

  static async rebalanceHoldingsForModel(
    investmentOptionId: number,
    type: string
  ): Promise<ApiResponseDataRebalanceRequestResponse> {
    const dto: ApiResponseDataRebalanceRequestResponse =
      type == 'risk'
        ? await ApiService.postJson(`/participant/rebalanceRiskSeries`, {
            riskSeriesIds: [+investmentOptionId]
          })
        : await ApiService.postJson(`/participant/rebalanceTargetSeries`, {
            targetSeriesIds: [+investmentOptionId]
          });
    if (!dto) {
      throw new Error(
        `Invalid JSON received from backend for rebalancing investmentOptionIds=${investmentOptionId} /participants/rebalanceModel`
      );
    }

    return dto;
  }

  static async getRebalanceHistory(
    participantId: number
  ): Promise<RebalanceHistoryDto[]> {
    const dto = (await ApiService.getJson(
      `/participant/${participantId}/rebalance/history`,
      {}
    )) as RebalanceHistoryDto[];

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async getLoans(participantId: number): Promise<LoanResponseDto> {
    const dto: LoanResponseDto = await ApiService.getJson(
      `/participant/${participantId}/loans`
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async getLoan(
    participantId: number,
    loanId: number,
    withFraudInfo: boolean
  ): Promise<LoanDetailsResponseDto> {
    const dto: LoanDetailsResponseDto = await ApiService.getJson(
      `/participant/${participantId}/loans/${loanId}`,
      {
        withFraudInfo
      }
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async getLoanTransactions(
    participantId: number,
    loanId: number
  ): Promise<LoanTransactionResponseDto> {
    const dto: LoanTransactionResponseDto = await ApiService.getJson(
      `/participant/${participantId}/loans/${loanId}/transactions`
    );

    return dto;
  }

  static async getLoanTradeRequests(
    participantId: number,
    loanId: number
  ): Promise<LoanTradeRequestDto> {
    const dto: LoanTradeRequestDto = await ApiService.getJson(
      `/participant/${participantId}/loans/${loanId}/tradeRequests`
    );

    return dto;
  }

  static async updateLoan(
    participantId: number,
    loanId: number,
    dto: UpdateLoanDto
  ): Promise<LoanDetailsResponseDto> {
    const response: LoanDetailsResponseDto = await ApiService.patchJson(
      `/participant/${participantId}/loans/${loanId}`,
      dto
    );

    if (!response) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return response;
  }

  static async validateLoan(loanId: number): Promise<LoanValidationResult> {
    const result: LoanValidationResult = await ApiService.getJson(
      `/participant/loans/${loanId}/validate`
    );

    if (!result) {
      throw new Error(
        `invalid JSON received from backend for loanId=${loanId}`
      );
    }

    return result;
  }

  static async createUserParticipantRelationship(
    userId: number | string,
    body: { entityId: number }
  ): Promise<{ rowsAdded: number }> {
    const dto = await ApiService.postJson<
      { entityId: number },
      { rowsAdded: number }
    >(`/users/${userId}/relationships/participants`, body);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for userId=${userId}`
      );
    }

    return dto;
  }

  static async deleteUserParticipantRelationship(
    userId: number | string,
    body: { entityId: number }
  ): Promise<{ rowsRemoved: number; exceptions: [] }> {
    const dto = await ApiService.patchJson<
      { entityId: number },
      { rowsRemoved: number; exceptions: [] }
    >(`/users/${userId}/relationships/participants`, body);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for userId=${userId}`
      );
    }

    return dto;
  }

  static async getInfoById(
    participantId: string | number
  ): Promise<ParticipantMeta> {
    return ApiService.getJson<ParticipantMeta>(
      `/participant/${participantId}/info`
    );
  }

  static async getLoanFraudrank(loanId: number): Promise<FraudrankerResult> {
    return ApiService.getJson<FraudrankerResult>(
      `/participant/fraudranker/rankLoan/${loanId}`
    );
  }

  static async getWithdrawalFraudrank(
    withdrawalId: number
  ): Promise<FraudrankerResult> {
    return ApiService.getJson<FraudrankerResult>(
      `/participant/fraudranker/rankWithdrawal/${withdrawalId}`
    );
  }

  static async rolloverInvestment(
    rolloverId?: number,
    transactionId?: string,
    amount?: number
  ): Promise<unknown> {
    return ApiService.postJson(`/participant/rollover/investment`, {
      amount,
      rolloverId,
      transactionId
    });
  }

  static async getRothDetails(
    participantId: number
  ): Promise<RothDetailsResponseDto> {
    const dto: RothDetailsResponseDto = await ApiService.getJson(
      `/participant/${participantId}/rothDetails`
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async createRothDetails(
    participantId: number,
    body: CreateRothDetailsDto
  ): Promise<RothDetailsResponseDto> {
    const dto = await ApiService.postJson<
      CreateRothDetailsDto,
      RothDetailsResponseDto
    >(`/participant/${participantId}/rothDetails`, body);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }
    return dto;
  }

  static async updateRothDetails(
    participantId: number,
    body: CreateRothDetailsDto
  ): Promise<RothDetailsResponseDto> {
    const dto = await ApiService.putJson<
      CreateRothDetailsDto,
      RothDetailsResponseDto
    >(`/participant/${participantId}/rothDetails`, body);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }
    return dto;
  }

  static async deleteRothDetails(
    participantId: number,
    detailsId: number
  ): Promise<any> {
    const result = await ApiService.deleteJson(
      `/participant/${participantId}/rothDetails/${detailsId}`
    );
    return result;
  }

  static async getPositions(
    participantId: number,
    asOfDate?: string
  ): Promise<ParticipantPositionsDto> {
    const dto: ParticipantPositionsDto = await ApiService.getJson(
      `/participant/${participantId}/positions`,
      { asOfDate }
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async getAutoRebalanceHistory(
    participantId: number
  ): Promise<AutoRebalanceHistory[]> {
    const dto = await ApiService.getJson<AutoRebalanceHistory[]>(
      `/participant/${participantId}/auto-rebalance-history`
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for participantId=${participantId}`
      );
    }

    return dto;
  }

  static async getDistributionAccounts(
    participantId: number
  ): Promise<DistributionAccountsDto> {
    return ApiService.getJson('/participant/withdrawal/custom/accounts', {
      participantId
    });
  }

  static async getParticipantBeneficiaries(
    participantId: number
  ): Promise<ParticipantBeneficiaryResponse> {
    return ApiService.getJson(`/participant/${participantId}/beneficiaries`);
  }

  static async getRothBasisAmounts(
    dto: CustomWithdrawalRothCostBasisDto
  ): Promise<CustomWithdrawalRothBasisResponse> {
    return ApiService.postJson(
      `/participant/withdrawal/custom/roth-basis-amounts`,
      dto
    );
  }

  static async createAndSubmitCustomWithdrawalRequest(
    data: CustomWithdrawalDetailsDto,
    submit: boolean
  ): Promise<CustomWithdrawalResponse> {
    return ApiService.postJson(
      `/participant/withdrawal/custom?submit=${submit}`,
      data
    );
  }

  static async updateCustomWithdrawal(
    data: CustomWithdrawalDetailsDto,
    withdrawalId?: number
  ): Promise<CustomWithdrawalResponse> {
    return ApiService.patchJson(
      `/participant/withdrawal/${withdrawalId}/custom`,
      data
    );
  }

  static async submitCustomWithdrawal(
    data: CustomWithdrawalDetailsDto,
    withdrawalId?: number
  ): Promise<CustomWithdrawalResponse> {
    if (withdrawalId) {
      return ApiService.postJson(
        `/participant/withdrawal/${withdrawalId}/custom/submit`,
        data
      );
    } else {
      return this.createAndSubmitCustomWithdrawalRequest(data, true);
    }
  }

  static async validateCustomWithdrawal(
    data: CustomWithdrawalDetailsDto
  ): Promise<CustomWithdrawalResponse> {
    return ApiService.postJson(`/participant/withdrawal/custom/validate`, data);
  }

  static async checkIfWithdrawalTicketExist(
    issueKey: string
  ): Promise<boolean> {
    const dto = await ApiService.getJson<{ data: boolean }>(
      `/participant/withdrawal/custom/ticket-exist/${issueKey}`
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for ticket=${issueKey}`
      );
    }

    return dto.data;
  }

  static async getSubaWithholding(
    data: GetSubaWithholdingContext,
    participantId?: number
  ): Promise<SubaWithholdingDto> {
    return ApiService.postJson(
      `/participant/${participantId}/withholding`,
      data
    );
  }

  static async getWithdrawalDestinations(
    participantId: string,
    withdrawalId: number
  ): Promise<WithdrawalDestinationDto> {
    return ApiService.getJson('/participant/withdrawal/destinations', {
      participantId,
      withdrawalId
    });
  }

  static async getServiceNoteSources(
    participantId: string
  ): Promise<ServiceNoteSourceDto> {
    return ApiService.getJson(
      `/participant/${participantId}/service-note-sources`
    );
  }

  static async getServiceNote(
    participantId: string,
    serviceNoteSourceId: string
  ): Promise<ServiceNoteDto> {
    return ApiService.getJson(`/participant/${participantId}/service-note`, {
      serviceNoteSourceId
    });
  }

  static async createServiceNote(
    participantId: string,
    notes: string,
    serviceNoteSourceId: number
  ): Promise<ServiceNoteDto> {
    return ApiService.postJson(`/participant/${participantId}/service-note`, {
      notes,
      participantId,
      serviceNoteSourceId
    });
  }

  static async updateServiceNote(
    id: number,
    participantId: string,
    notes: string,
    serviceNoteSourceId: number
  ): Promise<ServiceNoteDto> {
    return ApiService.putJson(`/participant/${participantId}/service-note`, {
      id,
      notes,
      participantId,
      serviceNoteSourceId
    });
  }

  static async getWithdrawalSierraRequest(
    withdrawalId: number
  ): Promise<WithdrawalSierraRequest> {
    return ApiService.getJson(
      `/participant/withdrawal/${withdrawalId}/sierra-request`
    );
  }

  static sendWelcomeEmail(
    id: number,
    sponsorPlanId: string | number
  ): Promise<unknown> {
    return ApiService.postJson(`/participant/${id}/send/welcome-email`, {
      sponsorPlanId
    });
  }

  static async getRMDBalance(
    participantId: number,
    year: number
  ): Promise<RmdBalance> {
    return ApiService.getJson(
      '/participant/required-min-distribution/balance',
      { participantId, year }
    );
  }

  static async getRMDCalculatedAmount(
    participantId: number,
    year: number,
    birthDate: string,
    accountOwner: boolean,
    married?: boolean,
    spouseSoleBeneficiary?: boolean,
    spouseAge?: number
  ): Promise<RmdCalculatedAmount> {
    return ApiService.getJson('/participant/required-min-distribution/amount', {
      accountOwner,
      birthDate,
      married,
      participantId,
      spouseAge,
      spouseSoleBeneficiary,
      year
    });
  }

  static removeDistributionHold(participantId: number): Promise<unknown> {
    return ApiService.postJson(
      `/participant/${participantId}/remove-distribution-hold`,
      {}
    );
  }

  static async upsertForceOutDistribution(
    participantIds: number[],
    reason: string,
    version: number,
    id?: number
  ): Promise<SubmitForceOutDistributionResponse> {
    const dto: SubmitForceOutDistributionResponse = await ApiService.postJson(
      `/participant/withdrawals/force-out-distribution`,
      {
        id,
        participantIds: participantIds,
        reason: reason,
        version
      }
    );

    if (!dto) {
      throw new Error(`Invalid JSON received from backend`);
    }

    return dto;
  }

  static async getForceOutRequest(): Promise<ForceOutDistributionResponse> {
    return ApiService.getJson(
      `/participant/withdrawals/force-out-distribution`
    );
  }

  static async submitForceOutDistribution(
    version: number,
    id?: number
  ): Promise<SubmitForceOutDistributionResponse> {
    const dto: SubmitForceOutDistributionResponse = await ApiService.postJson(
      `/participant/withdrawals/force-out-distribution/submit?id=${id}&version=${version}`,
      {}
    );

    if (!dto) {
      throw new Error(`Invalid JSON received from backend`);
    }

    return dto;
  }

  static getSuspiciousActivity(
    participantId: number
  ): Promise<SuspiciousActivityDto> {
    return ApiService.getJson(`/participant/suspicious-activity`, {
      id: participantId
    });
  }

  static putSuspiciousActivity(
    participantId: number,
    suspicious: boolean
  ): Promise<UpdateSuspiciousActivityDto> {
    return ApiService.putJson(`/participant/suspicious-activity`, {
      id: participantId,
      suspicious: suspicious
    });
  }

  static getParticipantUserList(params: { participantId: number }) {
    return ApiService.getJson<ParticipantUserInfo[]>(
      `/participant/${params.participantId}/users`
    );
  }

  static mergeParticipants(
    data: MergeParticipantsDto
  ): Promise<{ data?: unknown; errors: string[] }> {
    return ApiService.postJson('/participant/merge', data);
  }

  static async getTotalWithholding(
    data: WithholdingEstimateRequest
  ): Promise<WithholdingEstimateResponse> {
    return ApiService.postJson(`/participant/withholding/total-estimate`, data);
  }

  static putWithdrawalAddress(
    data: WithdrawalAddressUpdateRequest
  ): Promise<unknown> {
    return ApiService.putJson('/participant/withdrawals/update-address', data);
  }

  static postParticipantRollover(data: RolloverCreateDto): Promise<unknown> {
    return ApiService.postJson('/participant/rollovers/request', data);
  }

  static putLoanAddress(data: LoanAddressUpdateRequest): Promise<unknown> {
    return ApiService.putJson('/participant/loans/update-address', data);
  }

  static getBeneficiaries(
    participantId: number
  ): Promise<{ data: Beneficiary[] }> {
    return ApiService.getJson(`/participant/${participantId}/beneficiaries`);
  }

  static updateBeneficiaries(
    participantId: number,
    beneficiaries: UpdateBeneficiariesDTO
  ): Promise<{ data: Beneficiary[] }> {
    return ApiService.putJson(`/participant/${participantId}/beneficiaries`, {
      data: beneficiaries
    });
  }

  static async getCalculatedEsaEmployerContributions(
    params: {
      participantId: number;
    },
    query: {
      employeeGroupId?: number;
      employeeGroupName?: string;
      employeeEsaContribution: number;
      sponsorPlanId: number;
    }
  ): Promise<any> {
    return ApiService.getJson(
      `/participant/${params.participantId}/calculate-employer-contributions`,
      query
    );
  }

  static async getAddressVerification(
    participantId: number
  ): Promise<ParticipantVerificationServiceResultResponse> {
    return ApiService.getJson('/participant/address/verification', {
      participantId
    });
  }

  static async postVerifyAddress(
    participantIds: number[]
  ): Promise<ParticipantAddrVerificationResultResponse> {
    return ApiService.postJson(
      `/participant/address/verification`,
      participantIds
    );
  }

  static async postReamortizeLoan(
    loanId: number,
    body: LoanReamortizeRequest
  ): Promise<unknown> {
    return ApiService.postJson(`/participant/loans/${loanId}/reamortize`, body);
  }

  static async getReamortizationLoanPreviewPayments(
    loanId: number,
    params: LoanReamortizeRequest
  ): Promise<LoanExpectedPaymentAttributes[]> {
    return ApiService.getJson(
      `/participant/loans/${loanId}/reamortizationPayments`,
      params
    );
  }

  static async getLinkedAccounts(
    participantId: number
  ): Promise<ParticipantLinkedAccount[]> {
    return ApiService.getJson(`/participant/${participantId}/linked-accounts`);
  }

  static async getModelsHistory(
    participantId: number,
    asOf?: string
  ): Promise<ModelSnapshotResponse[]> {
    return ApiService.getJson(`/participant/${participantId}/models/history`, {
      asOf
    });
  }
}

export default ParticipantService;
