import { Alert } from '@mui/material';

import { FC } from 'react';

interface HardcodedBannerProps {
  sponsorPlanId: number;
}

export const HardcodedBanner: FC<HardcodedBannerProps> = ({
  sponsorPlanId
}) => {
  return (
    <>
      {[504713].includes(sponsorPlanId) && (
        <Alert severity='error'>
          This plan is highly sensitive and high priority to J.P. Morgan. If
          this plan reaches out to us, please direct comms first to
          naomi.olsen@jpmchase.com, william.p.reynolds@jpmorgan.com, and
          jpmsales@vestwell.com before responding to the client
        </Alert>
      )}
      {[431, 6335].includes(sponsorPlanId) && (
        <Alert severity='error'>
          Cash Balance Plan - please consult with ECS on all testing related
          questions and actions
        </Alert>
      )}
      {[186, 104].includes(sponsorPlanId) && (
        <Alert severity='error'>
          Cash Balance Plan (Ref. Plan ID {sponsorPlanId === 104 ? 6335 : 431})
          - please consult with ECS on all testing related questions and actions
        </Alert>
      )}
      {[5060].includes(sponsorPlanId) && (
        <Alert severity='error'>
          Due to various circumstances involving this plan, the servicing team
          should not engage with any participant or plan sponsor (or anyone
          calling on their behalf) before informing Legal.
        </Alert>
      )}
      {[
        503891, 447, 245927, 450, 4880, 445, 327393, 242, 331373, 331208,
        504736, 246533, 70458, 355, 6401
      ].includes(sponsorPlanId) && (
        <Alert severity='error'>
          Please note this is a Top Tier Client, please loop in Pete Naquin,
          Account Manager, for any plan updates or issues.
        </Alert>
      )}
    </>
  );
};
