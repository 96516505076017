import { ReEnrollmentFull } from '@/components/icon/ReEnrollmentFull';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { ArrowForward } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { FC, useCallback } from 'react';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1)
}));

type ReEnrollmentModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  currentProgramName: string;
  currentProgramId: number;
  newProgramName: string;
  newProgramId: number;
  planName: string;
  planId: number;
};

export const FullReEnrollmentModal: FC<ReEnrollmentModalProps> = props => {
  const snackbar = useSnackbar();

  const postFullReenrollment = useMutation(
    [
      'ProgramService.postProgramChangeFullReenrollment',
      props.newProgramId,
      props.planId
    ],
    () =>
      ProgramService.postProgramChangeFullReenrollment(
        props.newProgramId,
        props.planId
      ),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: `Couldn’t submit changes to plan id ${props.planId}`,
          severity: 'error'
        });
      },
      onSettled: props.onClose,
      onSuccess: () => {
        props.onSuccess();
        snackbar.showSnackbar({
          message: `Successfully submitted changes to plan id ${props.planId}`,
          severity: 'success'
        });
      }
    }
  );

  const onSubmit = useCallback(() => {
    postFullReenrollment.mutate();
  }, []);

  const onClose = useCallback(() => {
    if (postFullReenrollment.isLoading) return;

    props.onClose();
  }, [postFullReenrollment.isLoading]);

  return (
    <Dialog onClose={onClose} open={props.isModalOpen}>
      <DialogTitle>Change investment program</DialogTitle>
      <DialogContent dividers>
        <Alert severity='warning'>
          <Typography color='rgba(0,0,0,0.6)' mb={2} variant='body2'>
            You&apos;re about to change this plans&apos; investment program.
            This could mean re-defaulting a saver&apos;s investment election to
            a new QDIA.
          </Typography>
          <Typography color='rgba(0,0,0,0.6)' variant='body2'>
            Submitting the below may impact savers&apos; investment elections in
            the plan.
          </Typography>
        </Alert>
        <Typography mb={2} mt={2}>
          Are you sure you want to update {props.planName}&apos;s investment
          program?
        </Typography>
        <Typography>
          <strong>Plan Name:</strong> {props.planName}
        </Typography>
        <Typography mb={2}>
          <strong>Plan ID:</strong> {props.planId}
        </Typography>
        <Divider />
        <Typography mb={2} mt={2} variant='body1'>
          Please confirm the below changes.
        </Typography>
        <Grid alignItems='stretch' container>
          <Grid item xs={5.5}>
            <StyledBox>
              <Typography variant='subtitle2'>Current Program</Typography>
              <Typography variant='body1'>
                {props.currentProgramName}
              </Typography>
              <Typography variant='body2'>
                ID: {props.currentProgramId}
              </Typography>
            </StyledBox>
          </Grid>
          <Grid alignContent='center' item textAlign='center' xs={1}>
            <ArrowForward />
          </Grid>
          <Grid item xs={5.5}>
            <StyledBox>
              <Typography variant='subtitle2'>New Program</Typography>
              <Typography variant='body1'>{props.newProgramName}</Typography>
              <Typography variant='body2'>ID: {props.newProgramId}</Typography>
            </StyledBox>
          </Grid>
        </Grid>
        <Typography mb={1} mt={2} variant='body1'>
          Re-enrollment method
        </Typography>
        <Stack alignItems='center' direction='row' spacing={2}>
          <ReEnrollmentFull fontSize='large' />
          <Stack>
            <Typography variant='subtitle1'>Full Re-enrollment</Typography>
            <Typography variant='body2'>
              Re-enroll all savers in the plan in the new program&apos;s QDIA
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={postFullReenrollment.isLoading} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={postFullReenrollment.isLoading}
          onClick={onSubmit}
          variant='contained'>
          Submit Changes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

FullReEnrollmentModal.displayName = 'FullReEnrollmentModal';
