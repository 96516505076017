import Card, { CardHeader } from '@/components/card/Card.component';
import DownloadCSVButton from '@/components/download-csv-button/DownloadCSVButton.component';
import { useParentAccountById } from '@/hooks/ops/useParentAccountById.hook';
import { SubAccountApiIncludeOption } from '@/models/ops/accounts/SubAccountApiIncludeOption.model';
import FundingSource from '@/models/ops/FundingSourceEnum.model';
import SubAccountService from '@/services/ops/accounts/SubAccount.service';
import formatters from '@/utils/Formatters';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { OperationalSubAccountList } from '@vestwell-sub-accounting/models/accountsAndLedgers/OperationalSubAccountList';
import { ParticipantSubAccountList } from '@vestwell-sub-accounting/models/accountsAndLedgers/ParticipantSubAccountList';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { useState } from 'react';

import { OperationalSubAccounts } from '../../common/OperationalSubAccounts.component';
import { SubAccounts } from '../../common/SubAccounts.component';

enum SubAccountsTabValue {
  'Operational' = 'Operational',
  'Participants' = 'Participants'
}

const exportOperationalSubAccounts = async (parentAccountId: string) => {
  return (
    await SubAccountService.searchSubAccounts({
      accountType: OperationalSubAccountList,
      hasPlanId: true,
      include: [
        SubAccountApiIncludeOption.cashBalance,
        SubAccountApiIncludeOption.totalMarketValue
      ],
      page: 1,
      pageSize: 0,
      parentAccountId
    })
  ).results?.map(subAccount =>
    subAccount.parentAccount?.accountType === ParentAccountType.SuperOmnibus
      ? {
          /* eslint-disable sort-keys-plus/sort-keys */
          Type: formatters.displayCase(subAccount.accountType),
          'Plan ID': subAccount.planId,
          'Plan Name': subAccount.plan?.name,
          'Market Value': subAccount.totalMarketValue,
          'Cash Balance': subAccount.cashBalance
          /* eslint-enable sort-keys-plus/sort-keys */
        }
      : {
          /* eslint-disable sort-keys-plus/sort-keys */
          Type: formatters.displayCase(subAccount.accountType),
          'Plan Name': subAccount.plan?.name,
          'Market Value': subAccount.totalMarketValue,
          'Cash Balance': subAccount.cashBalance
          /* eslint-enable sort-keys-plus/sort-keys */
        }
  );
};

const exportParticipantSubAccounts = async (parentAccountId: string) => {
  return (
    await SubAccountService.searchSubAccounts({
      accountType: ParticipantSubAccountList,
      include: [
        SubAccountApiIncludeOption.cashBalance,
        SubAccountApiIncludeOption.totalMarketValue
      ],
      page: 1,
      pageSize: 0,
      parentAccountId
    })
  ).results?.map(subAccount => ({
    /* eslint-disable sort-keys-plus/sort-keys */
    'Sub Account ID': subAccount.subAccountId,
    'Plan ID': subAccount.planId,
    'Part ID': subAccount.investorId,
    'Participant Name':
      subAccount.participant?.firstName || subAccount.participant?.lastName
        ? `${subAccount.participant?.firstName} ${subAccount.participant?.lastName}`
        : '',
    'Funding Source': subAccount.fundingSource
      ? FundingSource[subAccount.fundingSource]
      : '',
    'Market Value': subAccount.totalMarketValue,
    'Cash Balance': subAccount.cashBalance,
    'Created At': formatters.formatFromIsoDate(subAccount.createdAt)
    /* eslint-enable sort-keys-plus/sort-keys */
  }));
};

type SubAccountsTabProps = {
  parentAccountId: string;
};

export const SubAccountsTab = (props: SubAccountsTabProps) => {
  const parentAccountQuery = useParentAccountById({
    accountId: props.parentAccountId
  });
  const [subAccountsType, setSubAccountsType] = useState<SubAccountsTabValue>(
    SubAccountsTabValue.Participants
  );

  return (
    <Grid container spacing={2}>
      <Grid lg={4} xs={12}>
        <Card>
          <CardHeader title='Operational Accounts' />
          <OperationalSubAccounts
            DataTableProps={{
              columnSizing: 'fit',
              ['data-testid']: 'data-operational-accounts'
            }}
            hasPlanId={
              ![
                ParentAccountType.House,
                ParentAccountType.SuperOmnibus
              ].includes(parentAccountQuery.data?.accountType)
            }
            parentAccountId={props.parentAccountId}
          />
        </Card>
      </Grid>
      <Grid lg={8} xs={12}>
        <Card>
          <CardHeader
            title='Sub Accounts'
            toggle={
              parentAccountQuery.data?.accountType ===
              ParentAccountType.SuperOmnibus
                ? {
                    onChangeAction: (
                      event: React.MouseEvent<HTMLElement>,
                      value: SubAccountsTabValue
                    ) => {
                      setSubAccountsType(value);
                    },
                    options: [
                      {
                        label: 'Participants',
                        value: SubAccountsTabValue.Participants
                      },
                      {
                        label: 'Operational',
                        value: SubAccountsTabValue.Operational
                      }
                    ],
                    value: subAccountsType
                  }
                : undefined
            }>
            <DownloadCSVButton
              buttonProps={{
                sx: {
                  fontSize: 'small'
                },
                variant: 'outlined'
              }}
              fileName={`SubAccounts-${parentAccountQuery.data?.parentAccountId}-${subAccountsType}`}
              getInfo={() =>
                subAccountsType === SubAccountsTabValue.Participants
                  ? exportParticipantSubAccounts(
                      parentAccountQuery.data?.parentAccountId
                    )
                  : exportOperationalSubAccounts(
                      parentAccountQuery.data?.parentAccountId
                    )
              }
              text='Export CSV'
            />
          </CardHeader>
          {subAccountsType === SubAccountsTabValue.Participants && (
            <SubAccounts
              accountType={ParticipantSubAccountList}
              data-testid='data-participants-accounts'
              parentAccountId={props.parentAccountId}
            />
          )}
          {subAccountsType === SubAccountsTabValue.Operational && (
            <OperationalSubAccounts
              data-testid='data-operational-accounts'
              hasPlanId
              pagination
              parentAccountId={props.parentAccountId}
              showPlan
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};
