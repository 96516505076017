import ApiService from '@/services/Api.service';

export type ExternalProviderInvestmentOptionResponse = {
  investmentType: string;
  externalInvestmentOptionId: number;
  externalInvestmentName: string;
  baseModelType: string;
  baseInvestmentOptionId: number;
};

class ExternalProviderInvestmentOptionService {
  static async getExternalProviderInvestmentOptions(): Promise<
    ExternalProviderInvestmentOptionResponse[]
  > {
    return ApiService.getJson('/investments/epio');
  }
}

export { ExternalProviderInvestmentOptionService };
