import { ColumnApi, GridApi } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import clsx from 'clsx';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useClickAway, useUpdateEffect } from 'react-use';

export type AgGridApi = {
  api?: GridApi;
  columnApi?: ColumnApi;
};

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-064941}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Vestwell_Holdings_Inc.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{8}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_October_2025}____[v3]_[01]_MTc2MDM5NjQwMDAwMA==4cc4aaa3a097b2a8ad092a939dd2780a'
);

export const AgGrid = forwardRef<
  AgGridApi,
  AgGridReactProps & { 'data-testid'?: string }
>((props, ref) => {
  const $grid = useRef<any>(null);
  const $gridContainer = useRef(null);

  const [isEditing, setIsEditing] = useState(false);

  useImperativeHandle(ref, () => $grid.current);

  const onCellEditingStarted = useCallback(
    params => {
      setIsEditing(true);

      if (props.onCellEditingStarted) {
        props.onCellEditingStarted(params);
      }
    },
    [props]
  );

  const onCellEditingStopped = useCallback(
    params => {
      setIsEditing(false);

      if (props.onCellEditingStopped) {
        props.onCellEditingStopped(params);
      }
    },
    [props]
  );

  useUpdateEffect(() => {
    if (!isEditing) {
      $grid.current?.api?.refreshCells();
    }
  }, [props.context, isEditing]);

  useClickAway(
    $gridContainer,
    e => {
      const isEditorPopover = e
        .composedPath()
        ?.some((path: any) => path.id === 'ag-grid-editor-popover');

      if (isEditorPopover) return;

      $grid.current.api.stopEditing();
      $grid.current.api.clearFocusedCell();
    },
    ['mouseup', 'touchend']
  );

  return (
    <div
      className={clsx('ag-theme-vestwell-admin-ui')}
      data-testid={props['data-testid']}
      ref={$gridContainer}>
      <AgGridReact
        ref={$grid}
        {...props}
        columnDefs={props.columnDefs}
        columnTypes={props.columnTypes}
        context={props.context}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
      />
    </div>
  );
});

AgGrid.defaultProps = {
  columnDefs: [],
  domLayout: 'autoHeight',
  rowData: []
};

AgGrid.displayName = 'AgGrid';
