import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { GlobalRatesService } from '@/services/ops/global-rates/GlobalRates.service';
import formatters from '@/utils/Formatters';
import { testDecimals } from '@/utils/validations/helpers/YupValidationFunctions';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Form, Formik } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';

export const GlobalRatesRoute: FC = () => {
  const { showSnackbar } = useSnackbar();

  const loanRateQuery = useQuery(
    ['GlobalRatesService.getLoanInterestRate'],
    () => GlobalRatesService.getLoanInterestRate()
  );

  const loanRateMutation = useMutation(
    ['GlobalRatesService.putLoanInterestRate'],
    (rate: string) => GlobalRatesService.putLoanInterestRate(+rate),
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating loan rate',
          severity: 'error'
        });
      },
      onSuccess: () => {
        loanRateQuery.refetch();
        showSnackbar({
          message: 'Success',
          severity: 'success'
        });
      }
    }
  );

  const onSubmit = values => {
    loanRateMutation.mutateAsync(values.loanRate);
  };

  return (
    <Stack spacing={4}>
      <Typography variant='h4'>Global Rates</Typography>
      <Card variant='outlined'>
        <CardHeader title='Loan Interest Rate' />
        <CardContent>
          <Formik
            enableReinitialize
            initialValues={{
              loanRate: loanRateQuery.data?.data[0]?.attributes?.rate ?? 0
            }}
            onSubmit={onSubmit}
            validateOnChange
            validationSchema={yup.object({
              loanRate: yup
                .number()
                .typeError('Must be numeric')
                .required()
                .moreThan(-1, 'Must be a positive number')
                .max(100, 'Must be less than 100')
                .test(
                  'is-decimal',
                  'Must have at most two decimal places',
                  testDecimals(2)
                )
            })}>
            {formik => (
              <Form>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>%</InputAdornment>
                    )
                  }}
                  error={!!formik.errors.loanRate}
                  helperText={formik.errors.loanRate?.toString() ?? ''}
                  name='loanRate'
                  onChange={formik.handleChange}
                  size='small'
                  sx={{ mb: 2 }}
                  value={formik.values.loanRate}
                  variant='outlined'
                />
                <Typography mb={2}>
                  Last Updated:{' '}
                  {loanRateQuery.data?.data[0]?.attributes.updatedAt
                    ? formatters.formatFromIsoDateCustom(
                        loanRateQuery.data.data[0].attributes.updatedAt,
                        'MM/DD/YYYY'
                      )
                    : EMPTY_FIELD_PLACEHOLDER}
                </Typography>
                <LoadingButton
                  disabled={!formik.isValid}
                  loading={loanRateMutation.isLoading}
                  type='submit'
                  variant='contained'>
                  SAVE
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Stack>
  );
};
