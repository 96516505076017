import { CardPlaceholder } from '@/components/card';
import CollapsibleTable from '@/components/collapsible-table';
import LinearLoading from '@/components/linear-loading';
import { PlanService } from '@/services/Plan.service';
import AddIcon from '@mui/icons-material/Add';
import { Button, Card, CardHeader, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { FC, useState } from 'react';
import { useToggle } from 'react-use';

import BillingTableCell from './BillingTableCell.component';
import NewBillingPeriodDialog from './NewBillingPeriodDialog.component';

const columns = [
  { field: 'period', headerName: 'Period', width: 130 },
  { field: 'status', headerName: 'Status', width: 130 },
  { field: 'requestedAmount', headerName: 'Requested Amount', width: 130 },
  { field: 'collectedAmount', headerName: 'Collected Amount', width: 130 },
  { field: 'submittedDate', headerName: 'Submitted Date', width: 130 },
  { field: 'notes', headerName: 'Note', width: 130 },
  { field: 'action', headerName: 'Action', width: 130 }
];

const FeeManagementRoute: FC = () => {
  const [isOpenDialog, toggleOpenDialog] = useToggle(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const billingPeriodQuery = useQuery<any>(
    ['PlanService.getBillingPeriods', rowsPerPage, pageNumber],
    async () => PlanService.getBillingPeriods(rowsPerPage, pageNumber),
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  return (
    <>
      <Typography variant='h4'>Fees & Billings</Typography>
      <Button
        onClick={toggleOpenDialog}
        startIcon={<AddIcon />}
        sx={{ my: 3 }}
        variant='contained'>
        NEW BILLING SUBMISSION
      </Button>
      <Card className='Billing_table'>
        <CardHeader title='Billings' />
        {billingPeriodQuery.isFetching ? (
          <LinearLoading />
        ) : billingPeriodQuery.data && billingPeriodQuery.data.length === 0 ? (
          <CardPlaceholder data-testid='no-billing-data' subtitle='No data' />
        ) : (
          <CollapsibleTable
            cellComponent={BillingTableCell}
            columns={columns}
            data-testid='billing-table-component'
            pager={{
              metaCount: billingPeriodQuery.data?.meta.count,
              onPageNumberChanged: (zeroIndexedPageNumber: number) => {
                return setPageNumber(zeroIndexedPageNumber + 1);
              },
              onRowsPerPageChanged: (newRowsPerPage: number) => {
                return setRowsPerPage(newRowsPerPage);
              },
              pageNumber: pageNumber - 1,
              rowsPerPage
            }}
            tableData={billingPeriodQuery.data?.data || []}
          />
        )}
      </Card>
      <NewBillingPeriodDialog
        isOpen={isOpenDialog}
        onClose={toggleOpenDialog}
      />
    </>
  );
};

export default FeeManagementRoute;
