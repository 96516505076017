import Card, {
  CardHeader,
  CardToggleProps
} from '@/components/card/Card.component';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import { useTitle } from '@/hooks/useTitle';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { SearchAccountsContent } from '@/routes/ops/accounts/common/AllParentAccounts.component';
import { userService } from '@/services/User.service';
import { Typography } from '@mui/material';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { useMemo, useState } from 'react';

import { SubAccounts } from '../common/SubAccounts.component';

export const SearchAccountsRoute = (): JSX.Element => {
  useTitle('Accounts');

  const [accountLevel, setAccountLevel] = useState<AccountLevel>(
    AccountLevel.ParentAccount
  );
  const [parentAccountType, setParentAccountType] = useState<ParentAccountType>(
    ParentAccountType.PlanLevel
  );

  const hasRequiredPermissions = userService.hasPermission(
    FeatureLevelPermissions.READ_SUBA_ACCOUNTS
  );

  const togglesOptions = useMemo<CardToggleProps[]>(
    () =>
      [
        {
          onChangeAction: (_, value: AccountLevel) => {
            setAccountLevel(value);
          },
          options: [
            {
              label: 'Parent',
              value: AccountLevel.ParentAccount
            },
            { label: 'Sub', value: AccountLevel.SubAccount }
          ],
          value: accountLevel
        },
        accountLevel === AccountLevel.ParentAccount && {
          name: 'Parent Type',
          onChangeAction: (_, value: ParentAccountType) => {
            setParentAccountType(value);
          },
          options: [
            {
              label: 'Plan level',
              value: ParentAccountType.PlanLevel
            },
            { label: 'Super omni', value: ParentAccountType.SuperOmnibus },
            { label: 'House', value: ParentAccountType.House }
          ],
          value: parentAccountType
        }
      ].filter(Boolean),
    [accountLevel]
  );

  if (!hasRequiredPermissions) {
    return redirectToErrorPage(new Error('403'));
  }

  return (
    <>
      <Typography component='h2' mb={5} variant='h4'>
        Accounts
      </Typography>
      <Card>
        <CardHeader
          data-testid='search-accounts'
          title='Search Accounts'
          toggles={togglesOptions}
        />
        {accountLevel === AccountLevel.ParentAccount && (
          <SearchAccountsContent accountType={parentAccountType} />
        )}
        {accountLevel === AccountLevel.SubAccount && <SubAccounts />}
      </Card>
    </>
  );
};

export default SearchAccountsRoute;
