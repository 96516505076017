import SimpleDropdown from '@/components/simple-dropdown';
import { useDialog } from '@/contexts/DialogContext';
import { CreateTpaRequest, TpaListDtoItem } from '@/models/TpaDTO.model';
import TpaService from '@/services/Tpa.service';
import formatters from '@/utils/Formatters';
import { Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import React, { useCallback } from 'react';
import * as yup from 'yup';

interface AddTpaButtonProps {
  tpaData: TpaListDtoItem[];
  addTpa: (tpa: CreateTpaRequest) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    tpaBtnContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  })
);

const AddTpaButton = (props: AddTpaButtonProps): JSX.Element => {
  const classes = useStyles();
  const { openDialog } = useDialog();

  const tpaSubtypes = useQuery(['TpaService.getTpaSubtypes'], () => {
    return TpaService.getTpaSubtypes();
  });

  const { tpaData, addTpa } = props;

  const validationSchema = yup.object({
    name: yup
      .string()
      .required('TPA Name is required')
      .min(3, 'TPA Name length must be at least 3 characters')
      .test('unique', 'TPA Name must be unique', inputValue => {
        if (inputValue) {
          return !tpaData.some(
            tpa => tpa.name.toLowerCase() === inputValue.toLowerCase()
          );
        }
        return false;
      }),
    tpaSubtypeId: yup.number().required('TPA Subtype is required')
  });

  const onClick = useCallback(
    () =>
      openDialog({
        actionButtons: {
          cancelButton: {
            children: 'CANCEl'
          },
          submitButton: {
            children: 'ADD'
          }
        },
        onSubmit: values => {
          addTpa({ name: values.name, tpaSubtypeId: +values.tpaSubtypeId });
        },
        steps: [
          {
            fields: {
              name: {
                initialValue: '',
                label: 'TPA Name'
              },
              tpaSubtypeId: {
                component: (
                  <SimpleDropdown
                    fieldId='tpaSubtypeId'
                    fieldName='TPA Subtype'
                    fieldOptions={(tpaSubtypes.data.data || []).map(
                      subtype => ({
                        option: `${subtype.name} - ${formatters.displayCase(subtype.type)}`,
                        value: subtype.tpaSubtypeId
                      })
                    )}
                  />
                ),
                initialValue: 1,
                label: 'TPA Subtype'
              }
            },
            title: 'Add New TPA'
          }
        ],
        validationSchema
      }),
    [tpaSubtypes.data]
  );

  return (
    <div className={classes.tpaBtnContainer}>
      <Button
        data-testid='tpa-main-page-btn'
        onClick={onClick}
        variant='contained'>
        ADD TPA
      </Button>
    </div>
  );
};

export default AddTpaButton;
