import AppConfig from '@/App.config';
import AccessControl from '@/components/access-control/AccessControl.component';
import { PLAN_DATES_TYPES } from '@/consts/plan.constants';
import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  DocumentMetadataListDto,
  DocumentSlotMetaData,
  LTPlan,
  PlanDate,
  PlanV2,
  PlanV2Dto,
  SubAccountingPlan
} from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import DocumentsCell from '@/routes/plans/plan-detail/PlanOnboard/DocumentsCell.component';
import PlanOnboardPizzaTracker from '@/routes/plans/plan-detail/PlanOnboard/PlanOnboardPizzaTracker.component';
import { PlanSalesforceInfo } from '@/routes/plans/plan-detail/PlanOnboard/PlanSalesforceInfo.component';
import SubAccountingPlanSetupCard from '@/routes/plans/plan-detail/SubAccountingPlanSetupCard.component';
import { PlanService } from '@/services/Plan.service';
import SponsorService from '@/services/Sponsor.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import {
  ArrowDownward as ArrowDownwardIcon,
  Business as BusinessIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
  PeopleOutline as PeopleOutlineIcon
} from '@mui/icons-material';
import {
  Box,
  Button,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { flatten } from 'lodash';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  arrowDownwardIcon: {
    color: theme.palette.text.disabled,
    marginLeft: '0.5rem'
  },
  disabledColor: {
    color: theme.palette.text.disabled
  },
  eligibilityButtons: {
    justifyContent: 'flex-start',
    width: theme.spacing(22) // prevent buttons from changing size after email is sent and label changes
  }
}));

interface PlanOnboardProps {
  isConversion: boolean;
  isStatePlan: boolean;
  recordkeeper: string;
  recordkeeperId: number;
  sponsorId: number;
  sponsorPlanId: string;
  tpaId: number;
  taskTracker: PlanV2['attributes']['statefulSchemaTrackingState'];
}

interface OnboardingError {
  details?: string[];
  error?: string;
}

const PlanOnboard = (props: PlanOnboardProps): ReactElement => {
  const classes = useStyles();
  const { sponsorId, sponsorPlanId, recordkeeper } = props;
  const [onboardingErrors, setOnboardingErrors] = useState<string[]>([]);
  const { showSnackbar } = useSnackbar();

  const columns = [
    {
      field: 'original_file_name',
      headerName: 'File'
    },
    {
      field: 'created_at',
      headerName: 'Uploaded'
    },
    {
      field: 'uploaded_by',
      headerName: 'Uploaded by'
    },
    {
      field: 'download',
      headerName: 'Actions'
    }
  ];

  const validation = useMutation((planId: string) =>
    PlanService.validate(planId)
  );

  const plan = useQuery<
    | {
        thirdPartyPlan: LTPlan | SubAccountingPlan;
        dateSubmittedToRecordkeeper: string;
      }
    | Record<string, any>
  >(
    ['PlanService.getOnboardedPlanById', sponsorPlanId],
    () => PlanService.getOnboardedPlanById(sponsorPlanId),
    {
      enabled: !userService.isTpaUser() && !props.isStatePlan,
      refetchInterval:
        recordkeeper === 'Vestwell Sub-Accounting Platform' ? 5000 : false,
      suspense: false
    }
  );

  const { data: planDesignData } = useQuery(
    ['PlanService.getPlanDesignById', +sponsorPlanId],
    () => {
      return PlanService.getPlanDesignById(sponsorPlanId);
    },
    {
      enabled: Boolean(sponsorPlanId) && !props.isStatePlan,
      staleTime: Infinity
    }
  );

  const existsEntryDateFrequency =
    planDesignData?.data?.eligibilityFeatures?.eligibilityRules?.[0]
      ?.entryDateFrequencyType;

  const existsAgeRequirement =
    planDesignData?.data?.eligibilityFeatures?.eligibilityRules?.find(
      rule => rule.eligibilityRequirementTypeName === 'Age'
    );

  const existsServiceRequirement =
    planDesignData?.data?.eligibilityFeatures?.eligibilityRules?.find(
      rule => rule.eligibilityRequirementTypeName !== 'Age'
    );

  const sponsorPlanQuery = useQuery<PlanV2Dto>(
    ['PlanService.getPlanById', sponsorPlanId?.toString()],
    () => {
      return PlanService.getPlanById(sponsorPlanId);
    },
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const planDatesQuery = useQuery<PlanDate[]>(
    ['PlanService.getPlanDates', sponsorPlanId],
    () => PlanService.getPlanDates(sponsorPlanId),
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const sendSponsorEligibilityReviewReminderEmailMutation = useMutation(
    (params: { sponsorId: string | number; sponsorPlanId: string | number }) =>
      SponsorService.sendSponsorEligibilityReviewReminderEmail(
        params.sponsorId,
        params.sponsorPlanId
      ),
    {
      onError: () => {
        showSnackbar({
          message: 'Error',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        showSnackbar({ message: 'Success!', severity: 'success' });
      }
    }
  );

  const sendSponsorNoticeMutation = useMutation(
    (params: { sponsorId: string | number; sponsorPlanId: string | number }) =>
      SponsorService.sendSponsorBlackoutNotice(
        params.sponsorId,
        params.sponsorPlanId
      ),
    {
      onError: () => {
        showSnackbar({
          message: 'Error',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await planDatesQuery.refetch();
        showSnackbar({ message: 'Success!', severity: 'success' });
      }
    }
  );

  const sendParticipantsNoticesMutation = useMutation(
    (planId: string) => PlanService.sendParticipantsBlackoutNotice(planId),
    {
      onError: () => {
        showSnackbar({
          message: 'Error',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await planDatesQuery.refetch();
        showSnackbar({ message: 'Success!', severity: 'success' });
      }
    }
  );

  const pizzaTracker = useQuery<Record<string, string>>(
    ['PlanService.getSponsorPlanPizzaTracker', sponsorPlanId],
    () => PlanService.getSponsorPlanPizzaTracker(sponsorPlanId)
  );

  const onboarding = useMutation<void, OnboardingError, string>(
    (planId: string) => PlanService.onboard(planId),
    {
      onError: async requestError => {
        const { error, details: rawDetails } = requestError;
        let newErrors: string[] = [];
        if (rawDetails && rawDetails.length > 0) {
          rawDetails.forEach(rawDetail => {
            try {
              const details = JSON.parse(rawDetail.replace(/\\"/g, ''));
              if (details.messages && details.messages.length > 0) {
                newErrors.push(...details.messages);
              }
              // eslint-disable-next-line no-empty
            } catch (e) {}
          });
        } else if (requestError && !error) {
          const ltError: { error?: string } = formatters.parseLtError(
            requestError.toString()
          );
          newErrors = ltError.error ? [ltError.error] : [];
        } else if (error) {
          newErrors = [error];
        }
        setOnboardingErrors(newErrors);
      },
      onSuccess: () => plan.refetch()
    }
  );

  const runEligibility = useMutation(
    (planId: string) => PlanService.refreshPlanEligibility(planId),
    {
      onError: () => {
        showSnackbar({
          message: 'Failed',
          severity: 'error'
        });
      },
      onSuccess: () =>
        showSnackbar({
          message: 'Success! The eligibility calculation has been refreshed.',
          severity: 'success'
        })
    }
  );

  const planDocumentsQuery = useQuery(
    ['PlanService.getAllPlanDocuments', sponsorPlanId],
    async () => PlanService.getAllPlanDocuments(sponsorPlanId),
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const documentsBySponsorEntityQuery = useQuery(
    ['SponsorService.getDocumentsBySponsorEntity', sponsorId],
    async () => SponsorService.getDocumentsBySponsorEntity(sponsorId),
    {
      enabled: Boolean(sponsorId),
      staleTime: Infinity
    }
  );

  const documents = useMemo(() => {
    const planDocuments = flatten(
      ((planDocumentsQuery?.data as DocumentSlotMetaData[]) || [])
        ?.filter(
          doc =>
            ['etl/initial-census/flatfile', 'etl/Add Employees'].includes(
              doc.document_key
            ) && doc.latest
        )
        ?.map(doc => {
          return doc.uploadHistory || [];
        })
    ) as unknown as DocumentMetadataListDto;

    const sponsorDocuments = flatten(
      ((documentsBySponsorEntityQuery?.data as DocumentSlotMetaData[]) || [])
        ?.filter(doc => doc.document_key === 'Employee List' && doc.latest)
        ?.map(doc => {
          return doc.uploadHistory || [];
        })
    ) as unknown as DocumentMetadataListDto;

    return [...planDocuments, ...sponsorDocuments].sort((a, b) =>
      dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1
    );
  }, [planDocumentsQuery, documentsBySponsorEntityQuery]);

  useEffect(() => {
    if (
      !userService.isTpaUser() &&
      !props.isStatePlan &&
      userService.hasPermission(FeatureLevelPermissions.WRITE_ONBOARD)
    ) {
      validation.mutate(sponsorPlanId);
    }
  }, []);

  const onboardInError = useMemo(() => {
    return ['FAILED', 'SUBA_FAILED'].includes(plan.data?.thirdPartyPlan.status);
  }, [plan.data?.thirdPartyPlan.status]);

  const sponsorBlackoutNoticeSentDate = useMemo(() => {
    return planDatesQuery.data?.findLast(
      (dateObject: PlanDate) =>
        dateObject.dateType === PLAN_DATES_TYPES.SPONSOR_BLACKOUT_NOTICE_SENT
    )?.date;
  }, [planDatesQuery.data]);

  const participantsBlackoutNoticeDate = useMemo(() => {
    return planDatesQuery.data?.findLast(
      (dateObject: PlanDate) =>
        dateObject.dateType === PLAN_DATES_TYPES.PARTICIPANTS_BLACKOUT_NOTICE
    )?.date;
  }, [planDatesQuery.data]);

  const urlPlansType =
    sponsorPlanQuery.data?.data?.attributes?.type === 'ESA'
      ? 'esa-plans'
      : 'plans';

  if (
    validation.isLoading ||
    plan.isInitialLoading ||
    pizzaTracker.isInitialLoading
  ) {
    return <LinearProgress />;
  }

  return (
    <>
      <AccessControl hideFromTPA>
        {!validation.isLoading &&
          !plan.isInitialLoading &&
          (!plan.data?.thirdPartyPlan?.planId ||
            !plan.data?.dateSubmittedToRecordkeeper) &&
          !props.isStatePlan && (
            <Box borderBottom='1px solid #E0E0E0' mb={3} pb={3}>
              <Box mb={3}>
                <AccessControl
                  requiresOneOf={[
                    FeatureLevelPermissions.WRITE_ONBOARD,
                    FeatureLevelPermissions.READ_ONBOARD
                  ]}>
                  <Button
                    color='primary'
                    disabled={
                      validation.data?.errors.length !== 0 ||
                      onboarding.isLoading ||
                      onboarding.isSuccess ||
                      (plan.data?.dateSubmittedToRecordkeeper &&
                        !onboardInError)
                    }
                    onClick={async () => {
                      onboarding.mutate(sponsorPlanId);
                    }}
                    variant='contained'>
                    {onboarding.isLoading ? 'PROCESSING...' : 'ONBOARD PLAN'}
                  </Button>
                </AccessControl>
              </Box>
              {onboarding.isSuccess && (
                <Box alignItems='center' display='flex'>
                  <Box display='flex' mr={1.25}>
                    <CheckCircleOutlineIcon
                      htmlColor={AppConfig.branding?.colors?.success}
                    />
                  </Box>
                  <Typography variant='body2'>Successful</Typography>
                </Box>
              )}
              {onboardingErrors.length > 0 && (
                <Box>
                  {onboardingErrors.map(error => (
                    <Box alignItems='center' display='flex' key={error}>
                      <Box display='flex' mr={1.25}>
                        <ErrorOutlineIcon color='error' />
                      </Box>
                      <Typography variant='body2'>{error}</Typography>
                    </Box>
                  ))}
                </Box>
              )}
              {!!validation.data?.errors.length && (
                <Box>
                  <Box mb={1.25}>
                    <Typography variant='body2'>
                      You must fix the following before onboarding:
                    </Typography>
                  </Box>
                  {validation.data?.errors.map(error => (
                    <Box alignItems='center' display='flex' key={error}>
                      <Box display='flex' mr={1.25}>
                        <ErrorOutlineIcon color='error' />
                      </Box>
                      <Typography variant='body2'>{error}</Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          )}
        {!validation.isLoading &&
          !plan.isInitialLoading &&
          (plan.data?.thirdPartyPlan?.planId ||
            plan.data?.dateSubmittedToRecordkeeper) &&
          !props.isStatePlan && (
            <>
              <Box alignItems='center' display='flex' mb={2}>
                <Box display='flex' mr={1.25}>
                  <CheckCircleOutlineIcon
                    htmlColor={AppConfig.branding?.colors?.success}
                  />
                </Box>
                <Typography variant='body2'>
                  Plan {plan.data?.thirdPartyPlan?.planId} has been successfully
                  {plan.data?.thirdPartyPlan?.planId
                    ? ' onboarded'
                    : ' submitted'}
                  {!!plan.data?.dateSubmittedToRecordkeeper &&
                    ` on ${plan.data?.dateSubmittedToRecordkeeper}`}
                </Typography>
              </Box>
              {recordkeeper === 'Vestwell Sub-Accounting Platform' &&
                plan.data && (
                  <SubAccountingPlanSetupCard
                    plan={plan.data?.thirdPartyPlan}
                  />
                )}
            </>
          )}
      </AccessControl>

      {recordkeeper === 'Vestwell Sub-Accounting Platform' && (
        <PlanSalesforceInfo sponsorPlanId={+sponsorPlanId} />
      )}

      {sponsorPlanQuery.data?.data?.attributes.type !== 'ESA' &&
        pizzaTracker.data && (
          <AccessControl
            requiresOneOf={[
              FeatureLevelPermissions.WRITE_ONBOARD,
              FeatureLevelPermissions.READ_ONBOARD
            ]}>
            <PlanOnboardPizzaTracker
              isConversion={props.isConversion}
              isStatePlan={props.isStatePlan}
              pizzaTracker={pizzaTracker.data}
              recordkeeperId={props.recordkeeperId}
              sponsorPlanId={+sponsorPlanId}
              taskTracker={props.taskTracker}
              tpaId={props.tpaId}
            />
          </AccessControl>
        )}

      <Box alignItems='baseline' display='flex' mt={2}>
        <Box border='1px solid #E0E0E0' flex='3' mr={2}>
          <Box display='flex' justifyContent='space-between' p={2}>
            <Typography variant='h5'>Census History</Typography>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell key={column.headerName}>
                      <Box alignItems='center' display='flex'>
                        {column.headerName}
                        {column.field === 'created_at' && (
                          <ArrowDownwardIcon
                            classes={{ root: classes.arrowDownwardIcon }}
                          />
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {documents &&
                  documents.map((doc: any) => (
                    <TableRow key={doc.id}>
                      {columns.map(col => (
                        <DocumentsCell column={col} key={col.field} row={doc} />
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box flex='1'>
          {props.isConversion &&
            sponsorPlanQuery.data?.data?.attributes?.inboundBlackoutEndDate &&
            sponsorPlanQuery.data?.data?.attributes
              ?.inboundBlackoutStartDate && (
              <Stack border='1px solid #E0E0E0' p={2}>
                <Typography variant='h5'>Blackout Notifications</Typography>
                <Stack alignItems='flex-start' mt={6} spacing={0.5}>
                  <Button
                    color='primary'
                    disabled={
                      sendParticipantsNoticesMutation.isLoading ||
                      sendParticipantsNoticesMutation.isSuccess
                    }
                    onClick={async () =>
                      sendParticipantsNoticesMutation.mutateAsync(sponsorPlanId)
                    }
                    startIcon={<PeopleOutlineIcon />}>
                    Send Employee Notice
                  </Button>
                  <Typography
                    variant='body2'
                    visibility={
                      participantsBlackoutNoticeDate ? 'visible' : 'hidden'
                    }>
                    Notice sent to employees on {participantsBlackoutNoticeDate}
                  </Typography>
                  <Button
                    color='primary'
                    disabled={
                      // ensure employees are notified first
                      !participantsBlackoutNoticeDate ||
                      sendSponsorNoticeMutation.isLoading ||
                      sendSponsorNoticeMutation.isSuccess
                    }
                    onClick={async () =>
                      sendSponsorNoticeMutation.mutateAsync({
                        sponsorId,
                        sponsorPlanId
                      })
                    }
                    startIcon={<BusinessIcon />}>
                    Send Employer Notice
                  </Button>
                  <Typography
                    variant='body2'
                    visibility={
                      sponsorBlackoutNoticeSentDate ? 'visible' : 'hidden'
                    }>
                    Notice sent to employer on {sponsorBlackoutNoticeSentDate}
                  </Typography>
                </Stack>
              </Stack>
            )}

          {!props.isStatePlan && (
            <AccessControl requires={[FeatureLevelPermissions.WRITE_ONBOARD]}>
              <Box
                border='1px solid #E0E0E0'
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
                mt={2}>
                <Box display='flex' gap={10} pt={2} px={2}>
                  <Typography variant='h5'>Eligibility</Typography>
                </Box>
                <Stack p={2} spacing={1}>
                  <Button
                    className={classes.eligibilityButtons}
                    onClick={() =>
                      window.open(`/${urlPlansType}/${sponsorPlanId}/plan`)
                    }
                    size='small'>
                    VIEW PLAN RULES
                  </Button>
                  <Button
                    className={classes.eligibilityButtons}
                    onClick={() =>
                      window.open(`/${urlPlansType}/${sponsorPlanId}/employees`)
                    }
                    size='small'>
                    VIEW EMPLOYEES
                  </Button>
                  <Button
                    className={classes.eligibilityButtons}
                    color='primary'
                    disabled={
                      !existsEntryDateFrequency ||
                      !existsServiceRequirement ||
                      !existsAgeRequirement
                    }
                    onClick={async () => {
                      runEligibility.mutate(sponsorPlanId);
                    }}
                    size='small'
                    variant='contained'>
                    RUN ELIGIBILITY
                  </Button>
                  <Button
                    className={classes.eligibilityButtons}
                    color='primary'
                    disabled={
                      sendSponsorEligibilityReviewReminderEmailMutation.isSuccess ||
                      sendSponsorEligibilityReviewReminderEmailMutation.isLoading
                    }
                    onClick={async () =>
                      sendSponsorEligibilityReviewReminderEmailMutation.mutateAsync(
                        {
                          sponsorId,
                          sponsorPlanId
                        }
                      )
                    }
                    size='small'
                    variant='contained'>
                    {sendSponsorEligibilityReviewReminderEmailMutation.isSuccess
                      ? 'REVIEW EMAIL SENT!'
                      : 'SEND REMINDER EMAIL'}
                  </Button>
                </Stack>
              </Box>
            </AccessControl>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PlanOnboard;
