class NonSafeHarborMatchType {
  convertToApiState = (value?: string): string | null => {
    return value ? this.mapUiValueToApi[value] : null;
  };

  disabled: boolean;

  mapApiValueToUi: Record<string, string> = {
    discretionary: 'Discretionary Match',
    formula: 'Formula'
  };

  mapUiValueToApi: Record<string, string> = {
    'Discretionary Match': 'discretionary',
    Formula: 'formula'
  };

  options = ['Discretionary Match', 'Formula'];

  output: string | undefined;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.disabled = !props.allowNonSafeHarborMatch;
    this.output = this.mapApiValueToUi[props.nonSafeHarborMatchType];
  }
}

export default NonSafeHarborMatchType;
