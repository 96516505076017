import { useField, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const EligibilityFeaturesObserver: FC<{
  data: Record<string, any>;
}> = ({ data }: { data: Record<string, any> }) => {
  const { values }: { values: Record<string, any> } = useFormikContext();

  const [, , lengthOfEmploymentRequiredHelpers] = useField(
    'lengthOfEmploymentRequired'
  );
  const [, , hoursRequiredHelpers] = useField('hoursRequired');
  const [, , specialParticipationDateHelpers] = useField(
    'specialParticipationDate'
  );
  const [, , specialEntryDateHelpers] = useField('specialEntryDate');

  const [, , employerMatchLengthOfEmploymentRequiredHelpers] = useField(
    'employerMatchLengthOfEmploymentRequired'
  );
  const [, , employerMatchHoursRequiredHelpers] = useField(
    'employerMatchHoursRequired'
  );

  const [, , profitSharingLengthOfEmploymentRequiredHelpers] = useField(
    'profitSharingLengthOfEmploymentRequired'
  );
  const [, , profitSharingHoursRequiredHelpers] = useField(
    'profitSharingHoursRequired'
  );

  useEffect(() => {
    if (
      ['No Requirement', 'Specified Hours of Service'].includes(
        values.profitSharingServiceCalculationType
      ) &&
      values.profitSharingLengthOfEmploymentRequired !== 'No Requirement'
    ) {
      profitSharingLengthOfEmploymentRequiredHelpers.setValue('No Requirement');
    } else if (
      ['One (1) Year - Hours of Service'].includes(
        values.profitSharingServiceCalculationType
      ) &&
      values.profitSharingLengthOfEmploymentRequired !== '12 months'
    ) {
      profitSharingLengthOfEmploymentRequiredHelpers.setValue('12 months');
    }

    data.profitSharingLengthOfEmploymentRequired.setDisabled(
      !values.profitSharingServiceCalculationType ||
        [
          'No Requirement',
          'Specified Hours of Service',
          'One (1) Year - Hours of Service'
        ].includes(values.profitSharingServiceCalculationType)
    );

    data.profitSharingHoursRequired.setDisabled(
      !values.profitSharingServiceCalculationType ||
        ['No Requirement', 'Specified Months - Elapsed Time'].includes(
          values.profitSharingServiceCalculationType
        )
    );

    if (values.profitSharingHoursRequired + '' === '') {
      profitSharingHoursRequiredHelpers.setValue('');
    } else if (
      ['No Requirement', 'Specified Months - Elapsed Time'].includes(
        values.profitSharingServiceCalculationType
      ) &&
      values.profitSharingHoursRequired !== 0
    ) {
      profitSharingHoursRequiredHelpers.setValue(0);
    }
  }, [
    data,
    values,
    profitSharingLengthOfEmploymentRequiredHelpers,
    profitSharingHoursRequiredHelpers
  ]);

  useEffect(() => {
    if (
      ['No Requirement', 'Specified Hours of Service'].includes(
        values.employerMatchServiceCalculationType
      ) &&
      values.employerMatchLengthOfEmploymentRequired !== 'No Requirement'
    ) {
      employerMatchLengthOfEmploymentRequiredHelpers.setValue('No Requirement');
    } else if (
      ['One (1) Year - Hours of Service'].includes(
        values.employerMatchServiceCalculationType
      ) &&
      values.employerMatchLengthOfEmploymentRequired !== '12 months'
    ) {
      employerMatchLengthOfEmploymentRequiredHelpers.setValue('12 months');
    }

    data.employerMatchLengthOfEmploymentRequired.setDisabled(
      !values.employerMatchServiceCalculationType ||
        [
          'No Requirement',
          'Specified Hours of Service',
          'One (1) Year - Hours of Service'
        ].includes(values.employerMatchServiceCalculationType)
    );

    data.employerMatchHoursRequired.setDisabled(
      !values.employerMatchServiceCalculationType ||
        ['No Requirement', 'Specified Months - Elapsed Time'].includes(
          values.employerMatchServiceCalculationType
        )
    );

    if (values.employerMatchHoursRequired + '' === '') {
      employerMatchHoursRequiredHelpers.setValue('');
    } else if (
      ['No Requirement', 'Specified Months - Elapsed Time'].includes(
        values.employerMatchServiceCalculationType
      ) &&
      values.employerMatchHoursRequired !== 0
    ) {
      employerMatchHoursRequiredHelpers.setValue(0);
    }
  }, [
    data,
    values,
    employerMatchLengthOfEmploymentRequiredHelpers,
    employerMatchHoursRequiredHelpers
  ]);

  useEffect(() => {
    if (
      ['No Requirement', 'Specified Hours of Service'].includes(
        values.serviceCalculationType
      ) &&
      values.lengthOfEmploymentRequired !== 'No Requirement'
    ) {
      lengthOfEmploymentRequiredHelpers.setValue('No Requirement');
    } else if (
      ['One (1) Year - Hours of Service', 'State IRA Eligibility'].includes(
        values.serviceCalculationType
      ) &&
      values.lengthOfEmploymentRequired !== '12 months'
    ) {
      lengthOfEmploymentRequiredHelpers.setValue('12 months');
    }

    data.lengthOfEmploymentRequired.setDisabled(
      !values.serviceCalculationType ||
        [
          'No Requirement',
          'Specified Hours of Service',
          'One (1) Year - Hours of Service',
          'State IRA Eligibility'
        ].includes(values.serviceCalculationType)
    );

    data.hoursRequired.setDisabled(
      !values.serviceCalculationType ||
        [
          'No Requirement',
          'Specified Months - Elapsed Time',
          'State IRA Eligibility'
        ].includes(values.serviceCalculationType)
    );

    if (values.hoursRequired + '' === '') {
      hoursRequiredHelpers.setValue('');
    } else if (
      [
        'No Requirement',
        'Specified Months - Elapsed Time',
        'State IRA Eligibility'
      ].includes(values.serviceCalculationType) &&
      values.hoursRequired !== 0
    ) {
      hoursRequiredHelpers.setValue(0);
    }

    if (values.specialParticipationDate === 'Invalid Date') {
      specialParticipationDateHelpers.setValue('');
    }

    if (values.specialEntryDate === 'Invalid Date') {
      specialEntryDateHelpers.setValue('');
    }
  }, [
    data,
    values,
    lengthOfEmploymentRequiredHelpers,
    hoursRequiredHelpers,
    specialParticipationDateHelpers,
    specialEntryDateHelpers
  ]);

  return null;
};

export default EligibilityFeaturesObserver;
