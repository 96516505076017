import { OnboardingQuestionnaireModal } from '@/components/OnboardingQuestionnaireModal.component';
import { PlanV2 } from '@/models';
import PlanOnboardPizzaTrackerCard from '@/routes/plans/plan-detail/PlanOnboard/PlanOnboardPizzaTrackerCard.component';
import { PlanService } from '@/services/Plan.service';
import { Grid } from '@mui/material';

import dayjs from 'dayjs';
import React, { useState } from 'react';

type PlanOnboardPizzaTrackerProps = {
  isConversion: boolean;
  isStatePlan: boolean;
  recordkeeperId: number;
  pizzaTracker: Record<string, string>;
  sponsorPlanId: number;
  tpaId: number;
  taskTracker: PlanV2['attributes']['statefulSchemaTrackingState'];
};

const PlanOnboardPizzaTracker: React.FC<PlanOnboardPizzaTrackerProps> = ({
  isConversion,
  isStatePlan,
  pizzaTracker,
  recordkeeperId,
  sponsorPlanId,
  taskTracker,
  tpaId
}: PlanOnboardPizzaTrackerProps) => {
  const [
    digitalOnboardingStatePlanDesign,
    setDigitalOnboardingStatePlanDesign
  ] = useState(pizzaTracker.DIGITAL_ONBOARDING_STATE_PLAN_DESIGN);

  const [
    digitalOnboardingStatePlanLaunchCall,
    setDigitalOnboardingStatePlanLaunchCall
  ] = useState(pizzaTracker.DIGITAL_ONBOARDING_STATE_PLAN_LAUNCH_CALL);

  return (
    <Grid container display='flex' flexDirection='row' mb={5} spacing={2}>
      {!isStatePlan && (
        <Grid item lg={2} md={3} xs={4}>
          <PlanOnboardPizzaTrackerCard
            ctaOrLink={
              <OnboardingQuestionnaireModal sponsorPlanId={sponsorPlanId} />
            }
            date={pizzaTracker.QUESTIONNAIRE_SUBMITTED_AT}
            mr={2}
            status={
              pizzaTracker.QUESTIONNAIRE_SUBMITTED_AT ? 'COMPLETE' : 'PENDING'
            }
            title='Employer Questionnaire'
          />
        </Grid>
      )}
      {recordkeeperId === 5 && tpaId === 1 && (
        <Grid item lg={2} md={3} xs={4}>
          <PlanOnboardPizzaTrackerCard
            date={digitalOnboardingStatePlanDesign}
            menuOptions={[
              {
                disabled: !!digitalOnboardingStatePlanDesign,
                fn: async () => {
                  const res =
                    await PlanService.updateDigitalOnboardingStatePlanDesign(
                      sponsorPlanId
                    );
                  setDigitalOnboardingStatePlanDesign(res.planDesign);
                },
                key: 'complete',
                text: 'Complete'
              }
            ]}
            status={digitalOnboardingStatePlanDesign ? 'COMPLETE' : 'PENDING'}
            title='Plan Design'
          />
        </Grid>
      )}
      {!isStatePlan && (
        <Grid item lg={2} md={3} xs={4}>
          <PlanOnboardPizzaTrackerCard
            date={pizzaTracker.PSA_SIGNED_DATE}
            mr={2}
            status={pizzaTracker.PSA_SIGNED_DATE ? 'SIGNED' : 'PENDING'}
            title='PSA Sign Date'
          />
        </Grid>
      )}
      <Grid item lg={2} md={3} xs={4}>
        <PlanOnboardPizzaTrackerCard
          date={
            taskTracker?.attributes?.updatedAt ||
            taskTracker?.attributes?.createdAt
          }
          mr={2}
          schemaTrackingState={taskTracker}
          status={
            taskTracker?.attributes?.status === 'completed_successfully'
              ? 'COMPLETE'
              : !taskTracker?.attributes?.status
                ? 'NOT STARTED'
                : 'IN PROGRESS'
          }
          title='Payroll and Employee Setup'
        />
      </Grid>
      {!isStatePlan && (
        <>
          <Grid item lg={2} md={3} xs={4}>
            <PlanOnboardPizzaTrackerCard
              date={digitalOnboardingStatePlanLaunchCall}
              menuOptions={[
                {
                  disabled: !!digitalOnboardingStatePlanLaunchCall,
                  fn: async () => {
                    const res =
                      await PlanService.updateDigitalOnboardingStatePlanLaunchCall(
                        sponsorPlanId
                      );
                    setDigitalOnboardingStatePlanLaunchCall(res.planLaunch);
                  },
                  key: 'complete',
                  text: 'Complete'
                }
              ]}
              mr={2}
              status={
                digitalOnboardingStatePlanLaunchCall ? 'COMPLETE' : 'PENDING'
              }
              title='Plan Launch Call'
            />
          </Grid>
          <Grid item lg={2} md={3} xs={4}>
            <PlanOnboardPizzaTrackerCard
              date={pizzaTracker.PAA_SIGNED || pizzaTracker.PAA_SENT}
              mr={2}
              status={
                pizzaTracker.PAA_SIGNED
                  ? 'SIGNED'
                  : pizzaTracker.PAA_SENT
                    ? 'SENT FOR SIGNATURE'
                    : 'PENDING'
              }
              title='Plan Adoption Agreement'
            />
          </Grid>
          <Grid item lg={2} md={3} xs={4}>
            <PlanOnboardPizzaTrackerCard
              date={
                pizzaTracker.EMPLOYEE_INVITES_SENT ||
                pizzaTracker.EMPLOYEE_INVITES_SCHEDULED
              }
              mr={2}
              status={
                pizzaTracker.EMPLOYEE_INVITES_SENT
                  ? 'SENT'
                  : pizzaTracker.EMPLOYEE_INVITES_SCHEDULED
                    ? 'SCHEDULED'
                    : 'PENDING'
              }
              title='Employee Invites'
            />
          </Grid>
        </>
      )}
      {isConversion && !isStatePlan && (
        <>
          <Grid item lg={2} md={3} xs={4}>
            <PlanOnboardPizzaTrackerCard
              date={pizzaTracker.BLACKOUT_START_DATE}
              mr={2}
              status={
                dayjs
                  .utc(pizzaTracker.BLACKOUT_START_DATE)
                  .isBefore(dayjs.utc())
                  ? 'COMPLETE'
                  : pizzaTracker.BLACKOUT_START_DATE
                    ? 'SCHEDULED'
                    : 'PENDING'
              }
              title='Blackout Start Date'
            />
          </Grid>
          <Grid item lg={2} md={3} xs={4}>
            <PlanOnboardPizzaTrackerCard
              date={pizzaTracker.BLACKOUT_END_DATE}
              mr={2}
              status={
                dayjs.utc(pizzaTracker.BLACKOUT_END_DATE).isBefore(dayjs.utc())
                  ? 'COMPLETE'
                  : pizzaTracker.BLACKOUT_END_DATE
                    ? 'SCHEDULED'
                    : 'PENDING'
              }
              title='Blackout End Date'
            />
          </Grid>
          <Grid item lg={2} md={3} xs={4}>
            <PlanOnboardPizzaTrackerCard
              date={pizzaTracker.CONVERSION_WIRE_RECEIVED}
              mr={2}
              status={
                pizzaTracker.CONVERSION_WIRE_RECEIVED ? 'COMPLETE' : 'PENDING'
              }
              title='Conversion Wire Received'
            />
          </Grid>
        </>
      )}
      {!isStatePlan && (
        <Grid item lg={2} md={3} xs={4}>
          <PlanOnboardPizzaTrackerCard
            date={
              pizzaTracker.PAYROLL_COMPLETE ||
              pizzaTracker.PAYROLL_SUBMITTED ||
              pizzaTracker.PAYROLL_SCHEDULED
            }
            status={
              pizzaTracker.PAYROLL_COMPLETE
                ? 'COMPLETE'
                : pizzaTracker.PAYROLL_SUBMITTED
                  ? 'SUBMITTED'
                  : pizzaTracker.PAYROLL_SCHEDULED
                    ? 'SCHEDULED'
                    : 'PENDING'
            }
            title='First Payroll'
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PlanOnboardPizzaTracker;
