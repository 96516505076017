import { useField, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const DO_NOT_ALLOW = "Don't allow";
const NO = 'No';
const YES = 'Yes';

const DistributionFeaturesObserver: FC<{
  data: Record<string, any>;
}> = ({ data }: { data: Record<string, any> }) => {
  const { values }: { values: Record<string, any> } = useFormikContext();
  const [, , allowHardshipWithdrawalsFromRothAccountHelpers] = useField(
    'allowHardshipWithdrawalsFromRothAccount'
  );
  const [, , allowInServiceAtEarlyAgeFromAllAccountsHelpers] = useField(
    'allowInServiceAtEarlyAgeFromAllAccounts'
  );
  const [, , allowInServiceAtEarlyAgeHelpers] = useField(
    'allowInServiceAtEarlyAge'
  );
  const [, , allowInServiceAtNormalAgeFromAllAccountsHelpers] = useField(
    'allowInServiceAtNormalAgeFromAllAccounts'
  );
  const [, , allowInServiceFromPartialVestedAccountHelpers] = useField(
    'allowInServiceFromPartialVestedAccount'
  );
  const [, , allowInServiceFromRolloverAccountHelpers] = useField(
    'allowInServiceFromRolloverAccount'
  );
  const [, , allowSpecifiedAgeFromRothAccountHelpers] = useField(
    'allowSpecifiedAgeFromRothAccount'
  );
  const [, , earlyRetirementAgeHelpers] = useField('earlyRetirementAge');
  const [, , hardshipCriteriaHelpers] = useField('hardshipCriteria');
  const [, , inServiceSpecifiedAgeHelpers] = useField('inServiceSpecifiedAge');

  useEffect(() => {
    if (
      values.allowInServiceFromPartialVestedAccount === YES &&
      (!values.allowInServiceAtEarlyAge ||
        values.allowInServiceAtEarlyAge === NO) &&
      !['Yes - All Accounts', 'Yes - Selected Accounts'].includes(
        values.allowInServiceAtSpecifiedAge
      ) &&
      !['Yes - All Accounts', 'Yes - Selected Accounts'].includes(
        values.allowHardshipWithdrawals
      ) &&
      (!values.allowInServiceUponDisability ||
        values.allowInServiceUponDisability === NO)
    ) {
      allowInServiceFromPartialVestedAccountHelpers.setValue(NO);
    }

    if (
      ['Yes - All Accounts', 'Yes - Selected Accounts'].includes(
        values.allowHardshipWithdrawals
      )
    ) {
      hardshipCriteriaHelpers.setValue('Safe Harbor');
    }

    data.allowInServiceFromPartialVestedAccount.setDisabled(
      (!values.allowInServiceAtEarlyAge ||
        values.allowInServiceAtEarlyAge === NO) &&
        !['Yes - All Accounts', 'Yes - Selected Accounts'].includes(
          values.allowInServiceAtSpecifiedAge
        ) &&
        !['Yes - All Accounts', 'Yes - Selected Accounts'].includes(
          values.allowHardshipWithdrawals
        ) &&
        (!values.allowInServiceUponDisability ||
          values.allowInServiceUponDisability === NO)
    );

    if (
      values.allowInServiceAtNormalAgeFromAllAccounts === YES &&
      (!values.allowInServiceAtNormalAge ||
        values.allowInServiceAtNormalAge === NO)
    ) {
      allowInServiceAtNormalAgeFromAllAccountsHelpers.setValue(NO);
    }

    data.allowInServiceAtNormalAgeFromAllAccounts.setDisabled(
      !values.allowInServiceAtNormalAge ||
        values.allowInServiceAtNormalAge === NO
    );

    if (
      values.allowInServiceAtEarlyAge === YES &&
      !['Age Only', 'Later of Age and Service'].includes(
        values.earlyRetirementAgeType
      )
    ) {
      allowInServiceAtEarlyAgeHelpers.setValue(NO);
    }

    data.allowInServiceAtEarlyAge.setDisabled(
      !['Age Only', 'Later of Age and Service'].includes(
        values.earlyRetirementAgeType
      )
    );

    if (
      values.earlyRetirementAge &&
      (!values.earlyRetirementAgeType ||
        values.earlyRetirementAgeType === 'Early Retirement Not Allowed')
    ) {
      earlyRetirementAgeHelpers.setValue('');
    }

    data.earlyRetirementAge.setDisabled(
      !['Age Only', 'Later of Age and Service'].includes(
        values.earlyRetirementAgeType
      )
    );

    if (
      values.allowInServiceAtEarlyAgeFromAllAccounts === YES &&
      (!values.allowInServiceAtEarlyAge ||
        values.allowInServiceAtEarlyAge === NO)
    ) {
      allowInServiceAtEarlyAgeFromAllAccountsHelpers.setValue(NO);
    }

    data.allowInServiceAtEarlyAgeFromAllAccounts.setDisabled(
      !values.allowInServiceAtEarlyAge || values.allowInServiceAtEarlyAge === NO
    );

    if (
      values.allowInServiceFromRolloverAccount == YES &&
      (!values.allowRollover || values.allowRollover === NO)
    ) {
      allowInServiceFromRolloverAccountHelpers.setValue(NO);
    }

    data.allowInServiceFromRolloverAccount.setDisabled(
      !values.allowRollover || values.allowRollover === NO
    );

    if (
      values.allowHardshipWithdrawalsFromRothAccount &&
      values.allowHardshipWithdrawalsFromRothAccount !== NO &&
      (!['Yes - All Accounts', 'Yes - Selected Accounts'].includes(
        values.allowHardshipWithdrawals
      ) ||
        !values.isSalaryRothDeferral ||
        values.isSalaryRothDeferral === DO_NOT_ALLOW)
    ) {
      allowHardshipWithdrawalsFromRothAccountHelpers.setValue(NO);
    }

    data.allowHardshipWithdrawalsFromRothAccount.setDisabled(
      !['Yes - All Accounts', 'Yes - Selected Accounts'].includes(
        values.allowHardshipWithdrawals
      ) ||
        !values.isSalaryRothDeferral ||
        values.isSalaryRothDeferral === DO_NOT_ALLOW
    );

    if (
      values.hardshipCriteria &&
      !['Yes - All Accounts', 'Yes - Selected Accounts'].includes(
        values.allowHardshipWithdrawals
      )
    ) {
      hardshipCriteriaHelpers.setValue('');
    }

    data.hardshipCriteria.setDisabled(
      !['Yes - All Accounts', 'Yes - Selected Accounts'].includes(
        values.allowHardshipWithdrawals
      )
    );

    if (
      values.allowSpecifiedAgeFromRothAccount === YES &&
      (!['Yes - All Accounts', 'Yes - Fully Vested Accounts Only'].includes(
        values.allowInServiceAtSpecifiedAge
      ) ||
        !values.isSalaryRothDeferral ||
        values.isSalaryRothDeferral === DO_NOT_ALLOW)
    ) {
      allowSpecifiedAgeFromRothAccountHelpers.setValue(NO);
    }

    data.allowSpecifiedAgeFromRothAccount.setDisabled(
      !['Yes - All Accounts', 'Yes - Fully Vested Accounts Only'].includes(
        values.allowInServiceAtSpecifiedAge
      ) ||
        !values.isSalaryRothDeferral ||
        values.isSalaryRothDeferral === DO_NOT_ALLOW
    );

    if (
      values.inServiceSpecifiedAge &&
      (!values.allowInServiceAtSpecifiedAge ||
        values.allowInServiceAtSpecifiedAge === NO)
    ) {
      inServiceSpecifiedAgeHelpers.setValue('');
    }

    data.inServiceSpecifiedAge.setDisabled(
      !values.allowInServiceAtSpecifiedAge ||
        values.allowInServiceAtSpecifiedAge === NO
    );
  }, [
    allowHardshipWithdrawalsFromRothAccountHelpers,
    allowInServiceAtEarlyAgeFromAllAccountsHelpers,
    allowInServiceAtEarlyAgeHelpers,
    allowInServiceAtNormalAgeFromAllAccountsHelpers,
    allowInServiceFromPartialVestedAccountHelpers,
    allowInServiceFromRolloverAccountHelpers,
    allowSpecifiedAgeFromRothAccountHelpers,
    data,
    earlyRetirementAgeHelpers,
    hardshipCriteriaHelpers,
    inServiceSpecifiedAgeHelpers,
    values
  ]);

  return null;
};

export default DistributionFeaturesObserver;
