import { useSnackbar } from '@/contexts/SnackBarContext';
import { ScheduledChangePayloadItem } from '@/models/ScheduledChangesDTO.model';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, DialogActions, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { useEffect } from 'react';

import DatePickerForm from '../date-picker/DatePickerForm';

type ScheduledChangesDialogActionsProps<T> = {
  scheduleChangesStep: boolean;
  setScheduleChangesStep: (step: boolean) => void;
  makeScheduledChangesPayload: (
    currentValues: Record<string, any>,
    scheduledValues: Record<string, any>,
    config?: T
  ) => ScheduledChangePayloadItem[];
  makeScheduledChangesPayloadConfig?: T;
  disableContinue: boolean;
  onClose: () => void;
  singleStep: boolean;
  hideDatePicker?: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
};

const ScheduledChangesDialogActions = <T,>(
  props: ScheduledChangesDialogActionsProps<T>
) => {
  const { showSnackbar } = useSnackbar();
  const formikContext = useFormikContext<any>();

  useEffect(() => {
    props.setIsSubmitting(formikContext.isSubmitting);
  }, [formikContext.isSubmitting]);

  return (
    <DialogActions data-testid='schedule-changes-dialog-actions'>
      <Stack flexDirection='column' spacing={2} width='100%'>
        {dayjs(formikContext.values.scheduledChangesEffectiveDate).isBefore(
          dayjs(),
          'date'
        ) &&
          props.scheduleChangesStep && (
            <Alert
              data-testid='retroactive-warning'
              icon={false}
              severity='warning'>
              <Stack
                alignItems='center'
                direction='row'
                justifyContent='center'
                spacing={1}>
                <WarningAmberIcon
                  fontSize='small'
                  sx={{ color: theme => theme.palette.warning.dark }}
                />
                <div>
                  Retroactive updates will not take effect as of that date, will
                  only update as of current date
                </div>
              </Stack>
            </Alert>
          )}
        <Grid container pl={2} spacing={1}>
          <Grid style={{ flexGrow: '1' }}>
            {props.scheduleChangesStep && !props.hideDatePicker && (
              <DatePickerForm
                data-testid='effective-date-date-picker'
                format='MM/DD/YYYY'
                fullWidth
                inputProps={{
                  autoComplete: 'off'
                }}
                label='Date Changes Will Be Applied'
                name='scheduledChangesEffectiveDate'
                size='small'
                value={formikContext.values.scheduledChangesEffectiveDate}
                variant='outlined'
              />
            )}
          </Grid>
          {(!props.scheduleChangesStep || props.singleStep) && (
            <Grid>
              <Button
                data-testid='schedule-changes-dialog-cancel-button'
                onClick={() => {
                  props.setScheduleChangesStep(false);
                  props.onClose();
                }}>
                Cancel
              </Button>{' '}
            </Grid>
          )}
          {!props.scheduleChangesStep ? (
            <>
              {!props.singleStep && (
                <Grid>
                  <Button
                    data-testid='schedule-changes-dialog-schedule-button'
                    disabled={
                      isEqual(
                        formikContext.initialValues,
                        formikContext.values
                      ) ||
                      props.disableContinue ||
                      !props
                        .makeScheduledChangesPayload(
                          formikContext.initialValues,
                          formikContext.values,
                          props.makeScheduledChangesPayloadConfig
                        )
                        .filter(payloadItem => payloadItem.uiLabel).length ||
                      !!formikContext.errors?.hasDualEligibility
                    }
                    onClick={async () => {
                      const { scheduledChangesEffectiveDate, ...restErrors } =
                        formikContext.errors;
                      if (Object.keys((restErrors || {}) as any)?.length) {
                        await formikContext.submitForm();
                        showSnackbar({
                          autoHideDuration: 10000,
                          message: `Please enter all required fields`,
                          severity: 'error'
                        });
                      } else {
                        props.setScheduleChangesStep(true);
                        formikContext.setFieldValue(
                          'scheduledChangesEffectiveDate',
                          dayjs().format('MM/DD/YYYY')
                        );
                      }
                    }}
                    variant='contained'>
                    Continue
                  </Button>
                </Grid>
              )}
            </>
          ) : (
            <>
              {!props.singleStep && (
                <Grid>
                  <Button
                    data-testid='schedule-changes-dialog-back-button'
                    disabled={formikContext.isSubmitting}
                    onClick={() => props.setScheduleChangesStep(false)}>
                    Back to edit
                  </Button>
                </Grid>
              )}
              <Grid>
                <LoadingButton
                  data-testid='schedule-changes-dialog-apply-button'
                  disabled={!formikContext.isValid}
                  loading={formikContext.isSubmitting}
                  onClick={formikContext.submitForm}
                  type='submit'
                  variant='contained'>
                  {dayjs(
                    formikContext.values.scheduledChangesEffectiveDate
                  ).isAfter(dayjs(), 'date')
                    ? 'Schedule'
                    : 'Apply'}
                </LoadingButton>
              </Grid>
            </>
          )}{' '}
        </Grid>
      </Stack>
    </DialogActions>
  );
};

export default ScheduledChangesDialogActions;
