import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem, MenuItemProps, styled } from '@mui/material';
import { unstable_useId as useId } from '@mui/utils';

import React, { createContext, FC, useContext, useState } from 'react';
import { useToggle } from 'react-use';

const StyledButton = styled(Button)(() => ({
  position: 'absolute',
  right: 0,
  top: 0
}));

const StyledHeadingMenuItem = styled(MenuItem)(() => ({
  fontSize: 12,
  textTransform: 'uppercase'
}));

type CreateMenuProps = {
  children: React.ReactNode;
};

const CreateMenuContext = createContext<{
  toggleOpen?: ReturnType<typeof useToggle>[1];
}>({});

export const CreateMenu: FC<CreateMenuProps> = props => {
  const buttonId = useId();
  const menuId = useId();
  const [buttonEl, setButtonEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <CreateMenuContext.Provider value={{ toggleOpen }}>
      <StyledButton
        aria-controls={isOpen ? menuId : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='Create'
        data-testid='create-menu-button'
        endIcon={<KeyboardArrowDownIcon />}
        id={buttonId}
        onClick={toggleOpen}
        ref={el => setButtonEl(el)}
        variant='outlined'>
        Create
      </StyledButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': buttonId
        }}
        anchorEl={buttonEl}
        id={menuId}
        onClose={toggleOpen}
        open={isOpen}>
        {props.children}
      </Menu>
    </CreateMenuContext.Provider>
  );
};

export const CreateMenuItem: FC<MenuItemProps> = props => {
  const { toggleOpen } = useContext(CreateMenuContext);

  return (
    <MenuItem
      {...props}
      onClick={event => {
        if (props.onClick) props.onClick(event);
        if (toggleOpen) toggleOpen();
      }}
    />
  );
};

type HeadingMenuItemProps = {
  children: React.ReactNode;
};

export const HeadingMenuItem: FC<HeadingMenuItemProps> = props => (
  <StyledHeadingMenuItem {...props} />
);
