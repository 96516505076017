import AccessControl from '@/components/access-control/AccessControl.component';
import DatePicker from '@/components/date-picker';
import PriorProviderComponent from '@/components/prior-provider';
import SimpleDropdown from '@/components/simple-dropdown';
import SimpleTextarea from '@/components/simple-textarea';
import Constants from '@/consts/planDesign.constants';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { userService } from '@/services/User.service';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, FormControl, FormHelperText, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Field } from 'formik';
import { TextField } from 'formik-mui';
import React, { useCallback } from 'react';

import ContributionMatchFormula from './ContributionMatchFormula.component';
import { DualEligibility } from './DualEligibility.component';
import { hideFieldFromTpa } from './utils';

const useStyles = makeStyles(theme => ({
  field: {
    marginTop: '1rem !important'
  },
  fieldWarning: {
    color: theme.palette.text.primary,
    paddingLeft: '0.5rem'
  },
  hidden: {
    display: 'none'
  },
  warningIcon: {
    color: theme.palette.warning.dark
  }
}));

const Warning = (props: { message: string }): JSX.Element => {
  const classes = useStyles();
  return (
    <Stack alignItems='center' direction='row'>
      <WarningIcon className={classes.warningIcon} fontSize='inherit' />
      <FormHelperText className={classes.fieldWarning}>
        {props.message}
      </FormHelperText>
    </Stack>
  );
};

interface GetFieldOptions {
  component?: string;
  key: string;
  label: string;
}

function useGetField(
  data: Record<string, any>,
  initialValues: Record<string, any>,
  isPooledPlan: boolean
): {
  getField: (
    options: GetFieldOptions,
    errors?: any,
    warnings?: any,
    unsupportedValue?: Record<string, any>
  ) => JSX.Element | null;
} {
  const classes = useStyles();

  const { showSnackbar } = useSnackbar();

  const hasWriteForfeiturePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_FORFEITURE_ACTION
  );

  const getField = useCallback(
    (
      {
        component,
        key,
        label
      }: {
        component?: string;
        key: string;
        label: string;
      },
      errors?: any,
      warnings?: Record<string, string>,
      unsupportedValue?: Record<string, any>
    ) => {
      const hideFromTPA = hideFieldFromTpa(key);

      const withEmptyStateOptionOverride = (
        options: { value: string; option: string }[]
      ) => {
        return isPooledPlan
          ? [
              { option: Constants.pooledPlanTemplateEmptyState, value: '' },
              ...options
            ]
          : options;
      };

      switch (component) {
        case 'SimpleDropdown':
          return (
            <React.Fragment key={key}>
              <AccessControl hideFromTPA={hideFromTPA}>
                <Box
                  className={
                    data[key]?.isHidden ? classes.hidden : classes.field
                  }
                  key={key}>
                  <SimpleDropdown
                    disabled={data[key]?.disabled}
                    displayEmpty={isPooledPlan}
                    fieldId={key}
                    fieldName={label}
                    fieldOptions={withEmptyStateOptionOverride(
                      data[key]?.optionsDisplay ||
                        data[key]?.options.map((v: any) => {
                          return v.value && v.option
                            ? v
                            : v.value
                              ? {
                                  option: v.value,
                                  value: v.value
                                }
                              : {
                                  option: v,
                                  value: v
                                };
                        }) ||
                        []
                    )}
                  />
                  {unsupportedValue && (
                    <Warning
                      message={`Unsupported value: ${unsupportedValue.value}`}
                    />
                  )}
                  {warnings?.[key] && <Warning message={warnings[key]} />}
                </Box>
              </AccessControl>
            </React.Fragment>
          );
        case 'DatePicker':
          return (
            <React.Fragment key={key}>
              <AccessControl hideFromTPA={hideFromTPA}>
                <Box className={classes.field} key={key}>
                  <FormControl error={Boolean(errors?.[key])} fullWidth>
                    <Field
                      as={DatePicker}
                      data-testid={key}
                      disabled={data[key]?.disabled}
                      label={label}
                      name={key}
                      showFormikErrorOnly
                    />
                  </FormControl>
                  {warnings?.[key] && <Warning message={warnings[key]} />}
                </Box>
              </AccessControl>
            </React.Fragment>
          );
        case 'Field':
          return (
            <React.Fragment key={key}>
              <AccessControl hideFromTPA={hideFromTPA}>
                <Box className={classes.field} key={key}>
                  <Field
                    component={TextField}
                    data-testid={key}
                    disabled={data[key]?.disabled}
                    fullWidth
                    key={key}
                    label={label}
                    name={key}
                  />
                  {warnings?.[key] && <Warning message={warnings[key]} />}
                </Box>
              </AccessControl>
            </React.Fragment>
          );
        case 'DualEligibility':
          return (
            <React.Fragment key={key}>
              <AccessControl hideFromTPA={hideFromTPA}>
                <Box className={classes.field} flexDirection='column' key={key}>
                  <DualEligibility data={data} />
                  {warnings?.[key] && <Warning message={warnings[key]} />}
                </Box>
              </AccessControl>
            </React.Fragment>
          );
        case 'ContributionMatchFormula':
          return (
            <React.Fragment key={key}>
              <AccessControl hideFromTPA={hideFromTPA}>
                <ContributionMatchFormula />
                {warnings?.[key] && <Warning message={warnings[key]} />}
              </AccessControl>
            </React.Fragment>
          );
        case 'Textarea':
          return (
            <React.Fragment key={key}>
              <AccessControl hideFromTPA={hideFromTPA}>
                <Box
                  className={
                    data[key]?.isHidden ? classes.hidden : classes.field
                  }
                  key={key}>
                  <SimpleTextarea data-testid={key} label={label} name={key} />
                  {warnings?.[key] && <Warning message={warnings[key]} />}
                </Box>
              </AccessControl>
            </React.Fragment>
          );
        case 'PriorProviderComponent':
          return (
            <React.Fragment key={key}>
              <Box className={classes.field} key={key}>
                <PriorProviderComponent withUnverified />
                {warnings?.[key] && <Warning message={warnings[key]} />}
              </Box>
            </React.Fragment>
          );
        default:
          return null;
      }
    },
    [
      classes.field,
      classes.hidden,
      data,
      hasWriteForfeiturePermissions,
      initialValues,
      isPooledPlan,
      showSnackbar
    ]
  );

  return { getField };
}

export { useGetField };
